.CrossBorderCourierService {
    box-shadow: 0px 10px 30px 0px rgba(0, 0, 0, 0.30);
    padding: 10px 30px 30px 30px;
    display: flex;
    flex-direction: column;
    gap: 10px;
    width: 920px;
    background: rgba(112, 176, 187, 0.90);

    p {
        margin: 0;
    }

    .Header {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
        .HeaderLeft {
            display: flex;
            flex-direction: row;
            gap: 10px;

            .PopupTitle {
                p {
                    color: #263238;
                    font-size: 17px !important;
                    font-style: normal;
                    font-weight: 700;
                    line-height: 32px;
                    letter-spacing: 1.7px;
                }
            }
        }

        .HeaderRight {
            display: flex;
            flex-direction: row;
            gap: 10px;
        }
    }
    .contentPopup{
        width: 100%;
        background-color: #EBEBEB;
        border-radius: 10px 10px 10px 10px;
        overflow-y: auto;
        max-height: 80vh;
        margin-right: -25px;
        padding-right: 15px;
        .content{
            overflow: auto;
            padding: 10px

        }
        .contentFooter{
            display: flex;
            justify-content: center;
            flex-direction: column;
            align-items: center;
            margin-bottom: 20px;
            p{
                font-size: 13px;
                color: #00aaaa;
                cursor: pointer;
                text-decoration: underline;
            }
        }
    }
}

.btn-form {
    display: flex;
    justify-content: center;
    gap: 63px;
    margin: 30px;
    button {
        border: none;
        padding: 9px 12px;
        color: #ffffff;
        border-radius: 5px;
        cursor: pointer;
        font-size: 14px;
    }

    .btn-cancel {
        background-color: #d7d7d7;
    }

    .btn-submit {
        background-color: #00aaaa;

        div {
            display: flex;
            align-items: center;
            gap: 10px;
        }
    }
}