@import "Common.scss";
.gap_20 {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    gap: 20px;
}

.custom-tab-holon-template {
    display: flex;
    flex-direction: row;
    align-items: center;
    border-bottom: 1px solid $mainBg;
    border-radius: 10px 10px 0px 0px;
    gap: 30px;
    max-width: 1160px;
    overflow-x: auto;
    // padding-bottom: 5px;

    &::-webkit-scrollbar {
        height: 8px;
        visibility: hidden;
    }

    /* Track */
    &::-webkit-scrollbar-track {
        background: #D9D9D9;
        border-radius: 5px;
        transform: translateY(-100px)
    }

    /* Handle */
    &::-webkit-scrollbar-thumb {
        background: #929292;
        border-radius: 5px;
        border: 2px solid transparent;
        background-clip: content-box;
        transform: translateY(-100px)
    }

    /* Handle on hover */
    &::-webkit-scrollbar-thumb:hover {
        background: #555555c4;
        border: 2px solid transparent;
        background-clip: content-box;
    }

    .custom-tab {
        padding: 7px;
        font-size: 13px;
        color: black;
        cursor: pointer;
        position: relative;
        display: flex;
        flex-direction: row;
        align-items: center;
        width: max-content;
        min-width: max-content;
        gap: 5px;

        span {
            overflow: hidden;
            display: -webkit-box;
            -webkit-line-clamp: 1;
            -webkit-box-orient: vertical;
            text-overflow: ellipsis;
        }

        &::before {
            width: 100%;
            height: 4px;
            bottom: 0px;
            position: absolute;
            left: 0px;
            content: "";
            border-radius: 5px 5px 0px 0px;
            background: #FFFFFF;
        }

        &.custom-tab-active {
            &::before {
                width: 100%;
                height: 4px;
                bottom: 0px;
                position: absolute;
                left: 0px;
                content: "";
                border-radius: 5px 5px 0px 0px;
                background: $btnBgColor !important;
            }

            color: $btnBgColor;
        }

        .custom-tab-number {
            border-radius: 50%;
            background-color: $btnBgColor;
            min-width: 15px;
            height: 15px;
            display: flex;
            align-items: center;
            justify-content: center;

            p {
                color: #FFFFFF;
                font-size: 12px !important;
                margin-bottom: 0px;
            }
        }
    }
}

.custom-table {
    width: 100%;
}

.text-note {
    font-size: 12px;
    color: #000;
    cursor: default;
    pointer-events: none;

    span {
        color: $mainBg;
        cursor: pointer;
        pointer-events: fill;
    }
}