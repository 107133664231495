@import "Common.scss";
.CommonTab {
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
    border-radius: 10px 10px 0px 0px;
    background: $mainBg;
    height: 30px;

    .CommonTab_Content{
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding: 0px 30px;
        width: 100%;
    }

    .TabList {
        display: flex;
        gap: 30px;

        .TabListItem {
            font-size: 14px;
            cursor: pointer;
            position: relative;
            padding: 0px 12px;

            &::before {
                width: 100%;
                height: 4px;
                bottom: -4px;
                position: absolute;
                left: 0px;
                content: "";
                border-radius: 5px 5px 0px 0px;
                background: rgba(0, 0, 0, 0.50);
            }

            &.TabListActive {
                &::before {
                    width: 100%;
                    height: 4px;
                    bottom: -4px;
                    position: absolute;
                    left: 0px;
                    content: "";
                    border-radius: 5px 5px 0px 0px;
                    background: #FFC30D !important;
                }

                span {
                    color: #FFC30D;
                }
            }

            span {
                color: #FFF;
                font-size: 14px;
                font-style: normal;
                font-weight: 500;
                line-height: 14px;
                letter-spacing: 1.4px;

            }
        }
    }
}