@import "Common.scss";
.InquiryDetailPopup {
    background: rgba(112, 176, 187, 0.90);
    box-shadow: 0px 10px 30px 0px rgba(0, 0, 0, 0.30);
    padding: 28px 40px;
    display: flex;
    flex-direction: column;
    gap: 10px;
    width: 700px;

    p {
        margin: 0;
    }

    .InquiryDetailPopupContent {
        max-height: 70vh;
        margin-right: -25px;
        padding-right: 15px;
        overflow-y: auto;
    }

    .Header {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;

        .HeaderLeft {
            display: flex;
            flex-direction: row;
            gap: 10px;

            .PopupTitle {
                p {
                    color: #263238;
                    font-size: 17px !important;
                    font-style: normal;
                    font-weight: 700;
                    line-height: 32px;
                    letter-spacing: 1.7px;
                }
            }
        }

        .HeaderRight {
            display: flex;
            flex-direction: row;
            gap: 10px;
        }
    }

    .Body {

        display: flex;
        flex-direction: column;
        gap: 10px;

        .RowItem {
            display: flex;
            flex-direction: row;
            align-items: center;
            gap: 10px;
            min-height: 30px;

            .Label {
                width: 250px;
                min-width: 250px;
                color: $btnDarkBgColor;
                font-weight: 500;
            }

            .Value {
                font-weight: 400;
                display: flex;

                .InsideValue {
                    display: flex;
                    margin-left: 50px;

                    .Label {
                        width: 100px;
                        min-width: unset;
                    }
                }
            }
        }

    }

    .Footer {
        display: flex;
        justify-content: center;
    }

}