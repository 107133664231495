.error-msg {
    color: red;
    font-size: 12px;
}

.form-home-input-container {
    display: flex;
    flex-direction: column;
}

.inner-form-home-input-container {
    display: flex;
    align-items: center;
}

.form-home-input {
    padding-right: 20px;
    padding-left: 20px;
    border: unset;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    height: 100%;
    letter-spacing: 1.6px;
    border-radius: 5px;
    background: #EBEBEB;
    position: relative;
    display: flex;
}

.form-textarea {
    @extend .form-home-input;
    height: 120px;
}

.icon-password {
    cursor: pointer;
    width: 0px;
    transform: translateX(-40px);
}

.input-password {
    padding-right: 50px;
}

input::-ms-reveal,
input::-ms-clear {
    display: none;
}