@import "Common.scss";


.header-concept {
    padding: 0 !important;
    margin: 0 !important;

    .header-concept-button {
        position: absolute;
        margin-top: 80px;
        right: 0px;
        gap: 10px;
        display: flex;
        flex-direction: column;
        padding: 0 !important;

        .side-btn {
            display: flex;
            padding: 0;
            justify-content: flex-end;
            width: 100%;

            .inquiry-btn {
                padding-left: 20px;
                display: flex;
                align-items: flex-end;
                justify-content: center;
                justify-content: flex-start;
                width: 60px;
                border-radius: 10px 0px 0px 10px;
                border: 3px solid #263238;
                border-right: 0;
                background: #EBEBEB;
                color: #263238;
                font-size: 16px;
                font-weight: 400;
                letter-spacing: 1.6px;
                padding-top: 12px;
                padding-bottom: 12px;

                .inquiry-btn-content {
                    display: flex;
                    flex-direction: column;


                    .inquiry-btn-label {
                        width: 0px;
                    }
                }
            }

            &.side-btn-english {

                &.side-btn-top {
                    // margin-top: 100px;
                }

                &.side-btn-center {
                    // margin-top: 225px;
                }

                .inquiry-btn {
                    width: 115px;

                    &:hover {
                        width: 130px;
                    }
                }
            }

            .inquiry-btn:hover {
                width: 80px;
            }
        }

        .side-btn-top {
            // margin-top: 50px;
        }

        .side-btn-bottom {
            // margin-top: 360px;
        }

    }

}

@media (prefers-reduced-motion: no-preference) {
    .App-logo {
        animation: App-logo-spin infinite 20s linear;
    }
}

body {
    font-family: 'Noto Sans JP';
    --bs-modal-padding: 1.8rem;
}

h1 {
    font-weight: 400;
    font-size: xx-large;
    letter-spacing: 0.3em;
    line-height: 1.6em !important;
}

h2 {
    font-family: 'Noto Sans JP';
    font-weight: 400 !important;
    font-size: xx-large !important;
    letter-spacing: 0.2em;
}

h3 {
    font-family: 'Noto Sans JP';
    font-weight: 500 !important;
    font-size: larger !important;
    letter-spacing: 0.2em;
}

p {
    // font-size: smaller!important;
}

input.form-control {
    background: #F2F2F2;
}


#signup-form.form-label {
    margin: 0.5rem;
}

.required {
    float: right;
    background: #ED6041 !important;
    border-radius: 50px !important;
    margin-top: 0.5rem;
}

.btn-sm {
    min-width: 6.5em;
}

.btn-round {
    border-radius: 50px !important;
    min-width: 18em !important;
    font-size: 0.8rem !important;
}

.btn-secondary {
    --bs-btn-bg: $btnBgColor !important;
    --bs-btn-border-color: $btnBgColor !important;
}

.btn-danger {
    --bs-btn-bg: #ED6041 !important;
    --bs-btn-border-color: #ED6041 !important;
}

.small-description {
    font-size: x-small !important;
}

.small-summary {
    font-size: smaller !important;
}

.remark-text {
    color: $mainBg;
    font-weight: 700;
}

#saveIDLogin {
    border-radius: 50%;
}

::placeholder {
    color: #929292;
}

.solid-borders-horizontal {
    border-top: 1px solid #EBEBEB;
    vertical-align: middle;
    padding: 2%;
    margin-bottom: 0rem !important;
}

.container-xl {
    padding: 0px !important;
}

.header-top {
    position: absolute;
    z-index: 99;
    color: #fff;
    padding: 1.5% !important;
}

.clickable {
    cursor: pointer;
}

.top-side-logo {
    letter-spacing: 0.2em;
    font-size: $fontMedium;
}

.top-side-login {
    text-align: right;
    display: flex;
    flex-direction: row;
    gap: 10px;
    justify-content: flex-end;
    align-items: center;


    .button-lang {
        display: flex;
        flex-direction: row;
        justify-content: flex-end;

        select {
            border: 1px solid #929292;
            width: 100px !important;
            border-radius: 4px;
            color: #0E0E28;
            font-size: 14px;
            font-style: normal;
            font-weight: 400;
            line-height: normal;
            text-indent: 5px;
            height: 30px;

            &:active {
                border: 1px solid #929292;
            }

            &:focus {
                border: 1px solid #929292;
            }
        }
    }
}

.home-header {
    overflow: hidden;
    position: relative;
    background-color: $mainBg;
    color: #fff;
    padding: 0px !important;
    margin-left: auto !important;
    margin-right: auto !important;
}

.header-side {
    position: relative;
    padding: 0px !important;
    min-width: 350px;

    &.header-side-left {
        @media (max-width: 1400px) {
            width: 60%;
        }
    }

    &.header-side-right {
        @media (max-width: 1400px) {
            width: 40%;
            margin-left: auto;
        }
    }
}


.header-title {
    z-index: 99;
    position: absolute;
    width: 90%;
    padding: 10%;
    margin-top: 15%;
    padding-right: 0%;
}

.header-world {
    width: 100%;
}

.sign-step {
    width: 90%;
}

.step-link {
    border-top: 4px dashed darkgray;
    margin: 45% auto !important;
}

.service-access {
    position: absolute;
    width: 90%;
    bottom: 10px;

    .service-access-icon {
        cursor: pointer;
    }
}

.container-service-access {
    display: flex;
    align-items: flex-end;

    .service-access-icon {
        cursor: pointer;
    }

    .service-access-icon-transport {
        margin-bottom: 10px;
    }
}

.step-col {
    padding: 0px !important;
}

.service-icon {
    max-width: 50%;
    float: right;
}

.service-thm-sec {
    padding: 5% 0px !important;
    width: 100% !important;
}

.inner-service-thm-sec {
    gap: 20px;
    margin-left: 0px !important;
    margin-right: 0px !important;
}

.service-thm {
    padding: 30px 30px 10px 30px !important;
    border-radius: 10px;
    position: relative;
    min-height: 350px;
    display: flex;
    /* align-items: stretch; */
    width: 100%;
    flex-direction: column;
    justify-content: space-between;

    h3 {
        font-weight: 500;
    }
}

$dark-sec-color: #434244;
$gray-sec-color: #3a93a3;
$red-sec-color: #ed6041;

.dark-sec {
    color: #fff;
    background-color: $dark-sec-color;
}

.gray-sec {
    color: #fff;
    background-color: $gray-sec-color;
}

.yellow-sec {
    color: #263238;
    background-color: #ffc30d;
}

.red-sec {
    color: #fff;
    background-color: $red-sec-color;
}

.convincing-data-sec {
    background-color: #263238;
    color: #fff;
    padding: 10% !important;
    ;
}

.dialog-pointer {
    transform: skew(12deg) rotate(37deg) scale(0.8) skewX(-40deg) translateX(-28px) translateY(72px);
    padding-left: 0 !important;
    z-index: 1;
}

.convincing-data-number {
    color: #67bfce;
    letter-spacing: 0.02em;
    font-size: 4em;
    font-weight: 700;
    text-shadow: 4px 4px 6px rgba(0, 0, 0, 0.5);
}

.empty-row {
    min-height: 10%;
}

.tag-convincing {
    padding: 25px 10px 10px 25px !important;
    z-index: 2;
}

.home-footer {
    background-color: $mainBg;
    color: #fff;
    padding: 10% !important;
}

.right-reserved {
    letter-spacing: 0.2em;
}

.footer-text-container {
    margin: 0 !important;
    gap: 10px;
}

.footer {
    background-color: #ebebeb;
    color: #000;
    padding: 25px 10% 25px 10% !important;

    :global {
        .cursor-pointer {
            cursor: pointer;
        }
    }

    .footer-content {
        display: flex;
        justify-content: space-between;
        align-items: center;
    }

    .footer-text {
        padding: 1%;
        color: #000;
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        line-height: 30px;
        letter-spacing: 2.8px;
        width: unset;

        p {
            text-align: right;
            color: #000;
            font-size: 12px;
            font-style: normal;
            font-weight: 400;
            letter-spacing: 2.4px;
            margin-bottom: 0px;
        }
    }

    // .footer-text-middle {
    //     width: 35%;
    // }

    .footer-text-faq {
        padding: 1%;
        @extend .footer-text;
        text-align: center;
    }

    .copyright {
        color: #000;
        font-size: 12px;
        font-style: normal;
        font-weight: 400;
        letter-spacing: 2.4px;
        margin-bottom: 0px;
        line-height: 30px;
    }
}


@keyframes App-logo-spin {
    from {
        transform: rotate(0deg);
    }

    to {
        transform: rotate(360deg);
    }
}

:global {
    //     p {
    //         font-size: $fontSmaller !important;
    //     }
}

@mixin custom-dialog {
    margin-top: auto;
    margin-bottom: 20%;
    transform: none;
    width: 0;
    height: 0;
    border-top: 10px solid transparent;
    border-bottom: 10px solid transparent;
    border-left: 20px solid $gray-sec-color;
    background: transparent;
}

@include mobile() {
    h3 {
        font-size: small !important;
    }

    .service-thm-sec {
        padding-left: 15px !important;
        padding-right: 15px !important;
    }

    .top-side-logo {
        font-size: small;
        display: flex;
        width: 100% !important;
    }

    .top-side-login {
        width: 100% !important;
        display: flex;
        margin-left: auto;
    }

    .login-model-body {
        width: 100%;
    }


    .login-small-description {
        height: unset;
    }

    .common-home-btn {
        min-width: 50%;
    }

    .footer-text {
        text-align: right;
        width: 100% !important;
    }

    .tag-convincing {
        padding-left: 2% !important;
        padding-right: 2% !important;
        font-size: small;

        h3 {
            letter-spacing: 0em !important;
        }
    }

    .convincing-data-number {
        font-size: 2em !important;
        padding: 0 !important;
    }

    .tag-convincing-container {
        display: flex;
    }

    .service-thm {
        flex: unset !important;
        min-height: 0;
    }

}

@include minTablet() {
    .header-side-right {
        &div {
            width: 55%;
            float: right !important;
        }
    }

    .header-title {
        padding: 5%;
        letter-spacing: 0.1em;
        font-size: medium;
        width: 55%;

        h1 {
            font-size: large;
            letter-spacing: 0.1em;
        }
    }

    .service-thm {
        flex: unset !important;
        min-height: 0;
    }

    .tag-convincing {
        padding-left: 2% !important;
        padding-right: 2% !important;
        font-size: small;

        h3 {
            letter-spacing: 0.1em;
        }
    }

    .convincing-data-number {
        font-size: 2em !important;
    }

    .tag-convincing-container {
        display: flex;
    }

    .footer-content {
        gap: 10px;
        flex-wrap: wrap-reverse;

        .footer-left-side {
            width: 100%;
            order: 1;

            p {
                font-size: x-small;
                text-align: left;
            }
        }

        .footer-right-side {
            @extend .footer-left-side;

            .footer-text-container {
                gap: 0px;
            }
        }
    }

    .gray-sec.dialog-pointer {
        @include custom-dialog;
        border-left: 20px solid $gray-sec-color;
        margin-top: auto !important;
    }

    .red-sec.dialog-pointer {
        @include custom-dialog;
        border-left: 20px solid $red-sec-color;
        margin-top: auto !important;
    }
}

@include large() {
    .gray-sec.dialog-pointer {
        @include custom-dialog;
        border-left: 20px solid $gray-sec-color;
        margin-top: 20%;
    }

    .red-sec.dialog-pointer {
        @include custom-dialog;
        margin-top: 20%;
        border-left: 20px solid $red-sec-color;
    }
}