.flex-bottom-pagination {
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 16px;
    margin-bottom: 0px;
    padding-left: 0px;

    button {
        background-color: transparent;
        outline: none;
        border: none;
    }

    .btn-pre {
        display: flex;
        align-items: center;
        justify-content: center;
        cursor: pointer;
        background-color: #D9D9D9;
        height: 30px;
        width: 30px;
        border-radius: 6px;
        box-shadow: 4px 4px 6px 0px rgba(0, 0, 0, 0.05) !important;

        svg {
            color: black;
        }
    }

    .btn-next {
        display: flex;
        align-items: center;
        justify-content: center;
        cursor: pointer;
        background-color: #D9D9D9;
        height: 30px;
        width: 30px;
        border-radius: 6px;
        box-shadow: 4px 4px 6px 0px rgba(0, 0, 0, 0.05) !important;

        svg {
            color: black;
        }
    }

    .disabled {
        display: flex;
        flex-direction: row;
        align-items: center;
        cursor: default;

        svg {
            color: #9F9F9F;
        }
    }

    .pagination-item {
        color: #929292;
        text-align: center;
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
        width: max-content;
        min-width: 30px;
        height: 30px;
        display: flex;
        align-items: center;
        justify-content: center;
        cursor: pointer;

        outline: none;
        border: none;
        font-size: 14px;
        font-style: normal;
        font-weight: 700;
        line-height: 27px;

        &.active {
            border-radius: 6px;
            background: #929292;
            color: #FFF;
            box-shadow: 4px 4px 6px 0px rgba(0, 0, 0, 0.05) !important;
        }
    }

    .pagination {
        display: flex;
        flex-direction: row;
        align-items: center;
        gap: 16px;

        li {
            list-style-type: none;
        }

        .pagination-item-dots {
            color: black;
            height: 30px;
            width: 30px;
            display: flex;
            align-items: flex-end;
            justify-content: flex-end;
        }
    }

    .btn-disabled {
        cursor: default;

        svg {
            color: #9F9F9F;
        }
    }

}

.custom-pagiantion {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;

    select {
        height: 30px;
        border: 1px solid #929292;
        background-color: transparent;
        color: #000;
        border-radius: 6px;
        font-size: 14px;
        padding: 0px 5px;
        cursor: pointer;
        text-align: center;

        &:focus{
            border: 1px solid #929292;
        }
        option{
            cursor: pointer;
        }
    }
}