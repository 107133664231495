.PolicySetting {
    display: flex;
    flex-direction: column;
    gap: 20px;


    .Update-PolicySetting-FormSearch {
        width: 100%;
        border-radius: 10px;

        form {
            display: flex;
            flex-direction: column;
            gap: 24px;
        }
    }

    .PolicySetting-FormSearch {
        width: 100%;

        form {
            display: flex;
            flex-direction: column;
            gap: 24px;
        }
    }

    .action-search {
        max-width: 100px;
    }

    .action-update {
        display: flex;
        justify-content: flex-end;
        width: 100%;
        margin-top: 10PX;
    }
}

.policy-content {
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: 10px;

    .title-policy {
        color: #000;
        font-size: 16px;
        font-style: normal;
        font-weight: 500;
        line-height: 24px;
        letter-spacing: 1.6px;
        margin-bottom: 0px;
    }

    .content {
        blockquote {
            font-style: italic;
            padding: 2px 0;
            border-style: solid;
            border-color: #ccc;
            border-width: 0;
            padding-left: 20px;
            padding-right: 8px;
            border-left-width: 5px;
            display: block;
            margin-block-start: 1em;
            margin-block-end: 1em;
            margin-inline-start: 40px;
            margin-inline-end: 40px;
        }
    }

}