@import "Common.scss";
@import './InquiryDetailPopup.module.scss';

.CreateInquiryPopup {
  @extend .InquiryDetailPopup;

  .CreateInquiryPopupContent {
    max-height: 70vh;
    margin-right: -25px;
    padding-right: 15px;
    overflow-y: auto;
  }

  .RowInput {
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 10px;
    min-height: 30px;

    .Label {
      width: 250px;
      min-width: 250px;
      color: $btnDarkBgColor;
      font-weight: 500;
    }

    .Value {
      font-weight: 400;
    }

    :global {
      .w-100 {
        width: unset !important;
      }
    }

  }

  .footerCreate {
    display: flex;
    gap: 20px;
    align-items: center;
    width: 100%;
    justify-content: center;
  }
}