.service-detail-popup {
    width: 1048px;

    .content {
        width: 100%;
        padding: 20px;

        blockquote {
            font-style: italic;
            padding: 2px 0;
            border-style: solid;
            border-color: #ccc;
            border-width: 0;
            padding-left: 20px;
            padding-right: 8px;
            border-left-width: 5px;
            display: block;
            margin-block-start: 1em;
            margin-block-end: 1em;
            margin-inline-start: 40px;
            margin-inline-end: 40px;
        }
    }

    .service-detail-content{
        background-color: white;
        overflow-y: auto;
        max-height: 70vh;
        min-height: 50vh;
    }

    .service-title-content {
        color: #000;
        font-size: 16px;
        font-style: normal;
        font-weight: 600;
        line-height: 24px;
        /* 150% */
        letter-spacing: 1.6px;
        margin-bottom: 0px;
        margin-bottom: 10px;
    }
}