@import "Common.scss";
.CustomDatePicker {
    display: flex;
    height: 30px;
    padding: 0px;
    justify-content: flex-start;
    align-items: center;
    flex: 1 0 0;
    border-radius: 5px;
    background: #FFF;
    box-shadow: 4px 4px 6px 0px rgba(0, 0, 0, 0.05);
    // position: relative;
    cursor: default;
    border: 0.5px solid #929292;

    &.Custom_Form_Search {
        height: 40px !important;
        border: none;
        box-shadow: none;
        border-radius: 6px;
    }

    &.DatePicker_Error {
        border: 0.5px solid #FF2C00;
    }

    input {
        border: none;
        background-color: transparent;
        outline: none;
        color: #000000;
        font-size: 12px !important;
        width: 100%;
    }

    .Icon {
        // position: absolute;
        // right: 10px;
        // top: 0px;
        // transform: translate(-50%, 10%);
        display: flex;
        justify-content: center;
        align-items: center;
        cursor: pointer;
        height: 100%;
        margin-right: 10px;

        svg {
            color: #000000;
        }
    }

    p {
        font-size: 14px;
        font-weight: 400;
        line-height: 19px;
        letter-spacing: 0em;
        text-align: center;
        color: #000000;
    }

    :global {

        .react-datepicker-popper[data-placement^=bottom] {
            z-index: 2001;
            padding-top: 0px;
        }

        .react-datepicker__header--time{
            display: flex;
            align-items: center;
            justify-content: center;

            div{
                color: $btnDarkBgColor !important;
            }
        }


        .react-datepicker__time-list-item--selected{
            background-color: $btnDarkBgColor !important;
        }

        .react-datepicker-wrapper {
            height: 100%;
            display: flex;
            align-items: center;
            justify-content: flex-start;
        }

        .react-datepicker {
            height: unset !important;

            .react-datepicker__month {
                height: unset !important;
            }
        }

        .react-datepicker__input-container {
            height: 100%;
            display: flex;
            align-items: center;

            input {
                width: 100%;
                outline: none;
                border: none;
                background-color: transparent;
                box-shadow: none;
                min-height: 30px;
                height: 100%;
                font-size: 14px;
                padding-inline: 10px;
            }
        }

        .react-datepicker__triangle::after {
            display: none;
        }

        .react-datepicker__triangle::before {
            display: none;
        }

        .react-datepicker {
            margin-top: 10px;
            border-radius: 8px;
            background: var(--ffffff, #FFF);
            box-shadow: 0px 4px 10px 0px rgba(0, 0, 0, 0.10);
            border: 1px solid white;
        }

        .react-datepicker__header {
            background: var(--ffffff, #FFF);
        }

        .react-datepicker__navigation-icon {
            &::before {
                border-color: #000000;
                border-width: 1.5px 1.5px 0px 0px !important;
            }
        }

        .react-datepicker__day--in-range {
            color: #FFFFFF;
            text-align: center;
            font-family: "Noto Sans JP";
            font-size: 18px;
            font-weight: 400;
            line-height: 30px;
            border-radius: 50%;
            width: 27.2px;
            height: 27.2px;
            background-color: rgba(45, 140, 157, 0.655) !important;
            color: #FFFFFF !important;

            &::before {
                background-color: rgba(45, 140, 157, 0.655) !important;
            }

            &:focus-visible {
                background-color: $mainBg !important;
                color: #FFFFFF !important;
                border: none !important;
                outline: none !important;
            }

            &:hover {
                background-color: $mainBg !important;
                color: #FFFFFF;
            }
        }

        .react-datepicker__day {
            line-height: unset !important;
        }

        .react-datepicker__day--selected:hover,
        .react-datepicker__day--in-selecting-range:hover,
        .react-datepicker__day--in-range:hover,
        .react-datepicker__month-text--selected:hover,
        .react-datepicker__month-text--in-selecting-range:hover,
        .react-datepicker__month-text--in-range:hover,
        .react-datepicker__quarter-text--selected:hover,
        .react-datepicker__quarter-text--in-selecting-range:hover,
        .react-datepicker__quarter-text--in-range:hover,
        .react-datepicker__year-text--selected:hover,
        .react-datepicker__year-text--in-selecting-range:hover,
        .react-datepicker__year-text--in-range:hover {
            border: none;
            background-color: var(--brand) !important;
            color: var(--dark) !important;
        }

        .react-datepicker-wrapper {
            width: 100%;
        }

        .react-datepicker__day--in-selecting-range {
            background-color: #67BFCE !important;
            border-radius: 0 !important;
            color: #fff;
        }

        .react-datepicker__day--in-range:hover::before {
            content: "";
            position: absolute;
            top: 0%;
            left: 0%;
            width: 30px;
            height: 30px;
            background-color: $mainBg;
            border-radius: 50% !important;
            z-index: -1;
        }

        .react-datepicker__day--in-range:not(.Sat, .Sun, .react-datepicker__day--range-start.react-datepicker__day--range-end)::after {
            content: "";
            position: absolute;
            top: 0%;
            left: -50%;
            width: 60px;
            height: 30px;
            background-color: #67BFCE;
            z-index: -2;
        }

        .react-datepicker__day--in-range.Sat:hover:not(.react-datepicker__day--range-start, .react-datepicker__day--range-end):after {
            content: "";
            position: absolute;
            top: 0%;
            left: -50%;
            width: 30px;
            height: 30px;
            background-color: #67BFCE;
            z-index: -2;
        }

        .react-datepicker__day--in-range.Sun:hover:not(.react-datepicker__day--range-start, .react-datepicker__day--range-end):after {
            content: "";
            position: absolute;
            top: 0%;
            left: 50%;
            width: 30px;
            height: 30px;
            background-color: #67BFCE;
            z-index: -2;
        }

        .react-datepicker__day--in-range.Sat:not(.react-datepicker__day--range-start, .react-datepicker__day--range-end):after {
            content: "";
            position: absolute;
            top: 0%;
            left: -50%;
            width: 30px;
            height: 30px;
            background-color: #67BFCE;
            z-index: -2;
        }

        .react-datepicker__day--in-range.Sun:not(.react-datepicker__day--range-start, .react-datepicker__day--range-end):after {
            content: "";
            position: absolute;
            top: 0%;
            left: 50%;
            width: 30px;
            height: 30px;
            background-color: #67BFCE;
            z-index: -2;
        }

        .react-datepicker__day--in-range {
            background-color: #67BFCE !important;
            color: #fff;
            border-radius: 0;
            width: 45px !important;
        }

        .react-datepicker__day--in-selecting-range.Sat:not(.react-datepicker__day--selecting-range-start, .react-datepicker__day--selecting-range-end),
        .react-datepicker__day--in-range.Sat:not(.react-datepicker__day--range-start, .react-datepicker__day--range-end) {
            position: relative;
            background-color: #67BFCE !important;
            width: 30px !important;
            height: 30px !important;
            margin-left: 7.5px !important;
            margin-right: 7.5px !important;
            border-radius: 50% !important;
            z-index: 1;
        }

        .react-datepicker__day--in-selecting-range.Sat:not(.react-datepicker__day--selecting-range-start, .react-datepicker__day--selecting-range-end):after,
        .react-datepicker__day--in-range.Sat:not(.react-datepicker__day--range-start, .react-datepicker__day--range-end):after {
            content: "";
            position: absolute;
            top: 0%;
            left: -50%;
            width: 30px;
            height: 30px;
            background-color: #67BFCE;
            z-index: -2;
        }

        .react-datepicker__day--in-selecting-range.Sun:not(.react-datepicker__day--selecting-range-start, .react-datepicker__day--selecting-range-end),
        .react-datepicker__day--in-range.Sun:not(.react-datepicker__day--range-start, .react-datepicker__day--range-end) {
            position: relative;
            background-color: #67BFCE !important;
            width: 30px !important;
            height: 30px !important;
            margin-left: 7.5px !important;
            margin-right: 7.5px !important;
            border-radius: 50% !important;
            z-index: 1;
        }

        .react-datepicker__day--in-selecting-range.Sun:not(.react-datepicker__day--selecting-range-start, .react-datepicker__day--selecting-range-end):after,
        .react-datepicker__day--in-range.Sun:not(.react-datepicker__day--range-start, .react-datepicker__day--range-end):after {
            content: "";
            position: absolute;
            top: 0%;
            left: 50%;
            width: 30px;
            height: 30px;
            background-color: #67BFCE;
            z-index: -2;
        }

        .react-datepicker__day-name {
            width: 45px !important;
            margin: 0;
        }

        .react-datepicker__day {
            margin: 0px 0px 0px 0px;
            padding: 0 2.5px 0 2.5px;
            width: 45px;
            line-height: 30px;
            color: $btnDarkBgColor;
            position: relative;
            text-align: center;
            font-family: 'Noto Sans JP';
            font-size: 18px;
            font-style: normal;
            font-weight: 500;
        }

        .react-datepicker__day.react-datepicker__day--disabled {
            color: #ccc;
        }

        .react-datepicker__day--range-end {
            background-color: red;
        }

        .react-datepicker__day--selected,
        .react-datepicker__day--range-end,
        .react-datepicker__day--range-start,
        .react-datepicker__day--selecting-range-end,
        .react-datepicker__day--selecting-range-start,
        .react-datepicker__day:hover {
            position: relative;
            background-color: $mainBg !important;
            width: 30px !important;
            height: 30px !important;
            margin-left: 7.5px !important;
            margin-right: 7.5px !important;
            border-radius: 50% !important;
            color: #FFF !important;
            z-index: 1;
        }

        .react-datepicker__day--selecting-range-end:not(.Sat.Sun)::before,
        .react-datepicker__day--range-end:not(.Sun)::before {
            content: "";
            position: absolute;
            top: 0%;
            left: 0%;
            width: 30px;
            height: 30px;
            background-color: $mainBg;
            border-radius: 50% !important;
            z-index: -1;
        }

        .react-datepicker__day--selecting-range-end:not(.Sun, .react-datepicker__day--selecting-range-start.react-datepicker__day--selecting-range-end)::after,
        .react-datepicker__day--range-end:not(.Sun, .react-datepicker__day--range-start.react-datepicker__day--range-end)::after {
            content: "";
            position: absolute;
            top: 0%;
            left: -50%;
            width: 30px;
            height: 30px;
            background-color: #67BFCE;
            z-index: -2;
        }

        .react-datepicker__day--selecting-range-start:not(.Sat)::before,
        .react-datepicker__day--range-start:not(.Sat)::before {
            content: "";
            position: absolute;
            top: 0%;
            left: 0%;
            width: 30px;
            height: 30px;
            background-color: $mainBg;
            border-radius: 50% !important;
            z-index: -1;
        }

        .react-datepicker__day--selecting-range-start:not(.Sat, .react-datepicker__day--selecting-range-start.react-datepicker__day--selecting-range-end)::after,
        .react-datepicker__day--range-start:not(.Sat, .react-datepicker__day--range-start.react-datepicker__day--range-end)::after {
            content: "";
            position: absolute;
            top: 0%;
            left: 50%;
            width: 30px;
            height: 30px;
            background-color: #67BFCE;
            z-index: -2;
        }

        .react-datepicker__day--outside-month {
            color: transparent !important;
            pointer-events: none;
        }

        .react-datepicker {
            width: 330px;
            height: 325px;
            border-radius: 10px;
            border: none;
            display: flex;
        }

        .react-datepicker__header {
            border-bottom: none !important;
            height: 60px;
            background-color: #FFF;
            // margin: 18px 0 0 0;
            padding: 0 0 0 0;
        }

        .react-datepicker__month-container {
            width: 100%;
            height: 100%;
        }

        .react-datepicker__month {
            width: 100%;
            height: 274px;
            margin: 20px 0 0 0;
        }

        .custom-header {
            margin: 0px 0 12px 0;
            color: $btnDarkBgColor;
            text-align: center;
            font-family: 'Noto Sans JP';
            font-size: 16px;
            font-style: normal;
            font-weight: 500;
            line-height: 24px;
            /* 150% */
            letter-spacing: 1.6px;
            height: 24px;
            display: flex;
            justify-content: space-between;
            margin-left: 10px;
            margin-right: 10px;
        }

        .custom-header button {
            color: $btnDarkBgColor;
            font-size: 16px;
            font-style: normal;
            text-align: center;
            background: transparent;
            border: none;
            font-weight: 500;
            outline: none;
        }

        .react-datepicker__day-name {
            color: #929292 !important;
            text-align: center;
            font-family: 'Noto Sans JP';
            font-size: 13px;
            font-style: normal;
            font-weight: 500;
            line-height: 24px !important;
            /* 184.615% */
            letter-spacing: 1.3px;
            height: 24px;
        }

        .btn-arrow-rounded-icon {
            padding: 0 0 0 0 !important;
            color: $btnDarkBgColor !important;
        }

        .react-datepicker__week {
            padding: 0 0 0 0 !important;
            width: 100%;
            height: 30px;
            flex-shrink: 0;
            margin: 7px 0 7px 0 !important;
        }

        .react-datepicker__day--keyboard-selected {
            background-color: unset;
        }

        .react-datepicker__triangle {
            left: 200px !important;
            transform: none !important;
        }
    }
}

.example-custom-input {
    background-color: transparent;
    outline: none;
    border: none;
    color: #000000;
    cursor: default;
    width: 100%;
    height: 100%;
}