@import "Common.scss";

.custom-table {
    display: flex;
    flex-direction: column;
    overflow: hidden;

    .custom-table-header {
        display: flex;
        flex-direction: row;
        justify-content: flex-start;
        border-radius: 10px 10px 0px 0px;
        background: $mainBg;
        padding: 3px 20px;

        .item-row-detail{
            text-align: left;
            display: flex;
            align-items: center;
            justify-content: flex-start;
            font-size: 14px;
            width: 25%;
            color: #FFF;
            font-size: 13px;
            font-style: normal;
            font-weight: 400;
            line-height: 24px;
            width: 50px;
            min-width: 50px;
        }
        .item-row-0 {
            text-align: left;
            display: flex;
            align-items: center;
            justify-content: flex-start;
            font-size: 14px;
            width: 25%;
            color: #FFF;
            font-size: 13px;
            font-style: normal;
            font-weight: 400;
            line-height: 24px;
        }

        .item-row-1 {
            text-align: left;
            display: flex;
            align-items: center;
            justify-content: flex-start;
            font-size: 14px;
            width: 25%;

            color: #FFF;
            font-size: 13px;
            font-style: normal;
            font-weight: 400;
            line-height: 24px;
        }

        .item-row-2 {
            text-align: left;
            display: flex;
            align-items: center;
            justify-content: flex-start;
            font-size: 14px;
            width: 50%;


            color: #FFF;
            font-size: 13px;
            font-style: normal;
            font-weight: 400;
            line-height: 24px;
        }
    }

    .custom-table-body {
        overflow-y: auto;
        background-color: white;
        border-radius: 0px 0px 10px 10px;

        .item-row {
            padding: 1px 20px;
            display: flex;
            flex-direction: row;
            height: 26px;

            .item-row-detail{
                text-align: left;
                display: flex;
                align-items: center;
                justify-content: flex-start;
                font-size: 14px;
                width: 25%;
                color: #000000;
                font-size: 13px;
                font-style: normal;
                font-weight: 400;
                line-height: 24px;
                width: 50px;
                min-width: 50px;
            }

            .item-row-0 {
                text-align: left;
                display: flex;
                align-items: center;
                justify-content: flex-start;
                font-size: 14px;
                width: 25%;
                color: $btnDarkBgColor;
                font-size: 12px;
                font-style: normal;
                font-weight: 400;
                line-height: 24px;
            }

            .item-row-1 {
                text-align: left;
                display: flex;
                align-items: center;
                justify-content: flex-start;
                font-size: 14px;
                width: 25%;

                color: #000000;
                font-size: 12px;
                font-style: normal;
                font-weight: 400;
                line-height: 24px;
            }

            .item-row-2 {
                text-align: left;
                display: flex;
                align-items: center;
                justify-content: flex-start;
                font-size: 14px;
                width: 50%;


                color: #000000;
                font-size: 12px;
                font-style: normal;
                font-weight: 400;
                line-height: 24px;
            }

            &:last-child{
                .item-row-0 {
                    border-bottom-left-radius: 10px;
                }
                .item-row-2 {
                    border-bottom-right-radius: 10px;
                }
            }
        }

        .content-table {
            overflow: hidden;
            display: -webkit-box;
            -webkit-line-clamp: 1;
            -webkit-box-orient: vertical;
        }

    }
}