@import "Common.scss";
.AddFaqCategoryPopup {
    // height: 900px;
    flex-shrink: 0;
    display: flex;
    flex-direction: column;
    gap: 15px;
    background-color: #F2F2F2;
    padding: 20px;
    border-radius: 10px;
}

.Custom-Header {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    width: 100%;
    align-items: center;
    background-color: $mainBg;
    padding: 10px 20px;

    p {
        margin-bottom: 0px;
        color: #FFFFFF;
        font-size: 17px;
        font-style: normal;
        font-weight: 700;
        line-height: 32px;
        /* 188.235% */
        letter-spacing: 1.7px;
    }
}

.Option-Button {
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    width: 100%;
}
