.service-detail-popup {
    width: 1048px;

    .service-detail-content{
        background-color: white;
        overflow-y: auto;
        max-height: 80vh;
        min-height: 30vh;
    }

    .service-title-content {
        color: #000;
        font-size: 16px;
        font-style: normal;
        font-weight: 600;
        line-height: 24px;
        /* 150% */
        letter-spacing: 1.6px;
        margin-bottom: 0px;
        margin-bottom: 10px;
    }
}