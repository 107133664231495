.ConfirmPopup {
    display: flex;
    flex-direction: column;
    border-radius: 10px;
    background: #FFF;
    box-shadow: 0px 0px 15px 0px rgba(0, 0, 0, 0.25);
    width: 480px;
    overflow: hidden;

    .Body {
        display: flex;
        align-items: flex-start;
        padding: 18px 19px 20px 18px;
        flex-direction: column;
        gap: 22px;

        p {
            color: #000;
            font-size: 14px;
            font-style: normal;
            font-weight: 400;
            line-height: 32px;
            letter-spacing: 1.4px;
            text-align: left;
            margin-bottom: 0px;
        }

        .Footer {
            display: flex;
            flex-direction: row;
            align-items: center;
            gap: 20px;
            justify-content: flex-end;
            width: 100%;
        }
    }
}