@import "Common.scss";

.Box {
    display: flex;
    flex-direction: column;
    border-radius: 8px;
}

.BoxHeader {
    height: 30px;
    background-color: $mainBg;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0px 15px 0px 19px;
    border-radius: 10px 10px 0px 0px;

    span{
        display: flex;
        align-items: center;
        justify-content: center;
        cursor: pointer;

        svg{
            color: white;
        }
    }
}

.BoxTitle {
    margin-bottom: 0px;
    font-size: 14px;
    color: white;
}

.BoxLabel {
    // min-width: 200px;
    width: 140px;
    color: $btnDarkBgColor;
    font-size: 12px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    letter-spacing: 1.2px;
    align-self: flex-start;
    min-height: $inputHeight;

    display: flex;
    justify-content: flex-start;
    align-items: center;

    overflow: hidden;
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical;
    text-overflow: ellipsis;
    overflow-wrap: break-word;

    .required{
        color: #FF2C00;
    }
}

.BoxBody {
    display: flex;
    flex-direction: column;
    gap: 10px;
    background: #F2F2F2;
    padding: 14px 19px;
    border-radius: 0px 0px 10px 10px;
    overflow: hidden;
}

.BoxRow {
    display: flex;
    flex-direction: row;
    align-items: stretch;
    justify-content: flex-start;
    gap: 20px;

    @include mobile(){
        flex-direction: column;
        gap: 5px;
    }
}

.BoxContent {
    width: 100%;

    color: #000;
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    letter-spacing: 1.2px;
    align-self: center;

    overflow: hidden;
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical;
    text-overflow: ellipsis;
    overflow-wrap: break-word;
}

.AutoScroll {
    overflow-y: auto;

    // padding-right: 15px;
    // margin-right: -25px;
    /* width */
    &::-webkit-scrollbar {
        width: 10px;
    }

    /* Track */
    &::-webkit-scrollbar-track {
        background: #D9D9D9;
        border-radius: 5px;
        transform: translateX(30px)
    }

    /* Handle */
    &::-webkit-scrollbar-thumb {
        background: #929292;
        border-radius: 5px;
        border: 2px solid transparent;
        background-clip: content-box;
        transform: translateX(30px)
    }

    /* Handle on hover */
    &::-webkit-scrollbar-thumb:hover {
        background: #555;
        border: 2px solid transparent;
        background-clip: content-box;
    }
}

.BoxTemplate {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
    gap: 4px;

    .item-select-template {
        width: max-content;
        padding: 2px 7px 3px 7px;
        color: #000;
        text-align: center;
        font-size: 12px;
        font-style: normal;
        font-weight: 400;
        line-height: 12px;
        /* 100% */
        letter-spacing: 1.2px;
        border-radius: 4px;
        border: 0.5px solid #929292;
    }
}

.Section_Tab{
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 47px;
    cursor: pointer;
    position: relative;

    .Section_Title{
        position: relative;
        &::before {
            width: 100%;
            height: 4px;
            bottom: -4px;
            position: absolute;
            left: 0px;
            content: "";
            border-radius: 5px 5px 0px 0px;
            background: rgba(0, 0, 0, 0.50);
        }
    }
    .Section_Title_Active{
        &::before {
            width: 100%;
            height: 4px;
            bottom: -4px;
            position: absolute;
            left: 0px;
            content: "";
            border-radius: 5px 5px 0px 0px;
            background: #FFC30D !important;
        }
        
        .BoxTitle{
            color: #FFC30D;
        }
    }
}