.policy-container {
    width: 1048px;
    height: 100%;
    background: #FFF;

    .title {
        font-size: 16px;
        font-style: normal;
        font-weight: 500;
        line-height: 24px; /* 150% */
        letter-spacing: 1.6px;
        padding-bottom: 9px;
    }

    .content {
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        line-height: 28px; /* 200% */
        letter-spacing: 1.4px;
    }
}
