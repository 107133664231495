.form-container {
    min-width: 65vh;
    background: #FFF;
    overflow: auto;

    .inner-form-container {
        .form-group {
            display: flex;
            justify-content: space-between;
            margin-bottom: 10px;

            .form-label-left {
                display: flex;
                align-items: center;
                max-width: 20%;

                p {
                    max-width: 100%;
                    word-wrap: break-word;
                    margin: 0;
                }
            }

            .form-item-input {
                width: 80%;
                justify-content: space-between;
                display: flex;
                align-items: center;

                .inner-form-item-input {
                    width: 90%;
                }
            }
        }

        .form-group-row {
            display: flex;
            justify-content: center;
        }
    }
}

.SelectTemplate {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
    gap: 5px;
    min-width: 100%;
    min-height: 30px;
    box-sizing: border-box;
    border-radius: 4px 10px 10px 4px;
    padding-left: 5px;

    .custom-loading {
        position: absolute;
        left: 50%;
    }

    div > div > div {
        flex-wrap: wrap;
    }
}
