.ContentHeader {
    height: 60px;
    position: fixed;
    top: 0px;
    right: 0px;
    left: calc(var(--contentMenuWidth) + var(--contentChannelWidth));
    background-color: #EBEBEB;

    padding: 24px 30px;
    display: flex;
    justify-content: space-between;
    align-items: center;

    .ContentName {
        color: #000;
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        line-height: 21px;
        letter-spacing: 1.4px;
        margin-bottom: 0px;

        overflow: hidden;
        display: -webkit-box;
        -webkit-line-clamp: 1;
        -webkit-box-orient: vertical;
    }

    .ContentIcon {
        display: flex;
        align-items: center;
        justify-content: center;

        svg {
            color: #929292;
            cursor: pointer;
        }
    }

    .WrapFormSearch {
        display: flex;
        flex-direction: row;
        align-items: center;
        gap: 5px;

        svg {
            color: #929292;
        }

        &:hover {
            svg {
                color: #929292 !important;
                cursor: pointer;
            }
        }
    }
}