@import "Common.scss";
.container {
    background: #F2F2F2;
    border-radius: 10px;

    :global {
        .wrap-content-body {
            padding: 20px 30px 20px 30px;
        }
    }

    .icon {
        cursor: pointer;

        svg {
            color: #929292;
        }
    }

    .list-selected {
        table {
            thead {
                th {
                    background-color: #FFC30D !important;

                    .title-header {
                        color: #000 !important;
                    }
                }
            }
        }
    }

    .selected-holon-template {
        width: 1160px;
        display: flex;
        flex-direction: column;
        gap: 20px;

        .selected-container {
            display: flex;
            flex-direction: row;
            gap: 20px;
            flex-wrap: nowrap;


            .content-list {
                height: 352px;
                overflow-y: auto;
                gap: 22px;
                display: flex;
                flex-direction: column;
            }

            .list {
                flex: 1;
                width: 50%;
                position: relative;
                display: flex;
                flex-direction: column;

                .custom-pagination-popup {
                    display: flex;
                    justify-content: center;
                    padding-top: 25px;
                }

                .custom-form-search {
                    position: sticky;
                    top: 0;
                    display: flex;
                    flex-direction: row;
                }

                table {
                    width: 100%;
                    border-collapse: collapse;
                    overflow: hidden;

                    thead {
                        position: sticky;
                        top: 0;

                        th {
                            background: $mainBg;
                            min-width: 100px;
                            padding: 5px;
                            color: #FFFFFF;
                            font-size: 14px;
                            font-style: normal;
                            font-weight: 400;
                            line-height: normal;
                            letter-spacing: 0.24px;
                            padding: 3px 10px;
                            text-align: left;

                            &:first-child {
                                padding-left: 20px;
                                border-top-left-radius: 10px;
                            }

                            &:last-child {
                                padding-right: 20px;
                                border-top-right-radius: 10px;
                            }

                            &.content-with {
                                width: 30%;
                                max-width: 30%;
                                min-width: 100px;
                            }

                            &.content-with-action {
                                width: 30px;
                                max-width: 30px;
                                min-width: 30px;
                            }

                            .title-header {
                                overflow: hidden;
                                display: -webkit-box;
                                -webkit-line-clamp: 1;
                                -webkit-box-orient: vertical;
                                color: #FFF;
                                text-align: left;
                                font-size: 13px;
                                font-style: normal;
                                font-weight: 400;
                                line-height: 24px;
                                margin-bottom: 0px;
                            }
                        }
                    }

                    tbody {

                        tr {
                            &:hover {
                                td {
                                    background: #EBEBEB;
                                }
                            }

                            &:last-child {
                                td {
                                    &:last-child {
                                        border-bottom-right-radius: 10px;
                                        overflow: hidden;
                                    }

                                    &:first-child {
                                        border-bottom-left-radius: 10px;
                                        overflow: hidden;
                                    }
                                }
                            }
                        }

                        td {
                            color: #000;
                            font-size: 12px;
                            font-style: normal;
                            font-weight: 400;
                            line-height: normal;
                            letter-spacing: 0.24px;
                            padding: 3px 10px;
                            background: #FFF;

                            &:first-child {
                                padding-left: 20px;
                            }

                            &:last-child {
                                padding-right: 20px;
                            }

                            &.content-width {
                                width: 30%;
                                max-width: 300px;
                                min-width: 100px;

                                p {
                                    color: #000;
                                    font-size: 12px;
                                    font-style: normal;
                                    font-weight: 400;
                                    line-height: normal;
                                    letter-spacing: 0.24px;
                                    margin-bottom: 0px;
                                    overflow: hidden;
                                    display: -webkit-box;
                                    -webkit-line-clamp: 1;
                                    -webkit-box-orient: vertical;
                                    margin-bottom: 0px;
                                    text-overflow: ellipsis;
                                    overflow-wrap: break-word;
                                }
                            }

                            &.action-remove {
                                svg {
                                    color: #929292;
                                }

                                &:hover {
                                    svg {
                                        color: #D9D9D9;
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }
    }

}

.custom-pagination {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 25px;
}