@import "Common.scss";
.Custom_Input {
    position: relative;
    display: flex;
    flex-direction: column;
    gap: 10px;

    label {
        display: flex;
        flex-direction: row;
        align-items: center;
        gap: 10px;

        .Btn_Add {
            border-radius: 5px;
            height: 30px;
            width: max-content;
            background-color: $btnDarkBgColor;
            display: flex;
            align-items: center;
            justify-content: center;
            cursor: pointer;
            min-width: 50px;

            svg {
                color: white;
            }
        }

        .Form_Input {
            width: 100%;
            height: 32px;
            border-radius: 4px;
            border: 0.5px solid #929292;
            background-color: white;
            display: flex;
            align-items: center;
            justify-content: flex-start;
            font-size: 12px;
            color: #848484;
            padding: 0px 9px;
        }
    }

    .Content_File {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        justify-content: flex-start;

        gap: 10px;


        .title-file {
            white-space: pre-wrap;
            overflow: hidden;
            visibility: visible;
            overflow: hidden;
            text-overflow: ellipsis;
            display: -webkit-box;
            -webkit-line-clamp: 1;
            -webkit-box-orient: vertical;
        }

        :global {
            button {
                border: 1px solid #929292;
                cursor: default;
                font-size: 12px;

                &:hover {
                    background-color: #D9D9D9;

                    svg {
                        color: #000000;
                    }
                }
            }
        }

        .Action_Delete {
            display: flex;
            align-items: center;
            justify-content: center;
            cursor: pointer;

            &:hover {
                svg {
                    color: #000000;
                }
            }
        }
    }

    .Placeholder_Value {
        margin-bottom: 0px;
        font-size: 14px;
        color: #000000;
    }
}

.Arrow {
    &:before {
        border: 1px solid #FF0000;
        color: #ffffff;
    }
}

.Tooltip {
    font-size: 14px !important;
    background-color: #ffffff !important;
    border-radius: 3px;
    border: 1px solid #FF0000;
    margin-top: 10px !important;

    &.p {
        margin-top: 0px !important;
    }
}

.CustomBorder_Error {
    border: 1px dashed #FF0000 !important;
}

.CustomBorder_Success {
    border: 1px dashed #848484 !important;
}

.Custom_Input_Error {
    .Custom_Input_Import_File {
        border-radius: 5px;
        border: 1px dashed #FC2424 !important;
        background: #FFF;

        /* drop shadow input */
        box-shadow: 0px 1px 4px 0px rgba(0, 0, 0, 0.25);

        svg {
            color: #FC2424;
        }
    }
}

.UploadFileWarehouse {
    display: flex;
    flex-direction: column;
    gap: 7px;

    :global {
        .BtnFile {
            max-width: 100%;
        }
    }

    .ContentFile {
        white-space: pre-wrap;
        overflow: hidden;
        visibility: visible;
        overflow: hidden;
        text-overflow: ellipsis;
        display: -webkit-box;
        -webkit-line-clamp: 1;
        -webkit-box-orient: vertical;
        padding-right: 10px;
        text-align: left;
        max-width: 100%;
        word-break: break-all;
    }
}