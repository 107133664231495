.select-template {
    min-height: 29px;
    width: max-content;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    border-radius: 4px;
    border: 0.5px solid #929292;
    background: #FFF;
    // box-shadow: 1px 1px 5px 0px rgba(0, 0, 0, 0.15) inset;
    padding: 4px;

    &.select-template-empty{
        background: transparent;
        border: none;
        padding: 0px;
    }

    :global {
        button {
            .lds-spinner {
                div {
                    &::after {
                        background-color: white !important;
                    }
                }
            }
        }
    }

    &.border-none {
        background: transparent;
        border: none;
        padding: 0px;

        .template-row{
            height: 17px !important;
        }
    }

    .wrap-content-template {
        display: flex;
        justify-content: space-between;
        align-items: center;
        width: 100%;

        .template-row {
            display: flex;
            flex-direction: row;
            align-items: stretch;
            justify-content: center;
            gap: 5px;
            height: 19px;

            .template-item {
                min-width: 35px;
                max-width: max-content;
                // height: 17px;
                flex-shrink: 0;
                display: flex;
                align-items: center;
                justify-content: center;
                border-radius: 4px;
                border: 0.5px solid #929292;
                padding-left: 3px;
                padding-right: 3px;
                cursor: pointer;

                &:hover {
                    opacity: 0.7;
                }

                p {
                    margin-bottom: 0px;
                    color: #000;
                    text-align: center;
                    font-family: Noto Sans JP;
                    font-size: 12px;
                    font-style: normal;
                    font-weight: 400;
                    line-height: 12px;
                    /* 100% */
                    letter-spacing: 1.2px;
                    overflow: hidden;
                    display: -webkit-box;
                    -webkit-line-clamp: 1;
                    -webkit-box-orient: vertical;
                }

                span{
                    display: flex;
                    align-items: center;
                    justify-content: center;

                    svg{
                        color: #929292;
                    }
                }
            }

            .button-read-more {
                cursor: pointer;
                display: flex;
                align-items: center;
                justify-content: center;
            }
        }
    }
}

.Custom_Select_Error_Message {
    margin-top: 7px;
}