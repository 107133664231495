@import "Common.scss";
.content-form {
    display: flex;
    flex-direction: column;
    gap: 10px;
    display: flex;
    flex-direction: column;
    gap: 28px;

    .form {
        display: flex;
        flex-direction: column;
        gap: 20px;
    }

    .heading-form {
        display: flex;
        justify-content: space-between;
        align-items: center;

        .heading-left {
            display: flex;
            flex-direction: row;
            align-items: center;
            justify-content: flex-start;
            gap: 10px;

            .content-icon {
                display: flex;
                align-items: center;
                justify-content: center;

                button {
                    background-color: $btnBgColor;
                }
            }

            .content-title {
                color: #263238;
                font-family: Noto Sans JP;
                font-size: 17px;
                font-style: normal;
                font-weight: 700;
                line-height: 32px;
                /* 188.235% */
                letter-spacing: 1.7px;
            }
        }

        .heading-right {
            display: flex;
            align-items: center;
            justify-content: center;
        }
    }

    .content-attributes {
        display: flex;
        flex-direction: column;
        gap: 10px;

        .wrap-content-form {
            display: flex;
            flex-direction: column;

            .wrap-content-heading {
                border-radius: 10px 10px 0px 0px;
                background: $mainBg;
                padding: 7px 20px;

                p {
                    color: #FFF;
                    font-family: Noto Sans JP;
                    font-size: 14px;
                    font-style: normal;
                    font-weight: 500;
                    line-height: 22px;
                    /* 228.571% */
                    letter-spacing: 1.4px;
                    margin-bottom: 0px;
                }
            }

            .wrap-content-body {
                display: flex;
                flex-direction: column;
                gap: 10px;
                padding: 10px 20px;
                background-color: #EBEBEB;
                border-radius: 0px 0px 10px 10px;

                .wrap-content-form-item {
                    display: flex;
                    flex-direction: row;
                    align-items: center;
                    gap: 10px;

                    :global {
                        .lds-spinner {
                            div {
                                &::after {
                                    background-color: $btnDarkBgColor;
                                }
                            }
                        }
                    }

                    .label {
                        width: 150px;
                        max-width: 150px;
                        min-width: 150px;
                        margin-bottom: 0px;
                        color: $btnDarkBgColor;
                        font-family: Noto Sans JP;
                        font-size: 12px;
                        font-style: normal;
                        font-weight: 500;
                        line-height: normal;
                        letter-spacing: 1.2px;
                        height: 30px;
                        display: flex;
                        align-items: center;
                    }
                }
            }
        }
    }

    .action-button {
        display: flex;
        align-items: center;
        justify-content: center;
    }
}

.custom-input-row{
    display: flex;
    flex-direction: row;
    gap: 10px;

    .icon-center{
        display: flex;
        align-items: center;
    }
}