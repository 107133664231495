.HolonTemplate {
    display: flex;
    flex-direction: column;
    gap: 21px;

    .Custom-Search {
        :global {
            .custom-select {
                select {
                    background-color: white;
                    border: none;
                    box-shadow: 4px 4px 6px 0px rgba(0, 0, 0, 0.05);
                    border-radius: 6px;
                }
            }

            .custom-button {
                box-shadow: 4px 4px 6px 0px rgba(0, 0, 0, 0.05);
            }

            .custom-input {
                input {
                    box-shadow: 4px 4px 6px 0px rgba(0, 0, 0, 0.05);
                }
            }

            .custom-form-search {
                display: flex;
                flex-direction: row;
                justify-content: space-between;
            }
        }
    }

    .Custom-body {
        display: flex;
        flex-direction: column;
        gap: 25px;
    }

    .custom-pagination {
        display: flex;
        align-items: center;
        justify-content: center;
    }
}

.Custom-Row-Table {
    display: flex;
    align-items: center;
    justify-content: center;
}

.custom-form-search {
    flex-direction: row;
    flex-wrap: nowrap;

    :global {
        .FormGroup {
            flex-wrap: wrap !important;
        }

        .FormItem {
            flex: unset !important;
        }
    }
}