.wrap-form-search {
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    justify-content: flex-end;

    :global {
        .FormItem {
            flex-direction: row;
            flex-wrap: wrap;
        }
    }
}