.wrap-body {
    display: flex;
    flex-direction: column;
    gap: 35px;

    .wrap-form {
        display: flex;
        flex-direction: column;
        gap: 15px;
    }
}

.attributes-password {
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: 10px;
}

.form-cargo-product{
    display: flex;
    flex-direction: row;
    justify-content: space-between;

    .list-cargo-product{
        display: flex;
        flex-direction: row;
        align-items: center;
        flex-wrap: wrap;
        gap: 20px;

        .cargo-product{
            display: flex;
            align-items: center;
            justify-content: center;
            font-size: 12px;
            padding: 5px 10px;
            border: 1px solid #D9D9D9;
            border-radius: 3px;
            max-width: 200px;
            width: max-content;
        }
        
        .cargo-product-view{
            display: flex;
            align-items: center;
            justify-content: center;
            font-size: 12px;
            padding: 5px 10px;
            border: 1px solid #D9D9D9;
            border-radius: 3px;
            cursor: pointer;
            max-width: 200px;
            width: max-content;
        }
    }
}