@import "Common.scss";
.InputMessage {
    width: 100%;
    height: 70px;

    .Custom_Input {
        padding: 0px 0px 0px 10px;

        input {
            width: 100%;
            resize: none;
            border: none;
            outline: none;
            color: #000;
            font-size: 14px;
            font-style: normal;
            font-weight: 400;
            line-height: 24px;
            /* 171.429% */
            letter-spacing: 1.4px;
            line-height: 16px;
            height: 30px;

            &::placeholder {
                color: #929292;
            }

            background-color: white;

            &:active {
                background-color: white;
            }

            &:focus {
                background-color: white;
            }
        }
    }
}

.InputUploadMessage {
    width: calc(100% - 30px);
    height: 30px;

    .ListFile_Upload {
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: space-between;
        gap: 10px;
        flex-wrap: nowrap;
        position: relative;
        min-height: 30px;
        padding-left: 10px;

        .ListFile_Upload_Content {
            display: flex;
            flex-direction: row;
            align-items: center;
            justify-content: flex-start;
            gap: 10px;
            flex-wrap: nowrap;
            position: relative;
            overflow-y: auto;
        }

        .Item_Name {
            padding: 4px;
            background-color: white;
            border: 1px solid #929292ba;
            display: flex;
            align-items: center;
            justify-content: flex-start;
            text-align: center;
            border-radius: 4px;
            cursor: pointer;
            display: flex;
            flex-direction: row;
            gap: 10px;
            max-width: 200px;

            .Icon_Close {
                display: flex;
                align-items: center;
                justify-content: center;

                svg {
                    color: #929292;
                }
            }

            &.Loading {
                opacity: 0.5;
            }

            p {
                font-size: 12px;
                color: #000;
                margin-bottom: 0px;
                text-align: left;
                overflow: hidden;
                display: -webkit-box;
                -webkit-line-clamp: 1;
                -webkit-box-orient: vertical;
            }

            div {
                display: flex;
                align-items: center;
                justify-content: center;
            }

            .Loading_Upload {
                position: absolute;

                :global {
                    .lds-spinner {
                        div {
                            &::after {
                                background-color: $btnBgColor;
                            }
                        }
                    }
                }
            }
        }

        .Icon_Tag {
            display: flex;
            align-items: center;
            justify-content: center;
            cursor: pointer;
        }
    }

}

.drag-file-element {
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0px;
    right: 0px;
    bottom: 0px;
    left: 0px;
    background-color: #5cbbff3b !important;
}