@import '../login/UserLoginPopup.module.scss';

.forgot-id-pw-model-body {
    width: 558px;
    padding: 12px 26px 26px 26px;
    background: #FFF;
}

@mixin font-label {
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: 24px;
    letter-spacing: 1.2px;
}

.small-description-above {
    @include font-label;
    padding-bottom: 38px;
}

.small-description {
    @include font-label;
    padding-top: 38px;
}

.registration-btn {
    @extend .login-btn;
}

.registration-btn-container {
    padding-top: 38px;
}