@import "Common.scss";
.Custom_Input {
    border: none;
    width: 100%;
    height: $inputHeight;
    position: relative;
    border-radius: 4px;
    border: 0.5px solid #929292;
    background: #FFF;

    &.disabled {
        background-color: #D9D9D9;
    }

    input {
        background-color: transparent;
        outline: none;
        border: none;
        width: 100%;
        height: 100%;
        color: #000000;
        font-size: 12px;
        font-style: normal;
        font-weight: 400;
        line-height: 24px;
        letter-spacing: 1.2px;
        // padding: 8px 13px;
        padding: 0px 9px;

        &::placeholder {
            color: #929292;
        }
    }

    &.Error {
        border: 0.5px solid #FF2C00;
        border-radius: 5px;
        margin-bottom: 5px;
    }

    .Icon_Search {
        position: absolute;
        left: 10px;
        top: 0px;

        svg {
            color: #929292;
        }
    }

    &.Custom_Form_Search {
        .Icon_Search {
            position: absolute;
            left: 10px;
            top: 6px;

            svg {
                color: #929292;
            }
        }

        input{
            font-size: 14px;
        }
    }

    .Icon_Delete {
        width: 35px;
        height: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
        position: absolute;
        top: 0px;
        margin: auto;
        right: 5px;
        cursor: pointer;
        transform: translateY(-50%, 50%);

        svg {
            color: #FF0000;
        }
    }

    .Padding_Icon_Left {
        padding-left: 30px;
    }

    .Padding_Icon_Right {
        padding-right: 45px !important;
    }

    &.Custom_Form_Search {
        height: 40px !important;
        border: none;
        box-shadow: none;
        border-radius: 10px;
        box-shadow: 4px 4px 6px 0px rgba(0, 0, 0, 0.05);
    }
}


.arrow {
    &:before {
        border: 1px solid #FF0000;
        color: #ffffff;
    }
}