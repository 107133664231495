@import "Common.scss";
.dropdown-container {
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    z-index: 100;
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 10px;
    width: max-content;
    min-width: 176px;

    .dropdown-option-list {
        background-color: white;
        position: absolute;
        top: 105%;
        box-shadow: 4px 4px 6px 0px rgba(0, 0, 0, 0.05);
        width: max-content;
        width: max-content;
        right: 0;
        border-radius: 4px;
        border: 0.5px solid #929292;

        .dropdown-option-container {
            padding: 8px 8px;
            cursor: pointer;
            border: 1px solid #989393;
        }

        .dropdown-option-container:last-child {
            border-bottom: 1px solid #989393;
        }

        .option {
            font-size: 14px;
            display: flex;
            flex-direction: row;
            align-items: center;
        }
    }

    p {
        cursor: pointer;
    }
}

.custom-content {
    font-size: 12px;
    display: flex;
    flex-direction: row;
    gap: 10px;
    align-items: center;
    color: #000000;
    border: 0.5px solid #929292;
    border-radius: 4px;
    background-color: #FFF;
    height: 40px;
    padding: 0px 10px;
    width: 100%;

    justify-content: space-between;

    svg {
        color: #000000;
    }

    cursor: pointer;

    &.disabled{
        opacity: 0.57;
        cursor: default;
        pointer-events: none;
    }
}


.dropdown-option-container-disabled {
    pointer-events: none;
    opacity: 0.7;
    cursor: default !important;

}

.item-dropdown {
    padding: 5px 10px;
    font-size: 12px;
    height: 35px;
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: space-between;
    border-radius: 4px;
    border-bottom: 0.5px solid #98939360;
    color: #000000 !important;
    text-decoration: none;
    gap: 10px;

    &:hover {
        background-color: hsl(0, 0%, 90%) !important;
        text-decoration: none;
        color: #000000 !important;
    }

    &.disable {
        opacity: 0.5;
        pointer-events: none;
        cursor: default;
    }

    &:last-child {
        border-bottom: none;
    }


    .icon-button-loading {
        :global {
            .lds-spinner {
                div {
                    &::after {
                        background-color: $btnDarkBgColor !important;
                    }
                }
            }
        }
    }
}