.NotificationMessagePopup {
    background: #FFF;
    background-color: white;
    border-radius: 10px;
    overflow: hidden;

    :global {
        .custom-header-popup {
            padding: 5px 15px;
        }
    }

    .DeletePopup {
        display: flex;
        flex-direction: column;
        min-width: 300px;
        max-width: 700px;

        .Body {
            display: flex;
            align-items: flex-start;
            padding: 18px 19px 20px 19px;
            flex-direction: column;
            gap: 22px;

            p {
                text-align: left;
                margin-bottom: 0px;
                font-size: 14px;
                color: #333333;
                margin-bottom: 0px;
            }

            .Footer {
                display: flex;
                flex-direction: row;
                align-items: flex-end;
                gap: 20px;
                justify-content: flex-end;
                width: 100%;
            }
        }
    }
}