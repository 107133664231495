@import "Common.scss";

.account-setting-header {
    width: 100%;
    border-bottom: 1px solid $btnBgColor;
    box-sizing: border-box;
    padding: 20px 26px 0px 26px;
}

.header {
    padding-left: 4px;
    margin-bottom: 41px;
    color: #000;
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: 24px;
    letter-spacing: 1.6px;
}

.tab-name {
    all: unset;
    line-height: 24px;
    padding-bottom: 2px;
    color: #000;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    letter-spacing: 1.4px;
    padding-right: 4px;
    padding-left: 4px;
}

.inactive-tab {
    color: #D9D9D9;
    cursor: not-allowed;
    pointer-events: none;
}

.tab {
    padding-right: 22px;
}

.upload-new-img:hover,
.delete-img:hover,
.tab-name:hover {
    cursor: pointer;
}

.tab-list {
    display: flex;
}

.underline-bar.active {
    height: 4px;
    border-radius: 5px 5px 0px 0px;
    background: $btnBgColor;
}

.account-setting-container {
    padding: unset;
    // width: 1160px;
    border-radius: 0 10px 10px 0;
}

.account-setting-content {
    padding: 16px 18px 16px 18px;
}
