@import "Common.scss";
.UserAccountListContainer {
    margin: 45px 30px;
    width: calc(100% - 60px);
    border-radius: 10px;
    background: #F2F2F2;
    display: flex;
    flex-direction: column;
    padding: 20px;

    .card-content {

        .card-heading {

            font-size: 16px;
            font-style: normal;
            font-weight: 500;
            line-height: 24px;
            letter-spacing: 1.6px;
            margin-bottom: 0px;
        }
    }

    .Custom-Header {
        border-radius: 10px 10px 0px 0px;
        display: flex;
        gap: 20px;
        width: 100%;
        align-items: center;
        background-color: $mainBg;
        padding: 0px 10px;

        p {
            padding: 10px 10px;
            margin-bottom: 0;
            font-size: 14px;
            font-style: normal;
            font-weight: 500;
            line-height: 14px;
            letter-spacing: 1.4px;
            cursor: pointer;
        }

        .active_card {
            color: #FFC30D;
        }

        .normal_card {
            color: #FFF;
        }

        .bottom_line {
            height: 4px;
            border-radius: 5px 5px 0px 0px;
        }

        .active_bottom_line {
            background: #FFC30D;
        }

        .normal_bottom_line {
            background: rgba(0, 0, 0, 0.50);
            ;
        }
    }

    .holon-instance {

        div {
            margin: 0;
        }
    }
}