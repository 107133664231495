@import "Common.scss";
@mixin fitContent {
    width: fit-content;
}

.profile {
    margin-bottom: 38px;
}

.profile-name {
    color: #000;
    font-size: 10px;
    font-style: normal;
    font-weight: 400;
    line-height: 24px;
    /* 240% */
    letter-spacing: 1px;
}

.fit-content {
    @include fitContent;
}

.profile-img {
    @include fitContent;
}

.upload-new-img,
.delete-img {
    color: $btnDarkBgColor;
    font-family: Noto Sans JP;
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: 24px;
    /* 171.429% */
    letter-spacing: 1.4px;
}

.avatar-container {
    display: flex;
    flex-direction: row;
}

.edit-img {
    @include fitContent;
    margin-left: 20px;
    display: flex;
    flex-direction: column;
    justify-content: center;
}

.form-outline {
    width: 300px;
}

.form-outline-expanded {
    width: 640px;
}

.form-check-label {
    color: #000;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 14px;
    letter-spacing: 1.4px;
}

.right-label {
    @include fitContent;
    padding-left: 28px;
    display: flex;
    align-items: flex-end;
}

.row-form {
    margin-bottom: 30px;
}

.form-label {
    @extend .form-check-label;
    margin-bottom: 10px;
}

.form-check-input-name {
    padding-left: 10px;
}

.form-check-name {
    line-height: 15px;
    margin-bottom: 13px;
}

.form-check.form-check-inline {
    padding-bottom: unset;
    min-height: unset;
    height: 18px;
    line-height: 18px;
    margin-bottom: 0;
    margin-right: 40px;
}

.gender-checkbox {
    @extend .row-form;
    display: inline-block;
    position: relative;
    cursor: context-menu;
}

.form-check-input {
    display: inline-block;
    position: relative;
    cursor: pointer;
}

.profile-btn {
    width: 52px;
    height: 24px;
    flex-shrink: 0;
    border-radius: 6px;
    background: #EBEBEB;
    color: #263238;
    font-family: Noto Sans JP;
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: 24px;
    letter-spacing: 1.4px;
    border: 0;
    background-color: lightblue;
    margin-left: 20px;
}

.phone-number {
    @extend .row-form;
    display: flex;
    display: flex;
    align-items: flex-end;
}

.form-outline-postcode {
    width: 300px;
    display: flex;
    justify-content: space-between;
}

.profile-submit-btn-start {
    @extend .row-form;
    padding-right: 40px;
    width: 640px;
    display: flex;
    justify-content: flex-start;
}

.profile-submit-btn-end {
    @extend .row-form;
    padding-right: 40px;
    width: 640px;
    display: flex;
    justify-content: flex-end;
}

.choose-template-container {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.row-form-input {
    @extend .row-form;
    width: 640px;
    display: flex;
    justify-content: space-between;
}

.form-outline-with-btn {
    width: 300px;
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.custom-table-attributes {
    width: 400px;
    display: flex;
    flex-direction: column;
    gap: 10px;

    .custom-table {
        max-height: 300px;
        overflow-y: auto;
        overflow-x: hidden;
    }

    table {
        border-collapse: separate;
        border-spacing: 0px;
        width: 100%;
        margin-bottom: 0px;

        td {
            border: 1px solid #929292;
            border-bottom: none;
            cursor: pointer;
            padding: 3px 10px;
            color: #000;
            font-size: 12px;
            font-style: normal;
            font-weight: 400;
            line-height: normal;
            background-color: white;

            &:hover {
                background-color: #F2F2F2;
            }

        }

        th {
            border: 1px solid #929292;
            padding: 3px 10px;
            font-size: 13px;
            font-style: normal;
            font-weight: 400;
            line-height: normal;
            letter-spacing: 0.24px;
            background-color: $mainBg;
            color: #FFF;
            position: sticky;
            top: 0;
        }

        tr {
            &:last-child {
                td {
                    border: 1px solid #929292;
                }
            }
        }
    }

    p {
        margin-bottom: 0px;
        color: #000;
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        line-height: 24px;
        /* 150% */
        letter-spacing: 1.6px;
        white-space: nowrap;
    }
}

.custom-table-instance {
    border-radius: 10px 10px 0px 0px;
    margin-bottom: 20px;
    overflow: hidden;

    .custom-scroll-header {
        pointer-events: none;

        table {
            border-collapse: separate;
            border-spacing: 0px;
        }

        &::-webkit-scrollbar {
            display: none;
        }
    }

    .title-header {
        background: $mainBg;
        color: #FFF;
        font-size: 13px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
        vertical-align: middle;
        cursor: pointer;

    }

    .custom-scroll-body {
        width: 100%;
        position: relative;
        overflow: auto;

        &::-webkit-scrollbar {
            display: none;
        }

        table {
            border-collapse: separate;
            border-spacing: 0px;
            width: 100%;
            margin-bottom: 0px;

            thead {
                z-index: 1;
                background-color: #F2F2F2;
            }

            tbody {
                tr {

                    td {
                        background-color: white;
                    }

                    &:hover {
                        td {
                            background-color: #F2F2F2;
                        }
                    }
                }
            }
        }
    }

    .custom-content-table {
        overflow: hidden;
        display: -webkit-box;
        -webkit-line-clamp: 1;
        -webkit-box-orient: vertical;
    }

    .link-detail {
        display: flex;
        align-items: center;
        justify-content: flex-start;

        a {
            color: $mainBg;
            font-size: 12px;

            &:hover {
                text-decoration: underline;
            }
        }
    }
}

.red {
    color: red;
}