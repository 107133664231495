@import "Common.scss";
.create-holon-template {
    display: flex;
    flex-direction: column;
    gap: 15px;
}

.custom-table {
    display: flex;
    flex-direction: column;
    gap: 6px;
    overflow-x: auto;
    overflow-y: hidden;

    table {
        border-collapse: separate;
        border-spacing: 0px;
        width: 100%;
        margin-bottom: 0px;
        overflow-x: auto;

        .form-header {
            border-bottom: 1px solid #929292;
            background: #D9D9D9;
            cursor: default;

            .head-title {
                color: $btnDarkBgColor;
                text-align: left;
                font-size: 13px;
                font-style: normal;
                font-weight: 400;
                line-height: 24px;
                letter-spacing: 0.26px;
                white-space: nowrap;
                padding: 3px 0px 3px 0px;
                padding-right: 10px;

                &:last-child {
                    padding-right: 19px;
                }

                &:first-child {
                    padding-left: 19px;
                }
            }

            .input-width {
                min-width: 150px !important;
            }

            .input-key {
                min-width: 130px !important;
                max-width: 130px !important;
                width: 130px !important;
            }

            .checkbox-width {
                min-width: 30px !important;
                text-align: center;
            }

            .button-width {
                min-width: 50px;
                text-align: left !important;

                :global {
                    .Input {
                        display: flex;
                        align-items: center;
                        justify-content: center;
                    }
                }
            }

            .button-action {
                min-width: 50px;
                max-width: 50px;
                text-align: center;
            }

            .sort {
                min-width: 50px;
            }
        }

        .form-body {
            background: #EBEBEB;
            cursor: default;

            &:first-child {
                td {
                    padding-top: 8px;
                }
            }

            &:last-child {
                td {
                    padding-bottom: 12px;
                }
            }

            td {
                vertical-align: top;
                height: 36px;
                padding: 3px 0px 3px 0px;
                padding-right: 10px;

                &:last-child {
                    padding-right: 19px;
                }

                &:first-child {
                    padding-left: 19px;
                }

                &.checkbox-width {
                    :global {
                        .CustomCheckBox {
                            display: flex;
                            align-items: center;
                            justify-content: center;
                        }

                    }
                }

                &.button-action {
                    text-align: center;
                }

                &.button-action-remove {
                    cursor: pointer;
                    text-align: center;
                }
            }

            .input-width {
                min-width: 150px !important;
            }

            .button-width {
                min-width: 50px;
            }
        }
    }
}