.custom-radio-wallet-charge {
    display: flex;
    flex-direction: column;
    gap: 10px;
}

.row {
    display: flex;
    flex-direction: column;
    gap: 10px;


    .header {
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: flex-start;
        gap: 10px;
        cursor: pointer;

        p {
            margin-bottom: 0px;
            color: #000;
            font-size: 12px;
            font-style: normal;
            font-weight: 400;
            line-height: 12px;
        }

        button {
            border: none;
            background-color: transparent;
            outline: none;
        }
    }

    .body {
        display: flex;
        flex-direction: row;
        gap: 20px;
        padding: 0px 40px;

        .body-title {
            p {
                margin-bottom: 0px;
                color: #000;
                font-size: 12px;
                font-style: normal;
                font-weight: 400;
                line-height: 12px;
            }
        }

        .body-info {
            list-style-type: circle;
            display: flex;
            flex-direction: column;
            gap: 10px;

            li {
                .bank-info {
                    display: flex;
                    flex-direction: row;
                    align-items: center;
                    justify-content: flex-start;
                    gap: 20px;

                    label {
                        margin-bottom: 0px;
                        margin-bottom: 0px;
                        color: #000;
                        font-size: 12px;
                        font-style: normal;
                        font-weight: 400;
                        line-height: 12px;
                        width: 150px;
                    }

                    p {
                        margin-bottom: 0px;
                        margin-bottom: 0px;
                        color: #000;
                        font-size: 12px;
                        font-style: normal;
                        font-weight: 400;
                        line-height: 12px;
                    }
                }
            }
        }
    }
}