@font-face {
    font-family: 'Noto Sans JP';
    src: local('Noto Sans JP'), url(./assets/fonts/NotoSansJP-Black.woff2) format('opentype');
}

@font-face {
    font-family: 'Noto Sans JP';
    font-weight: 100;
    src: local('Noto Sans JP'), url(./assets/fonts/NotoSansJP-Thin.woff2) format('opentype');
}

@font-face {
    font-family: 'Noto Sans JP';
    font-weight: 300;
    src: local('Noto Sans JP'), url(./assets/fonts/NotoSansJP-Light.woff2) format('opentype');
}

@font-face {
    font-family: 'Noto Sans JP';
    font-weight: 400;
    src: local('Noto Sans JP'), url(./assets/fonts/NotoSansJP-Regular.woff2) format('woff2');
}

@font-face {
    font-family: 'Noto Sans JP';
    font-weight: 500;
    src: local('Noto Sans JP'), url(./assets/fonts/NotoSansJP-Medium.woff2) format('opentype');
}

@font-face {
    font-family: 'Noto Sans JP';
    font-weight: 700;
    src: local('Noto Sans JP'), url(./assets/fonts/NotoSansJP-Bold.woff2) format('opentype');
}
@font-face {
    font-family: 'Meiryo';
    font-weight: 400;
    src: local('meiryo_ui'), url(./assets/fonts/meiryo_ui.woff2) format('opentype');
}
@font-face {
    font-family: 'Hiragino Kaku Gothic Pro';
    font-weight: 400;
    src: local('hiragino_kaku_gothic'), url(./assets/fonts/hiragino_kaku_gothic.woff2) format('opentype');
}
@font-face {
    font-family: 'MS PGothic';
    font-weight: 400;
    src: local('ms_pgothic'), url(./assets/fonts/ms_pgothic.woff2) format('opentype');
}
@font-face {
    font-family: 'Osaka';
    font-weight: 400;
    src: local('osaka'), url(./assets/fonts/osaka.woff2) format('opentype');
}


html {
    font-size: 16px;
}

html * {
    &::-webkit-scrollbar {
        width: 10px !important;
        height: 10px !important;
    }

    /* Track */
    &::-webkit-scrollbar-track {
        background: #D9D9D9;
        border-radius: 5px;
        transform: translateX(30px)
    }

    /* Handle */
    &::-webkit-scrollbar-thumb {
        background: #929292;
        border-radius: 5px;
        border: 2px solid transparent;
        background-clip: content-box;
        transform: translateX(30px)
    }

    /* Handle on hover */
    &::-webkit-scrollbar-thumb:hover {
        background: #555;
        border: 2px solid transparent;
        background-clip: content-box;
    }
}

body {
    font-family: 'Noto Sans JP';
    --bs-modal-padding: 1.8rem;
}

body * {
    font-family: 'Noto Sans JP';
}

.d-center {
    display: flex;
    align-items: center;
    justify-content: center;
}

.d-start {
    display: flex;
    align-items: center;
    justify-content: flex-start;
}

.d-end {
    display: flex;
    align-items: center;
    justify-content: flex-end;
}

.error-msg {
    color: red;
    font-size: 12px;
}

.btn-sm {
    min-width: 6.5em;
}