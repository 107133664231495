.btn-upload {
    display: flex;
    align-items: center;
    justify-content: center;
    color: white;
    width: max-content;
    min-width: max-content;
    padding: 0px 10px;
}

.text-waring {
    color: #FF2C00;
    font-size: 13px;
    margin-top: 10px;
}