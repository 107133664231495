@import "Common.scss";
.wrap-content-body {
    display: flex;
    flex-direction: column;
    width: 100%;
    padding: 28px 40px 20px 40px;
    gap: 12px;
    overflow: hidden;

    .wrap-content {
        display: flex;
        flex-direction: column;
        gap: 35px;

        .wrap-form {
            display: flex;
            flex-direction: column;
            gap: 15px;
            overflow-y: auto;
            overflow-x: hidden;
            max-height: 60vh;
        }

        .wrap-footer {
            display: flex;
            flex-direction: row;
            align-items: center;
            justify-content: center;
        }
    }
}

.BoxContent {
    background: $modalBgColor;
    overflow: hidden;
}