@import "Common.scss";

.company-logo {
    // border: 1px dashed #67BFCE;
    // width: 100%;
    height: 42px;
    text-align: center;
    align-items: center;
    margin: 32px 30px 31px 30px;
    font-style: normal;
    font-weight: 300;
    line-height: 24px;
    /* 100% */
    letter-spacing: 2.4px;
    font-size: 24px !important;
    cursor: pointer;
}

.user-logo {
    margin-bottom: 15px !important;
    font-size: 14px;
    font-style: normal;
    font-weight: 700;
    letter-spacing: 1.4px;
    display: flex;
    flex-direction: row;
    gap: 11px;
    align-items: center;
    margin-left: 21px;
    position: relative;

    .user-name {
        text-overflow: ellipsis;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        margin: 0;
    }
}

#menu {
    width: var(--contentMenuWidth);
}

.nav-item {
    width: 100%;
}

.expand-icon {
    float: right;
}

.contract-icon {
    float: right;
}

.bg-menu {
    background: $sideMenuBg;
    width: var(--contentMenuWidth);
    overflow-y: auto;
    overflow-x: hidden;

    a {
        color: #fff;
        text-decoration: none;
    }
}

#side-menu a {
    color: #fff;
    font-size: small;
    width: var(--contentMenuWidth);
}

.submenu {
    padding-left: 10px;
    font-size: smaller;
}

.success-request {
    font-size: large;
}

.button-lang {
    position: absolute;
    bottom: 20px;
    left: 10px;
    right: 10px;

    :global {
        .custom-select {
            select {
                background-color: white;
            }
        }
    }
}

@mixin hover-style {
    color: #929292;
}

.item-button {
    // width: 300px;
    min-height: 40px;
    padding: 5px 15px 5px 5px !important;
    margin: 5px 0 5px 0 !important;

    &:hover {
        @include hover-style;
    }
}

.selected-button {
    background-color: #929292;
}

.item-text {
    min-height: 30px;
    margin: 0 0 0 0 !important;
    display: flex;
    align-items: center;
}

.item-typography {
    width: 150px;
    font-size: 14px !important;
    font-style: normal;
    font-weight: 500 !important;
    line-height: 32px !important;
    /* 228.571% */
    letter-spacing: 1.4px !important;
    font-family: 'Noto Sans JP' !important;

    &:hover {
        color: #fff;
    }
}

.item-button-content {
    height: 14px;
    padding: 15px 0 15px 0px !important;
}

.item-text-content {
    @extend .item-text;
    display: flex;
    align-items: center;
    padding-left: 36px;

    &:hover {
        @include hover-style;
    }
}

.item-typography-content {
    font-size: 12px !important;
    font-style: normal;
    font-weight: 400 !important;
    /* 116.667% */
    letter-spacing: 0.24px !important;
    font-family: 'Noto Sans JP' !important;
    line-height: 14px !important;
}

.list-button-content {
    padding: 0 0 0 10px !important;
}

.list-button-content .list-button-content .item-text-content {
    padding-left: 50px;
}

.menu-selected {
    background-color: $sideMenuHoverBgColor !important;
    p{
        color: $sideMenuHoverTextColor !important;
    }

    &:hover {
        @include hover-style;
        background-color: $sideMenuHoverBgColor !important;
    }
}

/* CustomDropdown */
.custom-dropdown {
    position: relative;

    .dropdown-header {
        cursor: pointer;
    }

    .dropdown-list {
        display: block;
        position: absolute;
        margin: 0px;
        transform: translate(10px, -10px);
        z-index: 1;
        background: #343a40;
        list-style-type: none;
        padding: 0;
        width: 10rem;
        border-radius: 4px;

        .dropdown-item {
            cursor: pointer;
            border-radius: 4px;

            &:hover {
                background: rgba(255, 255, 255, 0.15);
            }

            a {
                padding: 10px;
                display: block;
                width: 100%;
                height: 100%;

                &:hover {
                    color: $sideMenuHoverTextColor;
                }
            }
        }

        .divider {
            border-top: 1px solid rgba(255, 255, 255, 0.15);
        }
    }

    &.open {
        .dropdown-list {
            display: block;
        }
    }
}

.loading {
    width: 100%;
    align-items: center;
    display: flex;
    justify-content: center;
}

.menu-ul {
    list-style: none;
    padding-left: 15px;
}

.menu-li:hover {
    background-color: red;
}