.tooltip {
  cursor: pointer;
  width: auto;
  pointer-events: fill;
  display: flex;
  align-items: center;
  justify-content: center;

  .text {
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical;
    font-weight: 400;
    line-height: 21px;
    font-size: 13px;
    color: #FFFFFF;
    max-width: max-content;
    text-align: center;
    margin-bottom: 0px;
  }
}