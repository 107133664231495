.AlarmListPopup{
    width: 900px;
    min-height: 430px;
    display: flex;
    flex-direction: column;
    gap: 15px;
    background-color: #F2F2F2;
    border-radius: 10px;

    .section-right{
        display: flex;
        flex-direction: row;
        align-items: center;
        gap: 15px;

        div{
            display: flex;
            flex-direction: row;
            align-items: center;
            cursor: pointer;
        }
    }

    .custom-search{
        padding:5px 20px;
    }

    .custom-body{
        padding: 0px 20px;
    }
}