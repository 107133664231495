.two-column-list {
    min-width: 460px;
    background: #FFF;
    list-style: none;
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 5px;
    padding: 10px;

    li {
        text-align: center;
        padding: 8px;
        display: flex;
        justify-content: center;

        button {
            width: 200px;
        }
    }

    li:last-child:nth-child(odd) {
        grid-column: span 2;
    }
}

li > div {
    height: unset !important;
    min-height: 40px ;
}