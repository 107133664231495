.CustomRadio {
    display: grid;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
    gap: 10px;
    grid-template-columns: repeat(2, 147px);
    width: 100%;

    .Radio_Item {
        display: flex;
        flex-direction: row;
        align-items: center;
        gap: 6px;
        justify-content: flex-start;
        cursor: pointer;

        .Radio_Item_Label {
            color: #333333;
            font-size: 14px;
            font-style: normal;
            font-weight: 400;
            line-height: normal;
            margin-bottom: 0px;
            overflow: hidden;
            display: -webkit-box;
            -webkit-line-clamp: 1;
            -webkit-box-orient: vertical;
        }

        .Radio_Icon {
            display: flex;
            align-items: center;
            justify-content: center;
        }

    }

    &.CustomRadio_Disabled {
        cursor: none;
        pointer-events: none;

        .Radio_Item {
            cursor: none;
        }
    }
}