.box-content {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    flex-direction: row;
    flex-wrap: wrap;
    gap: 10px;
    width: 100%;

    .box-name {
        display: flex;
        align-items: center;
        justify-content: center;
        border: 0.5px solid #929292;
        padding: 4px;
        border-radius: 5px;
        width: max-content;

        p {
            text-align: left;
            margin-bottom: 0px;
            position: relative;
            display: inline;
            flex-grow: 1;
            flex-shrink: 1;
            overflow: hidden;
            white-space: pre;
            text-overflow: ellipsis;
        }
    }
}

.btn-attachment {
    width: 100% !important;
    button {
        font-size: 12px;
        cursor: default !important;
    }
}