@import "Common.scss";
@import './RowItem.module.scss';

.RowInput {
    @extend .RowItem;

    align-items: flex-start;

    .Label {
        display: flex;
        align-items: center;
        min-height: 30px;
    }

    .CheckboxCustom {
        display: flex;
        align-items: center;
        p {
            width: 100%;
        }
    }
}
