.cancel-order-container {
    min-width: 100px;
    min-height: 100px;
    align-items: center;

    .form-container {
        gap: 20px;
        padding: 20px;
        min-height: 100px;
        width: 500px;
        background-color: #FFF;
        display: flex;
        align-content: center;
        flex-direction: column;
        align-items: center;

        .form-text-area {
            width: 100%;
        }

        .form-header {
            width: 100%;
            display: flex;
            justify-content: space-between;
            gap: 10px;
            text-align: center;
        }
    }

    .loading-container {
        margin: 50%;
    }
}