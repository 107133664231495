.preview-content {
    display: flex;
    flex-direction: column;
    gap: 10px;
    height: 65vh;

    .header {
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: flex-start;
        gap: 10px;

        p {
            margin-bottom: 0px;
            color: #263238;
            font-size: 17px;
            font-style: normal;
            font-weight: 700;
            line-height: 32px;
            /* 188.235% */
            letter-spacing: 1.7px;
        }
    }

    .content {

        height: 100%;

        .image-link {
            width: 100%;
            // height: 100%;
            cursor: pointer;
            display: flex;
            align-items: center;
            justify-content: center;

            img {
                width: 100%;
                height: 100%;
                object-fit: cover;
                // aspect-ratio: 1;
            }
        }

        :global {
            .dTuydp {
                display: none;
            }
        }

        .custom-name-file {
            position: relative;
            display: inline;
            flex-grow: 1;
            flex-shrink: 1;
            overflow: hidden;
            white-space: pre;
            text-overflow: ellipsis;
        }
    }
}

.action-button {
    display: flex;
    justify-content: center;
    align-items: center;

    a {
        text-decoration: none !important;
        color: #263238 !important;
    }
}

.file_PDF {
    width: 100%;
    height: 100%;
    background-color: white;

    body {
        background-color: white;
        color: black;
    }
}

.file-cannot-viewer {
    width: 100%;
    height: 100%;
    background: #EBEBEB;
    padding: 21px 19px;

    p {
        margin-bottom: 0px;
        color: #000;
        font-family: Noto Sans JP;
        font-size: 12px;
        font-style: normal;
        font-weight: 400;
        line-height: 12px;
        /* 100% */
        letter-spacing: 1.2px;
    }
}

.box-body{
    display: flex;
    flex-direction: column;
    gap: 10px;
    background: #EBEBEB;
    padding: 14px 0px;
    border-radius: 0px 0px 10px 10px;
    overflow: hidden;
    align-items: center;
    justify-content: center;
}

.loading-lsd{
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    padding: 15px 0px;
    background-color: #EBEBEB;
    border-radius: 0px 0px 10px 10px;

    :global{
        .lds-spinner{
            div{
                &::after{
                    background-color: #000;
                }
            }
        }
    }
}

.layout-form{
    display: flex;
    flex-direction: row;
    gap: 5px;
    // max-width: 1301px;
}