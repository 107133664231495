.Custom_Input {
    position: relative;
    display: flex;
    flex-direction: column;
    gap: 5px;

    .Custom_Input_Import_File {
        border-radius: 5px;
        min-height: 30px;
        width: 100%;
        font-size: 14px;
        color: #000000;
        font-weight: 400;
        z-index: 1;
        background-color: #ffffff;
        outline: none;
        text-indent: 8px;
        line-height: 19.1px;
        box-shadow: 0px 1px 6px 0px #00000040;
        border-radius: 5px;
        border: 1px dashed #848484;
        border-style: border !important;
        background: #FFF;

        /* drop shadow input */
        box-shadow: 0px 1px 4px 0px rgba(0, 0, 0, 0.25);
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;
        cursor: pointer;

        position: relative;

        svg {
            color: #5CBBFF;
        }

        .content {
            display: flex;
            align-items: center;
            justify-content: center;
            flex-direction: column;
        }

        p {
            margin-bottom: 0px;
            color: var(--848484, #848484);
            font-size: 12px;
            font-style: normal;
            font-weight: 400;
            line-height: normal;
        }

    }

    .Placeholder_Value {
        white-space: pre-wrap;
        overflow: hidden;
        visibility: visible;
        overflow: hidden;
        text-overflow: ellipsis;
        display: -webkit-box;
        -webkit-line-clamp: 1;
        -webkit-box-orient: vertical;
        padding-right: 10px;
        word-break: break-all;
    }
}