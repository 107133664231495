@import "Common.scss";
.custom-table-instance {

    .custom-scroll-header {
        pointer-events: none;
        position: sticky;
        z-index: 11;
        overflow-y: hidden;
        top: 0;
        border-radius: 10px 10px 0px 0px;
        display: flex;
        flex-direction: row;
        align-items: center;
        background-color: $mainBg;

        .custom-th-header {
            height: 30px;
            width: 60px;
            min-width: 60px;
            max-width: 60px;
            position: relative;
            padding: 0px 10px;
            text-align: left;
        }
        .custom-th-header-hexID {
            height: 30px;
            width: 130px;
            min-width: 130px;
            max-width: 130px;
            position: relative;
            padding: 0px 10px;
            text-align: left;
        }

        .custom-checkbox-th-header {
            height: 30px;
            width: 40px;
            min-width: 40px;
            max-width: 40px;
            position: relative;
            padding: 0px 10px;
            cursor: pointer !important;
            pointer-events: fill;
            display: flex;
            align-items: center;
            justify-content: center;
        }

        .custom-title-header {
            height: 30px;
            width: 150px;
            min-width: 150px;
            // max-width: 150px;
            position: relative;
            padding: 0px 10px;
        }

        .custom-content-table {
            overflow: hidden;
            display: -webkit-box;
            -webkit-line-clamp: 1;
            -webkit-box-orient: vertical;
            margin-bottom: 0px;
            text-overflow: ellipsis;
            overflow-wrap: break-word;
        }

        &::-webkit-scrollbar {
            display: none;
        }
    }

    ._custom_scroll_footer {
        position: sticky;
        z-index: 11;
        max-height: 15px;
        overflow-y: hidden;
        bottom: 0;

        ._custom_scroll_footer_row {
            display: flex;
            flex-direction: row;
            height: 1px;

            .custom-th-footer {
                min-height: 38px;
                width: 50px;
                min-width: 50px;
                max-width: 50px;
                position: relative;
            }
            .custom-th-footer-hexID {
                min-height: 38px;
                width: 130px;
                min-width: 130px;
                max-width: 130px;
                position: relative;
            }

            .custom-title-footer {
                min-height: 38px;
                width: 150px;
                min-width: 150px;
                // max-width: 150px;
                position: relative;
            }

            .custom-checkbox-th-footer {
                height: 30px;
                width: 40px;
                min-width: 40px;
                max-width: 40px;
                position: relative;
                padding: 0px 10px;
                cursor: pointer !important;
                pointer-events: fill;
                display: flex;
                align-items: center;
                justify-content: center;
            }
        }
    }

    .title-header {
        color: #FFF;
        font-size: 13px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
        vertical-align: middle;
        cursor: pointer;
        display: flex;
        align-items: center;
        justify-content: flex-start;
    }

    .custom-scroll-body {
        width: 100%;
        position: relative;
        overflow: auto;
        border-bottom-left-radius: 10px;
        border-bottom-right-radius: 10px;

        .custom-scroll-body-row {
            display: flex;
            flex-direction: row;
            align-items: center;
            background-color: white;
            width: fit-content;
            min-width: 100%;

            .custom-title-body {
                min-width: 150px;
                // max-width: 150px;
                width: 150px;
                font-size: 12px;
                color: #000;
                padding: 0px 10px;
                height: 26px;
                display: flex;
                align-items: center;
                justify-content: flex-start;

                .custom-content-table {
                    overflow: hidden;
                    display: -webkit-box;
                    -webkit-line-clamp: 1;
                    -webkit-box-orient: vertical;
                    margin-bottom: 0px;
                    text-overflow: ellipsis;
                    overflow-wrap: break-word;
                }
            }

            .custom-td-body {
                min-width: 60px;
                max-width: 60px;
                width: 60px;
                font-size: 12px;
                color: #000;
                padding: 0px 10px;
                height: 26px;
                display: flex;
                align-items: center;
                justify-content: flex-start;
            }
            
            .custom-td-body-hexID {
                min-width: 130px;
                max-width: 130px;
                width: 130px;
                font-size: 12px;
                color: #000;
                padding: 0px 10px;
                height: 26px;
                display: flex;
                align-items: center;
                justify-content: flex-start;
            }

            .custom-checkbox-td-body {
                min-width: 40px;
                max-width: 40px;
                width: 40px;
                font-size: 12px;
                color: #000;
                padding: 0px 10px;
                height: 26px;
                cursor: pointer;
                display: flex;
                align-items: center;
                justify-content: center;
            }
        }

        &::-webkit-scrollbar {
            display: none;
        }
    }

    .link-detail {
        display: flex;
        align-items: center;
        justify-content: flex-start;

        a {
            color: $mainBg;
            font-size: 12px;

            &:hover {
                text-decoration: underline;
            }
        }
    }

    .custom-table-empty {
        display: flex;
        align-items: center;
        justify-content: center;
        padding: 5px;
        font-size: 12px;
        color: #000;
        background-color: white;
        border-radius: 0px 0px 10px 10px;
    }
}

.custom-pagination {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 5px;
}