.SelectTemplateInstance {
    min-height: 30px;
    width: 100%;
    display: flex;
    align-items: center;
    .template-row {
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: flex-start;
        gap: 5px;

        .template-item {
            min-width: 35px;
            // max-width: 25%;
            min-height: 17px;
            flex-shrink: 0;
            display: flex;
            align-items: center;
            justify-content: center;
            border-radius: 4px;
            border: 0.5px solid #929292;
            padding: 5px;

            p {
                margin-bottom: 0px;
                color: #000;
                text-align: center;
                font-family: Noto Sans JP;
                font-size: 12px;
                font-style: normal;
                font-weight: 400;
                line-height: 12px;
                letter-spacing: 1.2px;
                overflow: hidden;
                display: -webkit-box;
                -webkit-line-clamp: 1;
                -webkit-box-orient: vertical;
                width: 100%;
            }
        }

        .button-readmore {
            cursor: pointer;
        }
    }
}

.btnEdit>div {
    display: flex;
    justify-content: flex-start;

    button {
        min-width: 20px;
        max-width: 20px;
        height: 20px !important;
    }
}