.warehouse-container {
    display: flex;
    flex-direction: column;
    gap: 21px;

    .custom-search {

        :global {
            .custom-select {
                select {
                    background-color: white;
                    border: none;
                    box-shadow: 4px 4px 6px 0px rgba(0, 0, 0, 0.05);
                    border-radius: 6px;
                }
            }

            .custom-button {
                box-shadow: 4px 4px 6px 0px rgba(0, 0, 0, 0.05);
            }

            .custom-input {
                input {
                    box-shadow: 4px 4px 6px 0px rgba(0, 0, 0, 0.05);
                }
            }

            .custom-form-search {
                display: flex;
                flex-direction: row;
                justify-content: space-between;
            }
        }
    }

    .custom-body {
        display: flex;
        flex-direction: column;
        gap: 40px;
    }

    .custom-pagination {
        display: flex;
        align-items: center;
        justify-content: center;
    }
}

.custom-row-table {
    display: flex;
    align-items: center;
    justify-content: center;
}

.custom-form-search {
    flex-direction: row;
    flex-wrap: nowrap;
    justify-content: space-between;

    :global {
        .FormGroup {
            flex-wrap: wrap !important;
        }

        .FormItem {
            flex: unset !important;
        }
    }
}

.create-product-container {
    display: flex;
    flex-direction: column;
    gap: 24px;
    margin: 45px 30px;
    border-radius: 10px;
    background: #F2F2F2;
    padding: 20px;


    .label {
        font-size: 14px;
        color: #333333;
    }
}

.text_two_line{
    overflow: hidden;
    display: -webkit-box;
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical;
}