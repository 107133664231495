@import "Common.scss";
.estimated-fare-container-popup {
    @extend .estimated-fare-container;
    border-radius: 0px;
    width: 1000px;

    .table-result {
        table {
            width: 100%;
            overflow: auto;
            display: block;

            thead {
                background-color: $mainBg;
                border-radius: 5px;

                th {
                    text-align: center;
                }

                th:last-child {
                    min-width: 100px;
                }

                tr:first-child th:first-child {
                    border-top-left-radius: 10px;
                }

                tr:first-child th:last-child {
                    border-top-right-radius: 10px;
                }

                th {
                    min-width: 150px;
                }
            }

            tbody {
                background-color: #fff;

                td {
                    padding: 4px;
                }

                td {
                    text-align: center;
                    vertical-align: top;
                }

                tr:hover {
                    background-color: #ddd;
                }

                tr:last-child td:first-child {
                    border-bottom-left-radius: 10px;
                }

                tr:last-child td:last-child {
                    border-bottom-right-radius: 10px;
                }

                td {
                    min-width: 100px;
                }
            }
        }
    }

}

.estimated-fare-container {
    p {
        margin: 0px !important;
    }

    display: flex;
    flex-direction: column;
    gap: 20px;
    padding-bottom: 35px;

    .button-detail {
        padding: 2px 15px;
        border: none;
        background-color: rgba(0, 170, 170, 0.67);
        border-radius: 5px;
    }

    .HeaderSection {
        font-weight: 500;
        display: flex;
        flex-direction: row;
        align-items: center;

        .title-section {
            margin-right: 64px;
            font-weight: 650;
            color: #333333;
            font-size: 20px;
            font-weight: 650;
        }

        .icon-note {
            margin-left: 20px;
        }
    }

    .line-estimate {
        height: 1px;
        background-color: #d7d7d7;
        margin: 10px 0px;
    }

    .estimated-fare {
        width: 100%;
        margin-top: 40px;
        justify-content: center;
        display: flex;
        flex-direction: column;
        align-items: center;
        gap: 20px;

        .estimated-fare-render {
            width: 100%;
            display: flex;
            flex-direction: row;
            justify-content: center;
            align-items: center;

            .title-estimated-fare {

                word-break: break-all;
                display: flex;
                flex-direction: row;
                align-items: center;

                span {
                    min-width: 150px;
                    max-width: 150px;
                    font-weight: 600;
                }

                .require {
                    margin: 0px 10px;
                    min-width: 100px;
                    max-width: 100px;
                    text-align: end;
                }
            }

            select {
                border: none;
            }
        }

        .btn-submit {
            border: none;
            background-color: rgba(0, 170, 170, 0.67);
            width: 259px;
            height: 44px;
            color: #333333;
            font-weight: 650;
            text-align: center;
            line-height: normal;
            margin-top: 20px;
        }
    }



    .reference-information {
        margin: 40px 0px 30px 20px;

        p {
            font-size: 16px;
            margin: 0px;
        }

        .reference-description {
            margin-top: 13px;

            p {
                color: #d9001b;
            }
        }
    }

    .reference-contact {
        display: flex;
        width: 100%;
        justify-content: center;

        p {
            color: #00aaaa;
            font-size: 13px;
            cursor: pointer;
        }
    }

    .reference-note {
        display: flex;
        flex-direction: row;
        width: 100%;
        border: 1px solid #d7d7d7;
        padding: 25px 21px;
        height: 100%;

        .note-confirm-line {
            background: #d7d7d7;
            width: 1px;
            margin: 0px 60px;
        }

        .reference-note-confirm {
            width: 100%;

            .header-note-confirm {
                font-size: 24px;
                font-weight: 650px;
            }

            .img-note-confirm {
                width: 50%;
                display: flex;
                justify-content: center;

                img {
                    width: 147px;
                    height: 147px;
                }
            }

            .content-note-confirm {
                display: flex;
                flex-direction: row;

                .title-infor {
                    width: 50%;
                    margin-left: 10px;
                    line-height: 30px;

                    p {
                        margin: 0px;
                        font-size: 13px;
                    }
                }
            }
        }
    }

    table {
        width: 100%;
        overflow: auto;
        display: block;
        thead {
            background-color: $mainBg;
            border-radius: 5px;

            th {
                text-align: center;
            }

            th:last-child {
                min-width: 100px;
            }

            tr:first-child th:first-child {
                border-top-left-radius: 10px;
            }

            tr:first-child th:last-child {
                border-top-right-radius: 10px;
            }
        }

        tbody {
            background-color: #fff;

            td {
                padding: 4px;
            }

            td {
                text-align: center;
            }

            tr:hover {
                background-color: #ddd;
            }

            tr:last-child td:first-child {
                border-bottom-left-radius: 10px;
            }

            tr:last-child td:last-child {
                border-bottom-right-radius: 10px;
            }
        }
    }

}