@import "Common.scss";
.checkbox-label {
    color: $mainBg;
    padding-left: 7px;
    font-size: 16px;
    font-style: normal;
    font-weight: 700;
    letter-spacing: 1.6px;
    cursor: pointer;
}

.form-check {
    position: relative;
    display: inline-block;
    width: 20px;
    height: 20px;
    margin: unset;
}

.checkbox-container {
    position: relative;
    display: flex;
    align-items: center;

    .icon-container {
        display: block;
        width: 20px;
        height: 20px;
    }

    input {
        opacity: 0;
    }

    .checked-icon {
        display: none;
    }

    input:checked+.icon-container .checked-icon {
        display: block;
    }

    .unchecked-icon,
    .checked-icon {
        position: absolute;
        top: 0;
        left: 0;
        width: 20px;
        height: 20px;
        cursor: pointer;
    }
}