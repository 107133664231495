.form-group:not(input) {
    border-radius: 4px;
    border: 0.5px solid #929292;
    background: #FFF;
    padding: 2px 7px;
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    letter-spacing: 0.24px;
}
.form-group.error {
    border-color: red;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    letter-spacing: 0.24px;
}
.error-msg {
    color: red;
    font-size: 12px;
}
select {
    border: none;
}
select:focus {
    outline: none;
    border: none;
}

input:focus {
    outline: none;
}

.form-input {
    border-radius: 4px;
    border: 0.5px solid #929292;
    background: #FFF;
    box-shadow: 1px 1px 5px 0px rgba(0, 0, 0, 0.15) inset;
    padding-left: 5px;
    padding-right: 5px;
}

/* RadioButton.css */
.radio-button-container {
    position: relative;
    display: inline-block;
    width: 18px;
    height: 18px;
}

.radio-icon {
    display: block;
    width: 18px;
    height: 18px;
}
.pointer {
    cursor: pointer;
}

.unchecked-icon,
.checked-icon {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
}

.checked-icon {
    display: none;
}

/* When radio button is checked, show the checked icon and hide the unchecked icon */
input[type='radio']:checked+.radio-icon .checked-icon {
    display: block;

}

input[type='radio'] {
    opacity: 0;
}

.custom-radio {
    display: flex;
    align-content: center;
    align-items: center;
    line-height: 18px;
    min-width: 82px;
    padding-right: 10px;
}

.radio-btn-container {
    display: flex;
}

.radio-name {
    padding-left: 10px;
}

.form-select {
    border-radius: 4px;
    border: 0.5px solid #929292;
    background: #FFF;
    box-shadow: 1px 1px 5px 0px rgba(0, 0, 0, 0.15) inset;
    padding-left: 5px;
    padding-right: 5px;
    padding-bottom: 1px;
    padding-top: 1px;
}