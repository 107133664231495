@import "./Responsive.scss";

$fontMedium: 16px;
$fontSmaller: 13px;
$fontLarge: 19px;
$inputHeight: 30px;
$mainBg: #83378A;
$darkBg: #481349;
$btnBgColor: #83378A;
$btnDarkBgColor: #83378A;
$mainTextColor: #fff;
$modalBgColor: #F8F8F8;
$sideMenuBg: #481349;
$sideMenuHoverBgColor: #F9ECFF;
$sideMenuHoverTextColor: #421243;
