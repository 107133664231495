.ManagementSearchPopup {
    display: flex;
    flex-direction: column;
    gap: 20px;
    background-color: #F2F2F2;
    border-radius: 10px;
    padding: 20px;

    .wrap-content {
        display: flex;
        align-items: center;
        gap: 10px;
        justify-content: flex-start;

        .wrap-content-left {
            width: 200px;
            min-width: 200px;
            max-width: 200px;

            p {
                margin-bottom: 0px;
                color: #000;
                font-size: 14px;
                font-style: normal;
                font-weight: 400;
                line-height: 22px;
                letter-spacing: 0.24px;
            }
        }

        .wrap-content-right {
            display: flex;
            flex-direction: row;
            align-items: center;
            justify-content: center;
            gap: 10px;
            width: 100%;

            :global {
                .custom-date-picker {
                    border: 0.5px solid #929292;
                }
            }
        }
    }
}