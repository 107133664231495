.child-attribute {
    width: 250px;
    padding-left: 20px;
}

.bottom-buttons {
    gap: 50px;
    display: flex;
}


.action-button {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 20px;
    margin-top: 20px;
}

.btn-upload {
    display: flex;
    align-items: center;
    justify-content: center;
    color: white;
    width: max-content;
    min-width: max-content;
    padding: 0px 10px;
}


.body-info {
    list-style-type: circle;
    display: flex;
    flex-direction: column;
    gap: 10px;
    margin-top: 5px;
    margin-left: -15px;

    li {
        .bank-info {
            display: flex;
            flex-direction: row;
            align-items: center;
            justify-content: flex-start;
            gap: 20px;

            label {
                margin-bottom: 0px;
                margin-bottom: 0px;
                color: #000;
                font-size: 12px;
                font-style: normal;
                font-weight: 400;
                line-height: 12px;
                width: 210px;

                span{
                    color: #FF2C00;
                    font-size: 12px;
                }
            }
        }
    }
}