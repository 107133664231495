.text {
    margin-bottom: 0px;
    position: relative;
    display: inline;
    flex-grow: 1;
    flex-shrink: 1;
    overflow: hidden;
    white-space: pre;
    text-overflow: ellipsis;
    word-break: break-all;
    max-width: 100%;
}