@import '../login/UserLoginPopup.module.scss';
@import "Common.scss";

@mixin inner-content {
    margin-left: 6px;
    margin-right: 6px;
    padding-left: 20px;
    padding-right: 33px;
}

$border-style: 1px solid #EBEBEB;

.inquiry-container {
    padding-top: 28px;
    min-width: 852px;
    background: #FFF;
    padding-bottom: 50px;
}

.stepper-container {
    display: flex;
    justify-content: center;
    
    .stepper-content {
        width: 480px;
    }
}

.open-time {
    display: flex;
    justify-content: center;
    margin-top: 34px;
    margin-bottom: 34px;

    .open-time-content {
        color: #263238;
        text-align: center;
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        width: 650px;
        line-height: 24px;
        /* 171.429% */
        letter-spacing: 1.4px;
    }
}

.input-wrap {
    @include inner-content;

    border-top: $border-style;
    box-sizing: border-box;
    display: flex;
    justify-content: space-between;
    align-items: center;
    min-height: 80px;

    .input-label-required {
        width: 316px;
        display: flex;
        justify-content: space-between;

        .input-label {
            margin-bottom: 0;
            font-size: 16px;
            color: $mainBg;
            font-size: 16px;
            font-style: normal;
            font-weight: 700;
            line-height: 24px;
            letter-spacing: 1.6px;
        }
    }

    .form-input {
        width: 440px;
        display: flex;

        .textarea-place-holder {
            height: 100%;
            line-height: 43px;
            color: #929292;
            font-size: 16px;
            font-weight: 400;
            letter-spacing: 1.6px;
            padding-left: 19px;
        }
    }
}

.form-textarea {
    border-bottom: $border-style;
    margin-left: 6px;
    margin-right: 6px;

    .inner-form-textarea {
        margin-left: 20px;
        margin-right: 33px;
        margin-top: -12px;
        margin-bottom: 19px;
    }
}

.policy-container {
    @include inner-content;

    border-bottom: $border-style;
    padding-top: 24px;
    padding-bottom: 22px;

    .policy-content {
        width: 787px;
        margin-bottom: 26px;

        .first-policy {
            color: $mainBg;
            font-size: 16px;
            font-weight: 700;
            line-height: 24px;
            /* 150% */
            letter-spacing: 1.6px;
            margin-bottom: 6px;
        }

        .second-policy {
            color: $btnDarkBgColor;
            font-size: 12px;
            font-weight: 400;
            line-height: 24px;
            /* 200% */
            letter-spacing: 1.2px;
            margin-bottom: 10px;
        }

        .third-policy {
            display: flex;
            align-items: center;
            width: 'fit-content';
            display: flex;
            color: $mainBg;
            font-family: Noto Sans JP;
            font-size: 14px;
            font-style: normal;
            font-weight: 700;
            line-height: 22px;
            /* 171.429% */
            letter-spacing: 1.4px;
            margin-bottom: 10px;
            background: none;
            border: none;
            padding: 0;

            .icon-open-in-new-window {
                display: flex;
                align-items: center;
                margin-left: 8px;
            }

            &:hover {
                cursor: pointer;
                text-decoration: underline;
            }
        }
    }

    .form-checkbox-agree-policy {
        display: flex;
        justify-content: center;

        .inner-form-checkbox {
            display: flex;
            justify-content: center;
            align-items: center;
            padding: 0px 20px;
            height: 60px;
            border-radius: 5px;
            border: 2px solid #ED6041;
            background: #FFF;
        }
    }
}

.description {
    @include inner-content;

    margin-top: 18px;
    color: #263238;
    font-size: 11px;
    font-weight: 400;
    line-height: 22px;
    /* 200% */
    letter-spacing: 1.1px;
    margin-bottom: 36px;
    font-size:16px;
}

.submit-btn-container {
    margin-bottom: 26px;
    display: flex;
    justify-content: center;
    
    .login-btn {
        display: flex;
        justify-content: center;
        align-items: center;

        .left-arrow-icon {
            padding-right: 26px;
            margin-left: -38px;
        }

        .right-arrow-icon {
            padding-left: 26px;
            margin-right: -38px;
        }
    }
    .btn-label {
        font-size: 14px;
        font-weight: 700;
        line-height: 24px;
        letter-spacing: 1.4px;
    }
}

.confirm-btn-container {
    @extend .submit-btn-container;
    justify-content: space-between;
    padding: 0 26px;
}
.re-cap-cha{
    display: flex;
    justify-content: center;
}