.TermOfUseContainer {
    display: flex;
    flex-direction: column;
    gap: 23px;

    .card-title {
        .title {
            margin-bottom: 0px;
            color: #000;
            font-size: 14px;
            font-style: normal;
            font-weight: 400;
            line-height: 28px;
            /* 200% */
            letter-spacing: 1.4px;
        }
    }

    .card-content {
        display: flex;
        flex-direction: column;
        width: 100%;
        gap: 23px;
        // padding-bottom: 29px;
        // border-bottom: 1px solid $btnBgColor;

        * {
            color: #000;
            font-size: 14px;
            font-style: normal;
            font-weight: 400;
            line-height: 28px;
            /* 200% */
            letter-spacing: 1.4px;
        }

        &:last-child {
            border-bottom: none;
        }

        .card-heading {
            width: 100%;
            display: flex;

            p {
                margin-bottom: 0px;
                color: #000;
                font-size: 16px;
                font-style: normal;
                font-weight: 500;
                line-height: 24px;
                /* 150% */
                letter-spacing: 1.6px;
            }
        }

        .card-body {
            p {
                margin-bottom: 0px;
                color: #000;
                font-size: 14px;
                font-style: normal;
                font-weight: 400;
                line-height: 28px;
                /* 200% */
                letter-spacing: 1.4px;
            }

            h1 {
                color: #000;
                font-size: 14px;
                font-style: normal;
                font-weight: 400;
                line-height: 28px;
                /* 200% */
                letter-spacing: 1.4px;
            }

            ul {
                margin-left: -15px;

                li {
                    list-style-type: decimal;
                }
            }
        }
    }
}