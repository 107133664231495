@import "Common.scss";
.custom-header {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    width: 100%;
    align-items: center;
    background-color: $mainBg;
    padding: 10px 20px;

    p {
        margin-bottom: 0px;
        color: #FFFFFF;
        font-size: 17px;
        font-style: normal;
        font-weight: 700;
        line-height: 32px;
        /* 188.235% */
        letter-spacing: 1.7px;
    }

    .icon {
        width: 20px;
    }
}

.CustomHeaderPopup {
    display: flex;
    align-items: center;
    justify-content: space-between;

    p {
        margin-bottom: 0px;
        color: $btnDarkBgColor;
        font-size: 17px;
        font-style: normal;
        font-weight: 700;
        line-height: 32px;
        letter-spacing: 1.7px;
    }

    .Custom-Header-Action {
        display: flex;
        flex-direction: row;
        align-items: center;
        gap: 10px;
    }

    .HeaderLeft {
        display: flex;
        flex-direction: row;
        align-items: center;
        gap: 10px;

        svg {
            color: #000;
        }

        button {
            background-color: $btnBgColor;
        }

        p {
            margin-bottom: 0px;
            color: #263238;
            font-size: 17px;
            font-style: normal;
            font-weight: 700;
            line-height: 32px;
            /* 188.235% */
            letter-spacing: 1.7px;
        }
    }

    .HeaderLeftV2 {
        p {
            color: #000;
            font-size: 16px;
            font-style: normal;
            font-weight: 500;
            line-height: 24px;
            /* 150% */
            letter-spacing: 1.6px;
        }
    }
}