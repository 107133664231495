@import "Common.scss";
$remark-text: 700;

@mixin home-button {
    min-width: 300px;
    height: 60px;
    box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.10);
    border-radius: 50px;
    font-family: Noto Sans JP;
    font-size: 14px;
    font-weight: 700;
    line-height: 24px;
    letter-spacing: 1.4px;
    border: 0;
    color: #FFF;
    padding: 0 10px 0 10px;
}

.common-home-btn {
    @include home-button;
}

.login-model {
    width: 600px;
    border-radius: 10px;
}

.forgot-id-password {
    line-height: 24px;

    &:hover {
        text-decoration: underline;
    }
}

.login-model-body {
    width: 558px;
    padding: 12px 26px 26px 26px;
    background: #FFF;
}

.login-btn {
    @extend .common-home-btn;
    background: $btnBgColor;
}

.register-btn {
    @extend .common-home-btn;
    background: #ED6041;
}

.login-model-header {
    height: 90px;
}

.login-form-label {
    color: $mainBg;
    font-size: 16px;
    font-style: normal;
    font-weight: $remark-text;
    line-height: 24px;
    letter-spacing: 1.6px;
    margin-bottom: 5px;
}

.login-input-label {
    height: 56px;
}

.remember-checkbox {
    display: flex;
    align-items: center;
    line-height: 24px;
    margin-bottom: 33px;
}

.forget-id-pw {
    height: 60px;
    line-height: 60px;
    border-bottom: 1px solid #EBEBEB;
    margin-right: -10px;
    margin-left: -10px;
    color: $btnDarkBgColor;
    text-align: center;
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    letter-spacing: 1.2px;
}

.login-description-link {
    margin-top: 24px;
    margin-bottom: 10px;
    line-height: 24px;
}

.login-small-description {
    margin-bottom: 10px;
    line-height: 24px;
    height: 24px;
}

.login-small-description-label {
    font-size: 10px;
    font-style: normal;
    font-weight: 400;
    line-height: 24px;
    letter-spacing: 0.5px;
    color: #263238;
    text-align: center;
    font-size: 10px;
    font-style: normal;
    letter-spacing: 0.5px;
}

.login-description-link-label {
    @extend .login-form-label;
    line-height: 24px;

}

.login-model-title {
    margin-left: 24px;
    display: flex;
    justify-content: center;
    color: #FFF;
    font-size: 24px;
    font-style: normal;
    font-weight: $remark-text;
    line-height: 24px;
    /* 100% */
    letter-spacing: 4.8px;
}

.input-container {
    position: relative;

    div {
        span {
            position: absolute;
        }
    }
}

.svg-overlay {
    margin-top: 20px;
    margin-right: 25px;
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
}

.svg-icon {
    width: 20px;
    height: 20px;
    fill: $modalBgColor;
    cursor: pointer;
}

.display-overlay-icon {
    padding-right: 40px;
}

.save-checkbox-container {
    margin-top: 18px;
    margin-bottom: 38px;
}

.save-password-checkbox-container {
    margin-top: 18px;
    margin-bottom: 38px;
}

.form-input-icon {
    div {
        div:first-child {
            input {
                padding-right: 50px;
            }
        }
    }
}

@include mobile() {
    .login-model-body {
        width: 100%;
    }

    // .login-small-description-label {
    //     word-break: break-all;
    // }

    .login-small-description {
        height: unset;
    }

    .common-home-btn {
        min-width: 50%;
    }
}