@import "Common.scss";
.company-info-container {
    width: 1000px;
    max-height: calc(100vh - 100px);
    height: 900px;
    padding: 20px;
    padding-bottom: 10px;
    background: rgba(112, 176, 187, 1);
    display: flex;
    flex-direction: column;
    gap: 10px;
    overflow-y: auto;


    .data-attributes {
        width: 100%;
        display: flex;
        flex-direction: column;
        border-radius: 8px;

        .data-attributes-header {
            min-height: 30px;
            background-color: $mainBg;
            display: flex;
            align-items: center;
            justify-content: space-between;
            padding: 0px 10px 0px 0px;
            border-radius: 10px 10px 0px 0px;

            p {
                margin-bottom: 0px;
                font-size: 14px;
                color: white;
            }

            div {
                height: 30px;
            }
        }

        .data-attributes-body {
            display: flex;
            flex-direction: column;
            background: #F2F2F2;
            gap: 10px;
            border-radius: 0px 0px 10px 10px;

            .item-attributes {
                display: flex;
                flex-direction: row;
                align-items: center;
                justify-content: flex-start;
                gap: 20px;

                :global {
                    .form-input {
                        border: 1px solid #929292;
                        background-color: white;
                    }
                }

                .attributes-password {
                    width: 100%;
                    display: flex;
                    flex-direction: column;
                    gap: 5px;
                }

                .item-attributes-title {
                    margin-bottom: 0px;
                    color: $mainBg;
                    font-size: 13px;
                    font-style: normal;
                    font-weight: 500;
                    line-height: 24px;
                    /* 184.615% */
                    letter-spacing: 1.3px;
                    width: 200px;
                    min-width: 200px;
                    display: flex;
                    align-items: center;
                    justify-content: space-between;
                }
            }

            .department-name-bar {
                padding-right: 10px;
                background-color: $mainBg;
                color: white;
                padding-left: 10px;
                display: flex;
                justify-content: space-between;
                align-items: center;
                min-height: 30px;
                border-radius: 6px;

                p {
                    margin-bottom: 0px;
                    font-size: 14px;
                    color: white;
                }

                div {
                    height: 20px;
                }

                .action_btn_container {
                    display: flex;
                }
            }

            .position-name-bar {
                padding-right: 10px;
                margin-left: 20px;
                background-color: #FFC30D;
                padding-left: 10px;
                display: flex;
                justify-content: space-between;
                align-items: center;
                min-height: 30px;
                border-radius: 6px;

                p {
                    margin-bottom: 0px;
                    font-size: 14px;
                }

                div {
                    height: 20px;
                }

                .action_btn_container {
                    display: flex;
                }
            }
        }

        .save-btn {
            display: flex;
            justify-content: center;
        }
    }
}

.department-container {}

.notify-header {
    font-size: 16px;
    font-weight: 900;
    border-bottom: 1px solid;
    margin-bottom: 10px;
    display: flex;
    justify-content: space-between;
}

.Custom-body {
    display: flex;
    flex-direction: column;
    gap: 10px;
    padding-bottom: 20px;
}

.Custom-position-body {
    gap: 5px;
    display: flex;
    flex-direction: column;
}

.table_container {
    margin-left: 20px;

    .CustomTable {
        overflow: auto;
        background-color: white;
        width: inherit;
        border: 1px solid rgb(146, 146, 146);
        border-radius: 5px;

        :global {
            table {
                border-collapse: collapse;
                width: 100%;
                margin-bottom: 0px;
                table-layout: fixed;

                
                th,
                td {
                    border: 1px solid rgb(146, 146, 146);
                    overflow-wrap: break-word;
                    min-width: 150px;
                    max-width: 150px;
                    width: 150px;
                    text-align: left;
                    font-size: 13px;
                    padding: 0px 10px;
                    vertical-align: middle;
                    height: 30px;

                    &:first-child{
                        border-left: 0px;
                    }
    
                    &:last-child{
                        border-right: 0px;
                    }
                }
                

                th {
                    background: #FFF;
                    color: var(--ffffff, black);
                    font-size: 14px;
                    font-style: normal;
                    font-weight: 700;
                    line-height: normal;
                    vertical-align: middle;
                    
                    &:last-child{
                        width: 100px;
                    }
                }
                tr {
                    &:first-child{
                        th{
                            border-top: 0px;

                        }
                    }
                }

                thead {
                    z-index: 1;
                    overflow-wrap: break-word;
                    width: 150px;

                    th {}

                    td {}
                }
            }
        }
    }
}

.title_header {
    background: $mainBg;
    color: var(--ffffff, #FFF);
    font-size: 14px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    vertical-align: middle;

    &:first-child {
        border-radius: 10px 0px 0px 0px;
    }

    &:last-child {
        border-radius: 0px 10px 0px 0px;
    }

    .title_header_content {
        display: flex;
        align-items: center;
        justify-content: center;
        gap: 7px;
        // width: max-content;

        .Icon_Sort {
            cursor: pointer;
            height: 12px;
            display: flex;
            align-items: center;
            justify-content: center;
        }

        .Icon_Sort_DESC {
            svg {
                color: #FFC30D;
            }
        }

        .Icon_Sort_ASC {
            svg {
                color: #FFC30D;
            }
        }
    }
}

.viewer-actions {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
    gap: 20px;
    flex-wrap: wrap;

    a {
        padding: 0;
        color: $btnDarkBgColor !important;
        font-size: 14px;
        text-decoration: none;
        font-weight: 400;

        &:hover {
            color: $btnDarkBgColor !important;
        }
    }
}

.custom-button {
    border: 1px solid $btnDarkBgColor;
    border-radius: 4px;
    padding: 5px 5px;
}

.custom-loading {
    padding: 10px;
    width: 100%;
    display: flex;
    justify-content: center;
}

.action_column {
    width: 100%;
    display: flex;
    justify-content: center;
}