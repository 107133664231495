@import "Common.scss";
@import './InquiryDetailPopup.module.scss';
@import './CreateInquiryPopup.module.scss';

.UpdateInquiryPopup {
  @extend .CreateInquiryPopup;

  .UpdateInquiryPopupContent {
    max-height: 70vh;
    margin-right: -25px;
    padding-right: 15px;
    overflow-y: auto;
  }
}