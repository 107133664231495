@import "Common.scss";
._custom_scroll_total {
    width: 100%;
    position: relative;
    overflow: auto;
    background-color: transparent;

    &::-webkit-scrollbar {
        display: none;
    }

    :global {
        table {
            border-collapse: collapse;
            width: 100%;
            margin-bottom: 0px;

            thead {
                z-index: 1;
                overflow: hidden;

                th {
                    border: transparent !important;
                }

                td {
                    border: transparent !important;
                }
            }
        }
    }

    // ********** Table row ************
    .table_row {
        td {
            text-align: left;
            padding: 12px 0px;
            vertical-align: middle;
            font-size: 12px !important;
            color: #333333;
        }

        &:first-child {
            td {
                border-top: none !important;
            }
        }

        // ********** Table row ************
    }
}
._custom_scroll_body {
    width: 100%;
    position: relative;
    overflow: auto;
    background-color: white;

    border-bottom-left-radius: 10px;
    border-bottom-right-radius: 10px;

    &::-webkit-scrollbar {
        display: none;
    }

    :global {
        table {
            border-collapse: collapse;
            width: 100%;
            margin-bottom: 0px;

            thead {
                z-index: 1;
                overflow: hidden;

                th {
                    border: transparent !important;
                }

                td {
                    border: transparent !important;
                }
            }
        }
    }

    // ********** Table row ************
    .table_row {
        td {
            text-align: left;
            padding: 12px 0px;
            vertical-align: middle;
            font-size: 12px !important;
            color: #333333;
        }

        &:first-child {
            td {
                border-top: none !important;
            }
        }

        &:hover {
            background-color: #EBEBEB;
        }

        // ********** Table row ************

        &:last-child {
            td {
                &:first-child {
                    border-bottom-left-radius: 10px;
                }

                &:last-child {
                    border-bottom-right-radius: 10px;
                }
            }
        }
    }
}

.custom-table-empty {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 5px;
    font-size: 13px;
    color: #000;
    background-color: white;
}

.title_header {
    background: $mainBg;
    color: var(--ffffff, #FFF);
    font-size: 13px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    vertical-align: middle;

    // &:first-child {
    //     border-radius: 10px 0px 0px 0px;
    // }

    // &:last-child {
    //     border-radius: 0px 10px 0px 0px;
    // }

    .title_header_content {
        display: flex;
        align-items: center;
        justify-content: center;
        gap: 7px;
        // width: max-content;

        .Icon_Sort {
            cursor: pointer;
            height: 12px;
            display: flex;
            align-items: center;
            justify-content: center;
        }

        .Icon_Sort_DESC {
            svg {
                color: #FFC30D;
            }
        }

        .Icon_Sort_ASC {
            svg {
                color: #FFC30D;
            }
        }
    }
}

.title_header_checkbox {
    svg {
        color: white;
    }

    vertical-align: middle;
    cursor: pointer;
}

.body_checkbox {

    svg {
        color: #050505;
    }

    vertical-align: middle;
    cursor: pointer;
}

._custom_scroll_footer {
    position: sticky;
    z-index: 11;
    max-height: 15;
    overflow-y: hidden;
    bottom: 0;
}

._custom_scroll_header {
    pointer-events: none;

    border-top-left-radius: 10px;
    border-top-right-radius: 10px;

    table {
        border-collapse: separate;
        border-spacing: 0px;
    }

    &::-webkit-scrollbar {
        display: none;
    }
}

.text_two_line {
    overflow: hidden;
    display: -webkit-box;
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical;
    margin-bottom: 0px;
    text-overflow: ellipsis;
    overflow-wrap: break-word;
}

.custom_column_checkbox{
    min-width: 90px;
    max-width: 90px;
    width: 90px;
    text-align: center;
    color: #000;
    padding: 7px 0px;
    vertical-align: middle;
}