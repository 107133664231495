.content-alarm {
    display: flex;
    flex-direction: column;
    gap: 10px;

    .box-template{
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        gap: 5px;
    }

    p {
        margin-bottom: 0px;
        color: #000;
        font-size: 12px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
        letter-spacing: 0.24px;
    }

    .border {
        border-radius: 4px;
        border: 0.5px solid #929292;
        padding: 3px 7px;
        width: max-content;
    }
}
