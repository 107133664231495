.action-button {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 20px;
    margin-top: 20px;
}

.btn-upload{
    display: flex;
    align-items: center;
    justify-content: center;
    color: white;
    width: max-content;
    min-width: max-content;
    padding: 0px 10px;
}