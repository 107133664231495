.CustomSelect {
    position: relative;
    cursor: pointer;

    :global {
        .custom__select__form.react-select__indicator {
            svg {
                color: gray;
            }
        }

        .react-select__clear-indicator{
            padding: 0px;
        }

        .react-select__multi-value{
            max-width: 80%;
        }
    }
}