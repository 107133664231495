.InquiryList {

    display: flex;
    flex-direction: column;
    gap: 20px;
    padding-bottom: 35px;

    .CustomFormSearch {
        display: flex;
        flex-direction: row;
        justify-content: space-between;

        :global {
            .FormGroup {
                flex-wrap: wrap !important;
            }
    
            .FormItem {
                flex: unset !important;
            }
        }
    }

    .HeaderSection {
        font-weight: 500;
    }

    .SearchResultSection {
        display: flex;
        flex-direction: column;
        gap: 40px;
    }
}
.Id_showDetail_inquiry{
    color: blue;
    text-decoration: underline;
    margin: 0px;
    cursor: pointer;
}
.column-detail-inquiry-p{
    margin: 0px;
}