@import "Common.scss";
.MenuManagementContainer {

    .MenuManagementContainer_Title {
        margin-bottom: 40px;
        font-size: 18px;
        font-weight: 600;
        color: #000;
    }

    .MenuManagementContainer_Explain {
        margin-bottom: 20px;
        font-size: 13px;
        color: $mainBg;
        font-weight: 400;
    }

    ._custom_scroll_header {
        // pointer-events: none;

        table {
            border-collapse: separate;
            border-spacing: 0px;
        }

        &::-webkit-scrollbar {
            display: none;
        }
    }

    ._custom_scroll_body {
        width: 100%;
        position: relative;
        overflow: auto;
        border-bottom-left-radius: 10px;
        border-bottom-right-radius: 10px;

        &::-webkit-scrollbar {
            display: none;
        }

        .background_even:nth-child(even) {
            background-color: #f5f5f535;
        }

        :global {
            table {
                border-collapse: separate;
                border-spacing: 0px;
                width: 100%;
                margin-bottom: 0px;

                thead {
                    z-index: 1;
                    background-color: #F2F2F2;
                    border: 1px solid #D8D7D7;

                    th {
                        border: 1px solid #D8D7D7 !important;
                    }
                }

                tbody {
                    td {
                        border: 0.5px solid #D8D7D7 !important;
                    }
                }
            }
        }
    }

    .title_header {
        background: $mainBg;
        color: var(--ffffff, #FFF);
        font-size: 13px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
        vertical-align: middle;
        cursor: default !important;

        .custom-content {
            width: 100%;
            height: 100%;

            &::after {
                position: absolute;
                content: "";
                top: 0;
                left: 100%;
                width: 1px;
                color: $mainBg;
            }

            .custom-content-menu-name {
                position: absolute;
                bottom: 10px;
                left: 10px;
                margin-bottom: 0px;
            }

            .custom-content-user-authority {
                position: absolute;
                top: 10px;
                right: 10px;
                margin-bottom: 0px;
            }
        }

        &:first-child {
            border-radius: 10px 0px 0px 0px;
        }

        &:last-child {
            border-radius: 0px 10px 0px 0px;
        }

        .title_header_content {
            display: flex;
            align-items: center;
            justify-content: center;
            gap: 7px;

            .Icon_Sort {
                cursor: pointer;
                height: 12px;
                display: flex;
                align-items: center;
                justify-content: center;
            }

            .Icon_Sort_DESC {
                svg {
                    color: #FFC30D;
                }
            }

            .Icon_Sort_ASC {
                svg {
                    color: #FFC30D;
                }
            }
        }
    }
}

.custom-pagination {
    display: flex;
    align-items: center;
    justify-content: center;
}

.custom-button-permission {
    padding: 12px 0px;
    display: flex;
    align-items: center;
    justify-content: center;
}

.rotatabletext {
    text-align: center;
    white-space: nowrap;
    height: 100%;
    display: flex;
    align-items: flex-end;
    justify-content: flex-start;
    padding-top: 20px;
    writing-mode: vertical-lr;
    margin: auto;
    margin-top: 0px;
}

table:nth-of-type(1) .connect-line {
    background-image: linear-gradient(to top right,
            transparent 49%,
            #929292,
            transparent 50%) !important;
}

table:nth-of-type(2) .connect-line {
    background-image: linear-gradient(to top right,
            transparent calc(50% - 1px),
            #929292,
            transparent calc(50% + 1px)) !important;
}