@import "Common.scss";
.custom-table-attributes {
    // min-width: 500px;
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: 10px;

    .custom-header {
        display: flex;
        align-items: center;
        justify-content: flex-start;
        gap: 10px;
        border-radius: 10px 10px 0px 0px;
        background-color: $mainBg;
        padding: 0px 10px;

        .button-arrow {
            display: flex;
            align-items: center;
            justify-content: center;
            cursor: pointer;

            svg {
                color: #FFF;
            }
        }

        p {
            margin-bottom: 0px;
            color: #FFF;
            font-size: 12px;
            font-style: normal;
            font-weight: 400;
            line-height: 24px;
            white-space: nowrap;
        }
    }

    .body-content {
        display: flex;
        flex-direction: row;
        gap: 20px;
        max-height: 245px;

        .left {
            display: flex;
            flex-direction: column;
            flex-grow: 1;
            width: 100%;

            .title-select {
                font-size: 14px;
                color: #000;
                margin-bottom: 5px;
                text-align: center;
            }

            .content-left {
                display: flex;
                flex-direction: column;
                border-radius: 5px;
                overflow: hidden;
                border: 1px solid #D9D9D9;
                background-color: white;
                flex-grow: 1;
            }

            .custom-table {
                display: flex;
                flex-direction: column;
                // width: 250px;
                overflow-y: auto;
                overflow-x: hidden;

                .empty {
                    display: flex;
                    align-items: flex-start;
                    justify-content: center;
                    margin-bottom: 0px;
                    color: #000;
                    font-size: 12px;
                    font-style: normal;
                    font-weight: 400;
                    line-height: 24px;
                    font-style: italic;
                }

                &::-webkit-scrollbar {
                    width: 10px;
                    visibility: hidden;
                }

                /* Track */
                &::-webkit-scrollbar-track {
                    background: #D9D9D9;
                    border-radius: 5px;
                }

                /* Handle */
                &::-webkit-scrollbar-thumb {
                    background: #929292;
                    border-radius: 5px;
                    border: 2px solid transparent;
                    background-clip: content-box;
                }

                /* Handle on hover */
                &::-webkit-scrollbar-thumb:hover {
                    background: #555555c4;
                    border: 2px solid transparent;
                    background-clip: content-box;
                }

                .row {
                    border-top: 1px solid #D9D9D9;
                    border-bottom: none;
                    cursor: pointer;
                    padding: 3px 10px;
                    color: #000;
                    font-size: 12px;
                    font-style: normal;
                    font-weight: 400;
                    line-height: normal;
                    background-color: white;

                    // &:hover {
                    //     background-color: #d9d9d997 !important;
                    // }

                    &:first-child {
                        border-top: none;
                    }

                    &:last-child {
                        border-bottom: 1px solid #D9D9D9;
                    }

                    p {
                        margin-bottom: 0px;
                        color: #000;
                        font-size: 12px;
                        font-style: normal;
                        font-weight: 400;
                        line-height: 24px;
                        display: inline;
                        flex-grow: 1;
                        flex-shrink: 1;
                        overflow: hidden;
                        white-space: pre;
                        text-overflow: ellipsis;
                    }
                }
            }
        }

        .content-center {
            display: flex;
            align-items: center;
            justify-content: center;
            flex-direction: column;
            gap: 5px;

            :global {
                .button {
                    height: 35px !important;

                    button {
                        width: 45px !important;
                        height: 35px !important;
                        font-size: 20px;

                        &:disabled {
                            width: 45px !important;
                            height: 35px !important;
                            font-size: 20px;
                        }
                    }

                    &:hover {
                        button {
                            width: 45px !important;
                            height: 35px !important;
                            font-size: 20px;
                            color: #fff;
                        }
                    }
                }
            }
        }

        .content-action {
            display: flex;
            align-items: center;
            justify-content: center;
            gap: 5px;
            flex-direction: column;

            .center-button {
                display: flex;
                flex-direction: row;
                gap: 7px;
            }

            button {
                min-width: 60px;
            }

            :global {
                .button {
                    height: 35px !important;

                    button {
                        width: 45px !important;
                        height: 35px !important;
                        border: 2px solid #ffc30d;
                        background-color: #fff;
                        font-size: 20px;

                        &:disabled {
                            width: 45px !important;
                            height: 35px !important;
                            border: 2px solid #c1b89b;
                            background-color: #fff;
                            font-size: 20px;
                        }
                    }

                    &:hover {
                        button {
                            width: 45px !important;
                            height: 35px !important;
                            border: 2px solid #d7a200;
                            background-color: #ffc30d;
                            font-size: 20px;
                            color: #fff;
                        }
                    }
                }
            }
        }
    }
}

.action-button {
    display: flex;
    align-items: center;
    justify-content: center;

    :global {
        .button {
            min-height: 28px;
            height: 28px;
            min-width: 80px;
        }

        button {
            color: white !important;
        }
    }
}