@import "Common.scss";
.FaqContainer {
    border-radius: 10px;
    display: flex;
    flex-direction: column;

    .heading {
        padding: 0px 30px 10px;

        p {
            color: #000;
            font-size: 16px;
            font-style: normal;
            font-weight: 500;
            line-height: 24px;
            /* 150% */
            letter-spacing: 1.6px;
            margin-bottom: 0px;
        }
    }

    .search {
        padding: 19px 219px;
        background: $btnBgColor;

        @media (max-width: 1220px) {
            padding: 19px 30px;
        }

        .search-content {
            display: flex;
            flex-direction: row;
            align-items: center;
            justify-content: center;
            gap: 23px;
        }

        .button {
            display: flex;
            flex-direction: row;
            gap: 10px;

            :global {
                .Icon-Search {
                    button {
                        // min-width: 100px;
                        padding: 0px 21px;
                    }
                }
            }
        }
    }

    .group-search {
        padding: 30px;
        display: flex;
        flex-direction: column;
        gap: 21px;

        .heading-group-search {
            p {
                color: #000;
                font-size: 16px !important;
                font-style: normal;
                font-weight: 500;
                line-height: 24px;
                /* 150% */
                letter-spacing: 1.6px;
                margin-bottom: 0px;
            }
        }

        .group-form {
            display: flex;
            flex-direction: row;
            align-items: center;
            flex-wrap: wrap;
            gap: 20px;

            .form-item {
                display: flex;
                flex-direction: row;
                flex-wrap: nowrap;
                align-items: center;
                justify-content: center;
                gap: 10px;
                padding: 8px 20px;
                cursor: pointer;

                .category-name {
                    margin-bottom: 0px;
                    color: #000;
                    font-size: 14px;
                    font-style: normal;
                    font-weight: 400;
                    line-height: 24px;
                    letter-spacing: 1.4px;
                }

                background-color: white;
                border-radius: 10px;
                box-shadow: 4px 4px 6px 0px rgba(0, 0, 0, 0.05);
                border: none;

                .CustomIcon {
                    svg {
                        color: $btnDarkBgColor;
                    }
                }
            }

            .form-item-active {
                .category-name {
                    color: white;
                }

                .CustomIcon {
                    svg {
                        color: white;
                    }
                }

                background-color: $btnDarkBgColor;
                border-radius: 10px;
                color: white;
            }
        }
    }

    .body {
        padding: 11px 30px;

        .heading-body {
            padding-bottom: 24px;
            border-bottom: 1px solid $btnBgColor;

            p {
                margin-bottom: 0px;
                color: #000;
                font-size: 16px !important;
                font-style: normal;
                font-weight: 500;
                line-height: 24px;
                /* 150% */
                letter-spacing: 1.6px;
            }
        }

        .content-body {
            display: flex;
            flex-direction: column;

            .row-item-accordion {
                padding: 28px 0px;
                display: flex;
                flex-direction: column;
                gap: 30px;
                border-bottom: 1px solid $btnBgColor;

                .row-item-heading {
                    display: flex;
                    flex-direction: row;
                    align-items: center;
                    justify-content: space-between;

                    p {
                        margin-bottom: 0px;
                        color: #000;
                        font-size: 16px !important;
                        font-style: normal;
                        font-weight: 500;
                        line-height: 24px;
                        /* 150% */
                        letter-spacing: 1.6px;
                    }

                    .action {
                        cursor: pointer;
                        display: flex;
                        align-items: center;
                        justify-content: center;
                    }
                }

                .content-accordion {
                    color: #000;
                    font-size: 16px;
                    font-style: normal;
                    font-weight: 400;
                    line-height: 24px;
                    /* 150% */
                    letter-spacing: 1.6px;
                    display: none;
                    flex-direction: row;
                    flex-wrap: nowrap;

                    p{
                        margin-bottom: 0px;
                    }
                    &.open {
                        display: flex;
                        align-items: flex-start;
                    }
                }
            }
        }
    }
}