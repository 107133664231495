.Custom-Row-Table {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    gap: 10px;
}

.FaqManagementContainer {
    width: 100%;
    border-radius: 6px;
    background: #F2F2F2;
    display: flex;
    flex-direction: column;

    .FaqManagement-Heading {
        p {
            color: #000;
            font-size: 16px;
            font-style: normal;
            font-weight: 600;
            line-height: 24px;
            letter-spacing: 1.6px;
            margin-bottom: 0px;
        }
    }

    .FaqManagement-FormSearch {
        display: flex;
        flex-direction: column;
        gap: 10px;
        margin-bottom: 10px;

        .Form-Group {
            display: flex;
            flex-direction: row;
            align-items: center;
            gap: 20px;
            width: 100%;

            .Form-Item_Left {
                width: 100px;

                p {
                    margin-bottom: 0px;
                    color: #000;
                    font-size: 12px;
                    font-style: normal;
                    font-weight: 500;
                    line-height: 24px;
                    letter-spacing: 1.6px;
                    margin-bottom: 0px;
                }
            }

            .Form-Item-Center {
                width: 300px;
            }
        }

        .Form-Group-Row {
            display: flex;
            flex-direction: row;
            align-items: center;
            gap: 20px;
            width: 100%;
            justify-content: space-between;
            margin-top: 30px;

            .Form-Group_radio-button {
                display: flex;
                flex-direction: row;
                align-items: center;
                gap: 20px;

                p {
                    margin-bottom: 0px;
                    color: #000;
                    font-size: 12px;
                    font-style: normal;
                    font-weight: 500;
                    line-height: 24px;
                    letter-spacing: 1.6px;
                    margin-bottom: 0px;
                    white-space: nowrap;
                }
            }

            .Form-Group_button {
                width: auto;
            }
        }
    }

    .body {
        display: flex;
        flex-direction: column;
        gap: 20px;


        .custom-pagination {
            display: flex;
            align-items: center;
            justify-content: center;
        }

        .Custom-Text-Table {
            overflow: hidden;
            display: -webkit-box;
            -webkit-line-clamp: 1;
            -webkit-box-orient: vertical;

            p {
                margin-bottom: 0px;
            }
        }

        .Custom-Row-CheckBox {
            display: flex;
            align-items: center;
            cursor: pointer;
            justify-content: center;
            width: 100%;
        }
    }
}