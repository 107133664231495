.menu {
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    z-index: 20;
}

.layout-content {
    padding-left: var(--contentMenuWidth);
}

.button-lang {
    width: calc(100%);
    padding: 20px 30px;
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    padding-bottom: 0px;

    select {
        border: 1px solid #929292;
        width: 100px !important;
        border-radius: 4px;
        color: #0E0E28;
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
        text-indent: 5px;
        height: 30px;

        &:active{
            border: 1px solid #929292;
        }

        &:focus{
            border: 1px solid #929292;
        }
    }
}