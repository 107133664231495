@import "Common.scss";
.ListChannel {
    display: flex;
    flex-direction: column;
    overflow-y: auto;
    height: calc(100vh - 70px);

    // &:hover {
    //     &::-webkit-scrollbar-track {
    //         background: #D9D9D9;
    //     }

    //     &::-webkit-scrollbar-thumb {
    //         background: #929292;
    //         border: 2px solid transparent;
    //         background-clip: content-box;
    //     }
    // }

    &::-webkit-scrollbar {
        width: 10px;
        visibility: hidden;
    }

    /* Track */
    &::-webkit-scrollbar-track {
        background: #D9D9D9;
        border-radius: 5px;
    }

    /* Handle */
    &::-webkit-scrollbar-thumb {
        background: #929292;
        border-radius: 5px;
        border: 2px solid transparent;
        background-clip: content-box;
    }

    /* Handle on hover */
    &::-webkit-scrollbar-thumb:hover {
        background: #555555c4;
        border: 2px solid transparent;
        background-clip: content-box;
    }

    .item-message {
        padding: 5px 10px;
        background-color: white;
        cursor: pointer;
        display: flex;
        flex-direction: row;
        align-items: stretch;
        gap: 10px;
        justify-content: space-between;

        &.item-message-active {
            background-color: #EBEBEB;
        }

        &:hover {
            background-color: #F5F5F5;
        }

        .item-message-info {
            display: flex;
            flex-direction: row;
            align-items: stretch;
            gap: 10px;

            .avatar-user {
                width: 50px;
                min-width: 50px;
                height: 50px;
                border-radius: 50%;
                overflow: hidden;

                img {
                    width: 100%;
                    height: 100%;
                    object-fit: cover;
                }
            }

            .message-info {
                display: flex;
                flex-direction: column;
                gap: 5px;
                justify-content: flex-start;
                align-items: flex-start;
                padding-top: 3px;
                padding-bottom: 3px;


                .username {
                    margin-bottom: 0px;
                    color: #000;
                    font-size: 12px;
                    font-style: normal;
                    font-weight: 400;
                    line-height: 12px;
                    overflow: hidden;
                    display: -webkit-box;
                    -webkit-line-clamp: 1;
                    -webkit-box-orient: vertical;
                    align-items: flex-end;
                    max-width: 140px;
                }

                .message-content {
                    max-width: 125px;
                    position: relative;
                    height: 24px;
                    display: flex;
                    align-items: center;
                    justify-content: flex-start;
                }

                .message-new {
                    margin-bottom: 0px;
                    color: #263238;
                    font-size: 9px !important;
                    font-style: normal;
                    font-weight: 350;
                    line-height: 12px;
                    max-width: 125px;
                    overflow: hidden;
                    display: -webkit-box;
                    -webkit-line-clamp: 2;
                    -webkit-box-orient: vertical;
                    text-overflow: ellipsis;
                    line-height: 12px;
                }
            }
        }

        .message-time {
            width: 35px;
            display: flex;
            flex-direction: column;
            justify-content: space-between;
            gap: 8px;

            .time {
                margin-bottom: 0px;
                color: #929292;
                font-family: Noto Sans JP;
                font-size: 9px;
                font-style: normal;
                font-weight: 400;
                line-height: 12px;
                /* 133.333% */
                letter-spacing: 0.9px;
            }

            .count {
                margin-bottom: 0px;
                width: 20px;
                height: 20px;
                flex-shrink: 0;
                display: flex;
                align-items: center;
                justify-content: center;
                background-color: $btnBgColor;
                border-radius: 50%;
                margin-bottom: 0px;
                color: #FFF;
                font-size: 11px;
                font-style: normal;
                font-weight: 500;
                line-height: 19px;
                padding-bottom: 2px;
            }
        }
    }
}