$mobile-width: 480px;
$tablet-width: 768px;
$large-tablet-width: 992px;
$desktop-width: 1024px;
$large-width: 1200px;

@mixin mobile {
    @media (max-width: #{$mobile-width - 1px}) {
        @content;
    }
}

@mixin minTablet {
    @media (max-width: #{$tablet-width - 1px}) {
        @content;
    }
}

@mixin tablet {
    @media (min-width: #{$tablet-width}) and (max-width: #{$desktop-width - 1px}) {
        @content;
    }
}

@mixin tablet_lg {
    @media (max-width: #{$large-tablet-width}) {
        @content;
    }
}

@mixin desktop {
    @media (min-width: #{$desktop-width}) {
        @content;
    }
}

@mixin large {
    @media (max-width: #{$large-width}) {
        @content;
    }
}

@mixin laptop-lg {
    @media (min-width: #{$large-width + 1px}) {
        @content;
    }
}