@import "Common.scss";
.Wrap-Content {
    .CreateHolonTemplate {
        display: flex;
        flex-direction: column;
        gap: 15px;
    }
}


.FormMetadata {
    display: flex;
    flex-direction: column;
    gap: 5px;
    padding: 15px 19px;

    .FormItem {
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: flex-start;

        .FormLabel {
            min-width: 140px;
            width: 140px;

            color: $btnDarkBgColor;
            font-size: 12px;
            font-style: normal;
            font-weight: 500;
            line-height: normal;
            letter-spacing: 1.2px;
        }

        .FormInput {
            width: 300px;
            color: #000;
            font-size: 12px;
            font-style: normal;
            font-weight: 400;
            line-height: normal;
            letter-spacing: 1.2px;

            p {
                margin-bottom: 0px;
                color: #000;
                font-size: 12px;
                font-style: normal;
                font-weight: 400;
                line-height: normal;
                letter-spacing: 1.2px;
            }

            :global {
                input {
                    border-radius: 4px;
                }

                .custom-select {
                    height: 40px;

                    select {
                        border-radius: 4px;
                    }
                }
            }
        }
    }
}

.custom-table {
    display: flex;
    flex-direction: column;
    gap: 6px;
    overflow-x: auto;
    overflow-y: hidden;
    max-width: 1000px;


    /* width */
    &::-webkit-scrollbar {
        height: 10px;
    }

    /* Track */
    &::-webkit-scrollbar-track {
        background: #D9D9D9;
        border-radius: 5px;
    }

    /* Handle */
    &::-webkit-scrollbar-thumb {
        background: #929292;
        border-radius: 5px;
        border: 2px solid transparent;
        background-clip: content-box;
    }

    /* Handle on hover */
    &::-webkit-scrollbar-thumb:hover {
        background: #555;
        border: 2px solid transparent;
        background-clip: content-box;
    }

    table {
        border-collapse: separate;
        border-spacing: 0px;
        width: 100%;
        margin-bottom: 0px;
        overflow-x: auto;

        .form-header {
            border-bottom: 1px solid #929292;
            background: #D9D9D9;
            cursor: default;

            .head-title {
                color: $btnDarkBgColor;
                text-align: left;
                font-size: 13px;
                font-style: normal;
                font-weight: 400;
                line-height: 24px;
                letter-spacing: 0.26px;
                white-space: nowrap;
                padding: 3px 0px 3px 0px;
                padding-right: 10px;

                &:last-child {
                    padding-right: 19px;
                }

                &:first-child {
                    padding-left: 19px;
                }
            }

            .input-width {
                min-width: 150px !important;
            }

            .input-key {
                min-width: 130px !important;
                max-width: 130px !important;
                width: 130px !important;
            }

            .checkbox-width {
                min-width: 50px !important;
                text-align: center;
            }

            .button-width {
                min-width: 50px;
                text-align: left !important;

                :global {
                    .Input {
                        display: flex;
                        align-items: center;
                        justify-content: center;
                    }
                }
            }

            .button-action {
                min-width: 50px;
                max-width: 50px;
                text-align: center;
            }

            .sort {
                min-width: 50px;
            }
        }

        .form-body {
            background: #EBEBEB;
            cursor: default;

            td {
                vertical-align: top;
                height: 36px;
                padding: 3px 0px 3px 0px;
                padding-right: 10px;
                color: #000;
                font-size: 12px;
                font-style: normal;
                font-weight: 400;
                line-height: normal;
                letter-spacing: 0.24px;

                &:last-child {
                    padding-right: 19px;
                }

                &:first-child {
                    padding-left: 19px;
                }

                &.checkbox-width {
                    text-align: center;
                }

                &.button-action {
                    text-align: center;
                }

                &.button-action-remove {
                    cursor: pointer;
                    text-align: center;
                }
            }

            .box-template {
                display: flex;
                flex-direction: row;
                align-items: center;
                justify-content: flex-start;
                gap: 10px;
            }
        }
    }
}