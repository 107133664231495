@import "Common.scss";

@include mobile() {

    /* For mobile phones: */
    [class*="dash-col"] {
        min-width: 100%;
    }

    .receiver-noti-container {
        margin-top: 21px !important;
    }


    .header-welcome-user {
        font-size: 20px !important;
    }

    .header-date {
        display: block;
    }

    .order-btn-container {
        row-gap: 21px !important;
        column-gap: 10px !important;
        min-width: 100%;
    }

    .number-of-orders-content {
        padding-left: 10px;
        padding-right: 10px;
    }

    .order-name {
        max-width: 100% !important;
    }
}

.dashboard-container {
    padding-left: 60px;
    margin-top: 31px;
    padding-bottom: 35px;

    .container-header {
        font-family: Noto Sans JP;
        font-size: 16px;
        font-weight: 500;
        line-height: 24px;
        letter-spacing: 1.6px;
    }

    .dashboard-content {
        gap: 21px;

        .dashboard-header {
            margin-bottom: 30px;

            .header-welcome-user {
                font-size: 24px;
                font-style: normal;
                font-weight: 500;
                line-height: 24px;
                letter-spacing: 2.4px;
            }

            .header-date {
                font-size: 14px;
                font-style: normal;
                font-weight: 500;
                line-height: 24px;
                /* 171.429% */
                letter-spacing: 1.4px;
                padding-left: 20px;
            }
        }

        .dashboard-first-row {
            gap: 2%;

            .number-of-orders {
                width: 66%;
                // height: 525px;
                padding: 20px !important;
                border-radius: 10px;
                background: #F2F2F2;

                .header-content {
                    margin-bottom: 20px;
                }

                .number-of-orders-content {
                    margin: 12px 0 15px 0;
                    padding: unset;
                    // height: 60px;
                    display: flex;
                    align-items: center;
                    align-content: center;
                    background-color: #FFF;
                    border-radius: 10px;

                    .order-name {
                        font-size: 13px;
                        font-weight: 500;
                        line-height: 24px;
                        letter-spacing: 1.3px;
                        flex-basis: 156px;
                        max-width: 156px;
                    }

                    .order-btn-container {
                        display: flex;
                        flex-direction: row;
                        gap: 21px;
                        padding: 20px 5px;
                        align-self: flex-end;
                    }

                    .order-button-col {
                        display: flex;
                        flex-direction: row;
                        align-items: center;
                        align-items: flex-end;
                        gap: 10px;
                        min-width: 140px;

                        .icon-button {
                            display: flex;
                            flex-direction: row;
                            align-items: center;
                            align-items: flex-end;
                            gap: 10px;
                            &:hover {
                                opacity: 0.6;
                                cursor: pointer;
                            }
                        }

                        .icon-label {
                            padding: 0;
                            display: flex;
                            flex-direction: column;
                            justify-content: space-between;
                            gap: 10px;
                        }

                        .button-name {
                            color: #000;
                            font-family: Noto Sans JP;
                            font-size: 12px;
                            font-style: normal;
                            font-weight: 400;
                            line-height: 12px;
                            /* 100% */
                            letter-spacing: 1.2px;
                        }

                        .number-text-label {
                            display: flex;
                            // justify-content: space-between;
                            align-items: flex-end;

                            .number-label {
                                color: #000;
                                font-family: Noto Sans JP;
                                font-size: 18px;
                                font-style: normal;
                                font-weight: 700;
                                line-height: 18px;
                                /* 100% */
                                letter-spacing: 1.8px;
                                // min-width: 50px;
                            }

                            .subject-label {
                                color: #000;
                                font-family: Noto Sans JP;
                                font-size: 12px;
                                font-style: normal;
                                font-weight: 400;
                                line-height: 12px;
                                /* 100% */
                                letter-spacing: 1.2px;
                                padding-bottom: 2px;
                            }
                        }
                    }

                    // .order-button-col {
                    //     width: 105px;
                    //     padding-right: 14px;
                    //     flex-basis: 131px;
                    //     flex-grow: 0;
                    //     max-width: 131px;

                    //     .order-button {
                    //         height: 40px;
                    //         gap: 10px;

                    //         .icon-img {

                    //             padding-right: 0px !important;
                    //             flex-basis: 54px;
                    //             flex-grow: 0;
                    //             max-width: 40px;
                    //             padding: 0;

                    //             .stats-icon {
                    //                 width: 40px;
                    //                 height: 40px;
                    //             }
                    //         }

                    //         .icon-label {
                    //             padding: 0;
                    //             max-width: 51px;
                    //             display: flex;
                    //             flex-direction: column;
                    //             justify-content: space-between;

                    //             .button-name {
                    //                 color: #000;
                    //                 font-family: Noto Sans JP;
                    //                 font-size: 12px;
                    //                 font-style: normal;
                    //                 font-weight: 400;
                    //                 line-height: 12px;
                    //                 /* 100% */
                    //                 letter-spacing: 1.2px;
                    //             }

                    //             .number-text-label {
                    //                 display: flex;
                    //                 justify-content: space-between;
                    //                 align-items: flex-end;

                    //                 .number-label {
                    //                     color: #000;
                    //                     font-family: Noto Sans JP;
                    //                     font-size: 18px;
                    //                     font-style: normal;
                    //                     font-weight: 700;
                    //                     line-height: 18px;
                    //                     /* 100% */
                    //                     letter-spacing: 1.8px;
                    //                 }

                    //                 .subject-label {
                    //                     color: #000;
                    //                     font-family: Noto Sans JP;
                    //                     font-size: 12px;
                    //                     font-style: normal;
                    //                     font-weight: 400;
                    //                     line-height: 12px;
                    //                     /* 100% */
                    //                     letter-spacing: 1.2px;
                    //                 }
                    //             }

                    //         }

                    //         &:hover {
                    //             opacity: 0.6;
                    //         }
                    //     }
                    // }
                }
            }

            .receiver-noti-container {
                gap: 21px;
                width: 32%;

                .receiver-container {
                    border-radius: 10px;
                    background: $mainBg;
                    height: 150px;
                    color: #FFF;
                    padding: 20px 34px 23px 30px;

                    .inner-receiver-container {
                        display: flex;
                        flex-direction: column;
                        justify-content: space-between;

                        .receiver-header-label {
                            font-size: 16px;
                            font-style: normal;
                            font-weight: 500;
                            line-height: 24px;
                            /* 150% */
                            letter-spacing: 1.6px;

                            span {
                                &:hover {
                                    color: #FFC30D;
                                    cursor: context-menu;
                                }
                            }
                        }

                        .content-label {
                            display: flex;
                            align-items: center;
                            align-content: center;
                            justify-content: space-between;

                            .receiver-content-label {

                                font-size: 16px;
                                font-style: normal;
                                font-weight: 400;
                                letter-spacing: 1.6px;
                                line-height: 24px;

                                &:hover {
                                    color: #FFC30D;
                                }
                            }

                            .receiver-number-label {
                                font-size: 24px;
                                font-weight: 700;
                                letter-spacing: 0.48px;
                                line-height: 24px;
                            }

                            .receiver-man-label {
                                font-size: 12px;
                                font-weight: 400;
                                letter-spacing: 1.2px;
                                line-height: 24px;
                            }
                        }
                    }
                }
                
                .inquiry-contaier {
                    border-radius: 10px;
                    background: $mainBg;
                    color: #FFF;
                    padding: 10px 20px 10px 20px;
                    margin-top: 21px;
                    
                    .inner-inquiry-container {
                        display: flex;
                        flex-direction: column;
                        .content-label {
                            display: flex;
                            justify-content: space-between;
                            padding-top: 10px;
                            .inquiry-count-label:hover {
                                cursor: pointer;
                                text-decoration: underline;
                            };
                            .inquiry-count-content:hover {
                                cursor: pointer;
                                text-decoration: underline;
                            };
                        }
                    }
                }

                .message-noti-container {
                    max-height: 354px;
                    height: 354px;
                    margin-top: 21px;
                    border-radius: 10px;
                    background: #F2F2F2;

                    .message-noti {
                        gap: 21px;

                        .message-noti-row-header {
                            padding-left: 30px;
                            margin-top: 20px;

                            .message-noti-header-label {
                                padding: 0;
                            }
                        }

                        .message-noti-row-content {
                            margin-top: 21px;
                            font-size: 10px;
                            font-style: normal;
                            font-weight: 500;
                            line-height: 20px;
                            letter-spacing: 1px;
                            margin-bottom: 20px;

                            .inner-message-noti-row-content {
                                overflow-y: auto;
                                max-height: 270px;
                                height: 270px;

                                .message-noti-row {
                                    line-height: 20px;
                                    font-size: 12px;
                                    height: 20px;
                                    padding: 0 30px 0 0;

                                    .message-noti-date {
                                        padding-right: 0px;
                                        color: $btnDarkBgColor;
                                        padding-left: 30px;
                                        width: 30%;
                                        white-space: nowrap;
                                        overflow: hidden !important;
                                        text-overflow: ellipsis;
                                    }

                                    .message-noti-title {
                                        width: 70%;
                                        padding-left: 10px;
                                        padding-right: 0px;

                                        .message-noti-title-label {
                                            display: inline-block;
                                            width: 100%;
                                            // width: 180px;
                                            white-space: nowrap;
                                            overflow: hidden !important;
                                            text-overflow: ellipsis;
                                        }
                                    }

                                    &:hover {
                                        background: #D9D9D9;
                                        cursor: context-menu;
                                    }
                                }
                            }


                        }
                    }
                }
            }
        }

        .wallet-container {
            margin-top: 21px;
            border-radius: 10px;
            background: #F2F2F2;
            padding: 20px 40px;
            // max-width: 1091px;

            .wallet-row-header {
                margin-bottom: 21px;
                padding: 0;
            }

            .wallet-row-content {
                justify-content: space-between;
                gap: 4%;
                padding: 0;
                margin-left: 0 !important;
                margin-right: 0 !important;

                .wallet-card {
                    padding: 0;

                    .inner-wallet-card {
                        // min-width: 320px;
                        // max-width: 320px;
                        // width: calc(100/3);
                        padding: 0;

                        .wallet-card-header {
                            height: 40px;
                            border-radius: 10px 10px 0px 0px;
                            background: $mainBg;
                            display: flex;
                            align-items: center;
                            justify-content: center;
                            color: #FFF;
                            font-size: 16px;
                            font-weight: 400;
                            line-height: 24px;
                            letter-spacing: 3.2px;

                            img {
                                padding-right: 10px;
                            }
                        }

                        .wallet-card-content {
                            border-radius: 0px 0px 10px 10px;
                            height: 190px;

                            .wallet-row-content {
                                padding: 0 20px 0 20px;
                                line-height: 61px;
                                height: 61px;
                                border: 0;

                                .wallet-row-label {
                                    display: flex;
                                    line-height: 61px;
                                    height: 61px;
                                    border-bottom: 1px solid #EBEBEB;

                                    .content-label {
                                        font-size: 14px;
                                        font-weight: 400;
                                        letter-spacing: 1.4px;
                                        padding-left: 4px;
                                    }

                                    div {
                                        .currency-number {
                                            font-family: Noto Sans JP;
                                            font-size: 24px;
                                            font-weight: 700;
                                            letter-spacing: 0.48px;
                                        }

                                        .currency-label {
                                            font-size: 12px;
                                            font-weight: 400;
                                            letter-spacing: 1.2px;
                                        }
                                    }
                                }
                            }

                            .wallet-last-row-content {
                                .wallet-row-label {
                                    border: 0;
                                    padding-bottom: 7px;
                                }
                            }
                        }
                    }
                }
            }
        }
    }
}