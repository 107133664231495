.FormSearch {
    display: flex;
    flex-direction: column;
    gap: 20px;
    width: 100%;
}

.FormGroup {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
    gap: 20px;
    width: 100%;
}

.FormItem {
    flex: 1;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 7px;
}

.TextLabel {
    color: #000;
    font-size: 14px;
    font-style: normal;
    font-weight: 600;
    line-height: 24px;
}