@import "Common.scss";
.freight-calculation-management {

    display: flex;
    flex-direction: column;
    padding-bottom: 15px;
    width: 1000px;
    overflow: hidden;
    border-radius: 0 0 10px 10px;

    .HeaderSection {
        font-weight: 500;
    }

    .line-estimate {
        height: 1px;
        background-color: #d7d7d7;
        margin: 10px 0px;
    }

    .btn-form {
        display: flex;
        justify-content: center;
        gap: 63px;
        margin-bottom: 112px;
        margin-top: 20px;

        button {
            border: none;
            padding: 9px 12px;
            color: #ffffff;
            border-radius: 5px;
            cursor: pointer;
            font-size: 14px;
        }

        .btn-cancel {
            background-color: #d7d7d7;
        }

        .btn-submit {
            background-color: #00aaaa;
            height: 100%;

            div {
                display: flex;
                align-items: center;
                gap: 10px;
            }
        }
    }

    .custom-table-edit-estimate {
        width: 95%;
        overflow-x: auto;

        table {
            width: 100%;
            table-layout: fixed;

            thead {
                background-color: $mainBg;
                border-radius: 5px;

                th {
                    text-align: center;
                    color: #fff;
                }

                tr:first-child th:first-child {
                    border-top-left-radius: 10px;
                }

                tr:first-child th:last-child {
                    border-top-right-radius: 10px;
                }
            }

            tbody {
                background-color: #fff;

                td {
                    padding: 4px;
                }

                td {
                    text-align: center;
                    vertical-align: top;
                }
                // td{
                //     min-width: 150px;
                // }
                tr:hover {
                    background-color: #ddd;
                }

            }
            .checkbox-width{
                display: flex;
                align-items: center;
                justify-content: center;
                min-width: 100px;
            }
        }

    }

    .title-input-item {
        display: flex;
        justify-content: space-between;

        p {
            font-size: 20px;
            font-weight: 650;
            margin: 10px;
        }

        .icon-plus {
            display: flex;
            align-items: center;
            justify-content: center;
            height: 40px;
            width: 40px;
            border-radius: 0 10px;
            background-color: #FFC30D;
            border: #FFC30D;
        }
    }

    .title-edit-freight {
        p {
            opacity: 0.5;
            font-size: 13px;
            margin-left: 30px;
        }
    }

    .show-detail-cross-border:hover {
        color: #009999;
        cursor: pointer;
        text-decoration: underline;
    }

    .show-detail-cross-border {
        margin-bottom: 0px;
    }

    .contentService {
        max-width: 300px;
        text-overflow: ellipsis;
        overflow: hidden;
        max-height: 50px;
        white-space: 'nowrap';
        p{
            margin: 0px;
        }
    }

    .button-action-remove {
        :hover {
            cursor: pointer;
        }
    }

}