@import "Common.scss";
.Section {
    background-color: #EBEBEB;
    border-radius: 10px 10px 10px 10px;

    .SectionHeader {
        border-radius: 10px 10px 0px 0px;
        background: $mainBg;
        padding: 7px 20px;

        p {
            color: #FFF;
            font-size: 14px;
            font-weight: 500;
        }
    }

    .SectionBody {
        padding: 10px 20px;
        display: flex;
        flex-direction: column;
        gap: 10px;
    }
}