@import "Common.scss";
.UserListPopup {
    width: 90vw;
    border-radius: 10px;
    background: #F2F2F2;
    display: flex;
    flex-direction: column;

    :global {
        .wrap-content-body {
            padding: 20px 30px 20px 30px;
        }
    }

    .icon-close {
        display: flex;
        align-items: center;
        justify-content: center;
        cursor: pointer;
    }

    .wrap-content {
        // max-height: 60vh;
        gap: 20px;
        display: flex;
        flex-direction: column;
    }

    .selected-container {
        display: flex;
        flex-direction: row;
        gap: 20px;
        flex-wrap: nowrap;


        .content-list {
            // height: 70vh;
            gap: 10px;
            display: flex;
            flex-direction: column;
            overflow-y: hidden;

            .content-error {
                display: flex;
                flex-direction: column;
                gap: 10px;
            }
        }

        .list {
            flex: 1;
            width: calc(50% - 10px);
            position: relative;
            display: flex;
            flex-direction: column;

            .custom-pagination-popup {
                display: flex;
                justify-content: center;
                padding-top: 25px;
            }

            .custom-form-search {
                position: sticky;
                top: 0;
                display: flex;
                flex-direction: row;
            }

            .custom-table {
                border-radius: 10px !important;

                .header-left {
                    .content-with {
                        color: white !important;
                    }
                }

                .header {
                    position: sticky;
                    top: 0;
                    display: flex;
                    flex-direction: row;
                    align-items: center;
                    background-color: $mainBg;
                    border-radius: 10px 10px 0px 0px;
                    z-index: 99;
                    overflow: auto;

                    &::-webkit-scrollbar {
                        display: none;
                    }

                    .content-with {
                        overflow: hidden;
                        display: -webkit-box;
                        -webkit-line-clamp: 1;
                        -webkit-box-orient: vertical;
                        color: #000;
                        text-align: left;
                        font-size: 12px;
                        font-style: normal;
                        font-weight: 400;
                        line-height: 24px;
                        padding: 5px;
                    }
                }

                .body {
                    background-color: white;
                    border-radius: 0px 0px 10px 10px;
                    overflow-y: auto;
                    max-height: 39vh;
                    height: 39vh;
                    overflow-x: hidden;

                    .custom-row {
                        display: flex;
                        flex-direction: row;
                        align-items: center;
                        width: fit-content;
                        min-width: 100%;

                        .content-with {
                            overflow: hidden;
                            display: -webkit-box;
                            -webkit-line-clamp: 1;
                            -webkit-box-orient: vertical;
                            color: #000;
                            text-align: left;
                            font-size: 12px;
                            font-style: normal;
                            font-weight: 400;
                            line-height: 24px;
                            padding: 0px 5px;
                        }

                        &:hover {
                            background: #EBEBEB;

                            .content-remove {
                                background: #EBEBEB;
                            }

                            .content-add {
                                background: #EBEBEB;
                            }
                        }
                    }
                }

                :global {
                    .btn-add {
                        box-shadow: none !important;
                    }
                }

                .content-remove {
                    height: 30px !important;
                    display: flex !important;
                    align-items: center;
                    justify-content: center;

                    svg {
                        color: #929292;
                    }

                    background-color: white;
                }

                .content-add {
                    height: 30px !important;
                    display: flex !important;
                    align-items: center;
                    justify-content: center;
                    background-color: white;
                }
            }
        }
    }

    .custom-pagination {
        display: flex;
        align-items: center;
        justify-content: center;
        padding-bottom: 20px;
    }

    .custom-content-table {
        overflow: hidden;
        display: -webkit-box;
        -webkit-line-clamp: 1;
        -webkit-box-orient: vertical;
        max-width: 200px;
    }
}

.custom-loading {
    position: absolute;
    transform: translateX(-50%);
    z-index: 10;
    top: 90px;
    left: 50%;

    :global {
        .lds-spinner {
            div {
                &::after {
                    background-color: $mainBg;
                }
            }
        }
    }
}

.footer-left {
    position: sticky;
    z-index: 11;
    max-height: 15px;
    overflow-y: hidden;
    bottom: 0;
    display: flex;
    flex-direction: row;
    align-items: center;

    .content-with {
        visibility: hidden;
    }
}