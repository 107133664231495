@import "Common.scss";
.box-content {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    flex-direction: row;
    flex-wrap: wrap;
    gap: 10px;
    width: 100%;

    .box-name {
        display: flex;
        align-items: center;
        justify-content: center;
        border: 0.5px solid #929292;
        padding: 4px;
        border-radius: 5px;
        width: max-content;
        cursor: pointer;

        p {
            text-align: left;
            margin-bottom: 0px;
            position: relative;
            display: inline;
            flex-grow: 1;
            flex-shrink: 1;
            overflow: hidden;
            white-space: pre;
            text-overflow: ellipsis;
            line-height: 22px;
        }
    }
}

.Wrap-Content {
    max-height: 70vh;
    overflow-x: hidden;
    overflow-y: auto;
    width: 100%;
    gap: 20px;
    display: flex;
    flex-direction: column;
}

.Box {
    width: 100%;
    border-radius: 10px;
    overflow: hidden;

    .Title_Box {
        width: 100%;
        background: $mainBg;
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: space-between;
        padding: 6px 15px 6px 19px;
        height: 30px;

        p {
            margin-bottom: 0px;
            color: #FFF;
            font-family: Noto Sans JP;
            font-size: 14px;
            font-style: normal;
            font-weight: 500;
            line-height: 14px;
            /* 100% */
            letter-spacing: 1.4px;
        }

        .Icon {
            display: flex;
            flex-direction: row;
            align-items: center;
            cursor: pointer;
        }
    }

    .Content_Box {
        width: 100%;
        background: #EBEBEB;
    }
}

.FormHas {
    background-color: #EBEBEB;
    border-radius: 10px;
    // overflow: hidden;

    .Custom-Layout_Table {
        display: flex;
        flex-direction: column;

        .FormTable_Loading {
            display: flex;
            align-items: center;
            justify-content: center;
            margin: 10px 0px;

            :global {
                .lds-spinner {
                    div {
                        &::after {
                            background-color: $btnDarkBgColor;
                        }
                    }
                }
            }
        }

        .FormTableHead {
            width: 100%;
            background-color: $btnDarkBgColor;
            padding: 2px 10px;
            border-radius: 10px 10px 0px 0px;

            .Head_Title {
                width: 50%;
                color: #FFF;
                font-family: Noto Sans JP;
                font-size: 14px;
                font-style: normal;
                font-weight: 500;
                line-height: 32px;
                /* 228.571% */
                letter-spacing: 1.4px;
            }
        }

        .FormTableBody {
            width: 100%;
            background: #EBEBEB;
            padding: 2px 10px;

            .FormTable-Row {
                display: flex;
                flex-direction: row;
                align-items: center;
                padding: 5px 0px;

                .Body_Title {
                    color: $btnDarkBgColor;
                    font-size: 14px;
                    font-style: normal;
                    font-weight: 500;
                    line-height: normal;
                    letter-spacing: 1.2px;
                    flex: 1;
                }

                .Body_Title-Value {
                    color: #000;
                    font-size: 12px;
                    font-style: normal;
                    font-weight: 400;
                    line-height: normal;
                    letter-spacing: 1.2px;

                    display: flex;
                    flex-direction: row;
                    align-items: center;
                    flex-wrap: wrap;

                    p {
                        margin-bottom: 0px;
                        white-space: pre;
                    }
                }

                .Body_Value_Img {
                    width: 200px;
                    height: 100px;

                    img {
                        width: 100%;
                        height: 100%;
                        object-fit: cover;
                    }
                }
            }
        }
    }
}

.item-select-template {
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 4px;
    border: 0.5px solid #929292;
    color: #000;
    text-align: center;
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: 12px;
    /* 100% */
    letter-spacing: 1.2px;
    margin-right: 5px;
    padding: 3px 5px;

    &:last-child {
        margin-left: 0px;
    }
}

.Action-Button {
    display: flex;
    align-items: center;
    justify-content: center;
}

.viewer-actions {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
    gap: 20px;
    flex-wrap: wrap;

    a {
        color: $btnDarkBgColor !important;
        font-size: 14px;
        text-decoration: none;
        font-weight: 400;

        &:hover {
            color: $btnDarkBgColor !important;
        }
    }
}

.custom-button {
    border: 1px solid $btnDarkBgColor;
    border-radius: 4px;
    padding: 5px 5px;
}

.btn-attachment {
    width: max-content;
    button {
        background-color: #D9D9D9 !important;
        border: 1px solid #929292 !important;
        color: #000 !important;
        justify-content: flex-start;
    }
}