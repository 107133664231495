@import "Common.scss";
.layout {
    border-radius: 10px 10px 0px 0px;
    background: transparent;
    height: 180px;
    position: fixed;
    bottom: 0px;
    right: 0px;
    z-index: 15;
    width: 100%;
    margin-left: 100px;

    .layout-content {
        // background-color: red;
        width: calc(100% - (var(--contentMenuWidth) + 60px));
        height: 100%;
        position: absolute;
        top: 0;
        right: 30px;
        left: calc(var(--contentMenuWidth) + 30px);
        background: #F2F2F2;

        .header {
            border-radius: 10px 10px 0px 0px;
            background: $mainBg;
            height: 30px;
            width: 100%;
            display: flex;
            align-items: center;
            justify-content: space-between;
            padding: 0px 10px;
            flex-direction: row;
            gap: 10px;
        }

        .tab-right {
            display: flex;
            flex-direction: row;
            align-items: center;
            gap: 20px;

            .icon_zoom {
                display: flex;
                align-items: center;
                justify-content: center;
                cursor: pointer;
            }

            .icon_close {
                display: flex;
                align-items: center;
                justify-content: center;
                cursor: pointer;
            }
        }

        .body {
            height: calc(180px - 40px);
            width: 100%;
            background-color: #F2F2F2;
            padding: 20px 30px;
        }
    }
}