.policy-setting {
    display: flex;
    flex-direction: column;
    gap: 20px;
    padding-top: 20px;
    padding-bottom: 20px;
    width: 100%;

    :global {
        .content-layout {
            margin-bottom: 0px;
            margin-top: 0px;
        }
    }

    .update-policy-setting-formSearch {
        width: 100%;
        border-radius: 10px;

        form {
            display: flex;
            flex-direction: column;
            gap: 24px;
        }
    }

    .policy-setting-form-search {
        width: 100%;
        border-radius: 10px;

        form {
            display: flex;
            flex-direction: column;
            gap: 24px;
        }
    }

    .action-search {
        max-width: 100px;
    }

    .action-submit {
        display: flex;
        justify-content: flex-end;
        width: 100%;
    }
}

.policy-content {
    width: 100%;
    border-radius: 10px;
    background: #F2F2F2;
    display: flex;
    flex-direction: column;
    gap: 10px;

    .title-policy {
        color: #000;
        font-size: 16px;
        font-style: normal;
        font-weight: 500;
        line-height: 24px;
        letter-spacing: 1.6px;
        margin-bottom: 0px;
    }

    .content {
        * {
            color: #000;
            font-size: 16px;
            font-style: normal;
            font-weight: 400;
            line-height: 24px;
            /* 150% */
            letter-spacing: 1.6px;
        }
    }

}

.hex-service-content {
    display: flex;
    flex-direction: column;
    gap: 10px;

    .hex-service-item {
        width: 100%;
        border-radius: 10px;
        padding: 20px;
        display: flex;
        flex-direction: column;
        gap: 10px;
        background-color: white;

        p {
            margin-bottom: 0px;
        }

        .label {
            font-size: 14px;
            font-weight: 600;
            color: #000;
        }

        .content {
            font-size: 14px;
            color: #929292;
            font-weight: 400;

        }

        .content-row {
            display: flex;
            flex-direction: row;
            align-items: center;
            justify-content: flex-start;
            gap: 10px;

            .content-image {
                width: 200px;
                height: 50px;

                img {
                    width: 100%;
                    height: 100%;
                    object-fit: contain;
                }
            }
        }
    }
}

.wrap_layout_form {
    width: 100%;
    border-radius: 10px;

    .action-update {
        display: flex;
        align-items: flex-end;
        justify-content: flex-end;
        width: 100%;
        margin-top: 20px;
    }

    :global{
        .FormGroup{
            align-items: flex-start;
        }
    }
}