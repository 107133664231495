@import "Common.scss";
.custom-button {
    min-width: 30px;
    width: max-content;
    height: 30px;
    flex-shrink: 0;
    border-radius: 6px;
    overflow: hidden;
    // padding: 0px 11px;
    display: flex;
    align-items: center;
    justify-content: center;

    &.custom-button-shadow {
        box-shadow: 4px 4px 6px 0px rgba(0, 0, 0, 0.05) !important;
    }

    &.small {
        min-width: 20px;
        height: 20px;
    }

    &.large {
        min-width: 40px;
        height: 40px;
    }

    &.nomarl {
        min-width: 30px;
        height: 30px;
    }

    &.custom-button-icon {
        button {
            padding: 0px;
        }
    }

    button {
        background-color: $btnDarkBgColor;
        outline: none;
        border: none;
        color: #FFF;
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        line-height: 24px;
        height: 100%;
        width: 100%;
        border-radius: 6px;
        display: flex;
        align-items: center;
        justify-content: center;
        gap: 10px;
        padding: 0px 10px;

        .icon-button {
            display: flex;
            align-items: center;
            justify-content: center;

        }

        &:hover {
            opacity: 0.8;

            .icon-button {
                svg {
                    color: #FFF;
                }
            }

            :global {
                .lds-spinner {
                    div {
                        &::after {
                            background-color: #FFFFFF;
                        }
                    }
                }
            }
        }

        .icon-button-loading {
            :global {
                .lds-spinner {
                    div {
                        &::after {
                            background-color: #FFFFFF;
                        }
                    }
                }
            }
        }

        &.outlined {
            background-color: white;
            color: $btnDarkBgColor;
            border: 1px solid $btnDarkBgColor;

            .icon-button-loading {
                :global {
                    .lds-spinner {
                        div {
                            &::after {
                                background-color: $btnDarkBgColor !important;
                            }
                        }
                    }
                }
            }
        }
    }

    &.disabled {
        .outlined {
            background-color: white;
            color: $btnDarkBgColor;
            border: 1px solid #929292;
            cursor: default !important;
            opacity: 0.7;
        }

        &.button_color_gray-yellow {
            button {
                border: 2px solid #c1b89b !important;
                background-color: #fff !important;
                color: #000000;
            }
        }
    }

    &.button_color_default {
        button {
            border-radius: 10px;
            border: 1px solid #929292;
            background: $btnDarkBgColor;
            box-shadow: 4px 4px 6px 0px rgba(0, 0, 0, 0.05);
        }

        .outlined {
            background-color: white;
            border: 1px solid #929292;
            color: $btnDarkBgColor;
            box-shadow: 4px 4px 6px 0px rgba(0, 0, 0, 0.05);
        }
    }

    &.button_color_dark_gray {
        button {
            border-radius: 10px;
            border: 1px solid #929292;
            background: #929292;
            box-shadow: 4px 4px 6px 0px rgba(0, 0, 0, 0.05);
        }

        .outlined {
            background-color: white;
            border: 1px solid #929292;
            color: $btnDarkBgColor;
            box-shadow: 4px 4px 6px 0px rgba(0, 0, 0, 0.05);
        }
    }

    &.button_color_gray {
        button {
            background-color: #EBEBEB;
            border: #EBEBEB;
            color: #263238;

            .icon-button-loading {
                :global {
                    .lds-spinner {
                        div {
                            &::after {
                                background-color: $btnDarkBgColor !important;
                            }
                        }
                    }
                }
            }

            &:hover {
                .icon-button {
                    svg {
                        color: $btnDarkBgColor !important;
                    }
                }

                :global {
                    .lds-spinner {
                        div {
                            &::after {
                                background-color: $btnDarkBgColor !important;
                            }
                        }
                    }
                }

                .icon-button-loading {
                    :global {
                        .lds-spinner {
                            div {
                                &::after {
                                    background-color: $btnDarkBgColor;
                                }
                            }
                        }
                    }
                }
            }
        }

        .outlined {
            background-color: white;
            border: 1px solid #EBEBEB;
            color: #EBEBEB;
        }

        &:hover {
            button {
                // background-color: $btnDarkBgColor;
                // border: $btnDarkBgColor;
                // color: #EBEBEB;
                // transition: 0.2s;
                opacity: 0.8;

                .icon-button-loading {
                    :global {
                        .lds-spinner {
                            div {
                                &::after {
                                    background-color: #EBEBEB;
                                }
                            }
                        }
                    }
                }

                svg {
                    color: white;
                }
            }
        }
    }

    &.button_color_transparent {
        button {
            background-color: transparent;
            border: transparent;
            color: #263238;
        }

        .outlined {
            background-color: transparent;
            border: 1px solid transparent;
            color: #263238;
        }
    }

    &.button_color_red {
        button {
            background-color: #FE461E;
            border: #FE461E;
            color: #FFFFFF;
        }

        .outlined {
            background-color: white;
            border: 1px solid #FE461E;
            color: #FE461E;
        }
    }

    &.button_color_yellow {
        button {
            background-color: #FFC30D;
            border: #FFC30D;
            color: #000000;
        }

        .outlined {
            background-color: white;
            border: 1px solid #FFC30D;
            color: #FFC30D;
        }
    }

    &.button_color_gray-yellow {
        button {
            border: 2px solid #ffc30d;
            background-color: #fff;
            color: #000000;
        }

        &:hover {
            button {
                border: 2px solid #d7a200;
                background-color: #ffc30d;
            }
        }
    }

    &.button_color_white {
        button {
            background-color: #FFFFFF;
            border: #FFFFFF;
            color: #000000;

            .icon-button-loading {
                :global {
                    .lds-spinner {
                        div {
                            &::after {
                                background-color: $btnDarkBgColor !important;
                            }
                        }
                    }
                }
            }
        }

        .outlined {
            background-color: white;
            border: 1px solid #FFFFFF;
            color: #000000;
        }
    }

    &.button_color_hippie_blue {
        button {
            background-color: $btnBgColor;
            border: $btnBgColor;
            color: $btnBgColor;
        }

        .outlined {
            background-color: $btnBgColor;
            border: 1px solid $btnBgColor;
            color: $btnBgColor;
        }
    }

    &.button_color_lightblue {
        button {
            background-color: lightblue;
            border: lightblue;
            color: #000;
        }

        .outlined {
            background-color: lightblue;
            border: 1px solid lightblue;
            color: #000;
        }
    }

    // background-transparent
    &.background-transparent {
        button {
            background-color: transparent !important;
        }
    }

    &.disabled {
        button {
            opacity: 0.7;
            border: none;
            cursor: default !important;
        }
    }
}

.btn-link {
    color: blue;
    text-decoration: underline;
    overflow: hidden;
    display: -webkit-box;
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical;
    word-break: break-word;

    &:hover {
        text-decoration: underline;
        cursor: pointer;
        color: blue;
    }
}

.custom-button-filter {
    border-radius: 10px;
    background: #FFF;
    box-shadow: 4px 4px 6px 0px rgba(0, 0, 0, 0.05) !important;

    button {
        border-radius: 10px;
        color: #000 !important;
        font-size: 14px !important;
        font-style: normal !important;
        font-weight: 400 !important;
        line-height: 24px !important;
        padding-left: 19px !important;
        padding-right: 13px !important;
        padding-top: 8px !important;
        padding-bottom: 8px !important;

        span {
            overflow: hidden;
            -webkit-line-clamp: 1;
            -webkit-box-orient: vertical;
            text-overflow: ellipsis;
            overflow-wrap: break-word;
            display: -webkit-box;
        }
    }

    .common-display {
        display: flex;
        align-items: center;
        flex-direction: row;
        justify-content: space-between;
        width: 95%;
        gap: 5px;
        font-size: 14px;
        flex-wrap: nowrap;
    }
}

.custom-button-add {
    border-radius: 10px;
    background: #FFF;
    box-shadow: 4px 4px 6px 0px rgba(0, 0, 0, 0.05) !important;
    width: 40px;
    min-width: 40px;
    max-width: 40px;

    button {
        border-radius: 10px;
        color: #000 !important;
        font-size: 14px !important;
        font-style: normal !important;
        font-weight: 400 !important;
        line-height: 24px !important;
        padding-left: 8px !important;
        padding-right: 8px !important;
        padding-top: 8px !important;
        padding-bottom: 8px !important;
    }
}