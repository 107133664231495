@import "Common.scss";
.custom-header {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    width: 100%;
    align-items: center;
    background-color: $mainBg;
    padding: 7px 19px 6px 19px;
    height: 30px;

    p {
        margin-bottom: 0px;
        color: #FFF;
        font-size: 14px;
        font-style: normal;
        font-weight: 500;
        line-height: 32px;
        letter-spacing: 1.4px;
    }

    .icon {
        width: 20px;
        height: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
        cursor: pointer;
    }
}