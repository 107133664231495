@import "Common.scss";
.from-item {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    width: 50%;
    gap: 20px;

    p {
        margin-bottom: 0px;
        color: #000;
        font-size: 12px;
        font-style: normal;
        font-weight: 500;
        line-height: 24px;
        /* 150% */
        letter-spacing: 1.6px;
        white-space: nowrap;
    }
}

.custom-table-attributes {
    // min-width: 500px;
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: 10px;

    .custom-header {
        display: flex;
        align-items: center;
        justify-content: flex-start;
        gap: 10px;
        border-radius: 10px 10px 0px 0px;
        background-color: $mainBg;
        padding: 0px 10px;

        .button-arrow {
            display: flex;
            align-items: center;
            justify-content: center;
            cursor: pointer;

            svg {
                color: #FFF;
            }
        }

        p {
            margin-bottom: 0px;
            color: #FFF;
            font-size: 12px;
            font-style: normal;
            font-weight: 400;
            line-height: 24px;
            white-space: nowrap;
        }
    }
}