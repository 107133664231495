.HolonInstanceContainer {

    display: flex;
    flex-direction: column;
    gap: 20px;

    .wrap-form-search {
        display: flex;
        flex-direction: row;
        align-items: flex-start;
        justify-content: space-between;

        :global {
            .FormItem {
                flex-direction: row;
                flex-wrap: wrap;
            }
        }
    }

    .holonInstanceContainer-title {
        margin-bottom: 0px;
        color: #000;
        font-size: 16px;
        font-style: normal;
        font-weight: 500;
        line-height: 24px;
        /* 150% */
        letter-spacing: 1.6px;
        white-space: nowrap;
    }

    form {
        display: flex;
        flex-direction: column;
        gap: 20px;

        .from-item {
            display: flex;
            align-items: center;
            justify-content: flex-start;
            width: 50%;
            gap: 20px;

            p {
                margin-bottom: 0px;
                color: #000;
                font-size: 12px;
                font-style: normal;
                font-weight: 500;
                line-height: 24px;
                /* 150% */
                letter-spacing: 1.6px;
                white-space: nowrap;
            }
        }
    }

}

.custom-button {
    background-color: #FFC30D !important;
    border: #FFC30D !important;
    color: white !important;
    height: 30px;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 24px;
    border-radius: 6px;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 10px;
    padding: 0px 10px;
    text-decoration: none !important;

    &:hover{
        opacity: 0.7;
    }

    &.custom-button-disable{
        opacity: .7;
        pointer-events: none !important;
        cursor: default !important;
    }
}

.action-button{
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
}