.menu {
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    z-index: 20;
}

.layout-content {
    // padding-left: var(--contentMenuWidth);
}

.button-lang {
    width: calc(100%);
    padding: 20px 15px;
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    padding-bottom: 0px;

    select {
        border: 1px solid #929292;
        width: 100px !important;
        border-radius: 4px;
        color: #0E0E28;
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
        text-indent: 5px;
        height: 30px;

        &:active{
            border: 1px solid #929292;
        }

        &:focus{
            border: 1px solid #929292;
        }
    }
}
.expand-menu{
    transform: translateX(-100%);
    transition: all 0.3s;
    &.open{
        transform: translateX(0);
    }
}
.icon{
    transform: translateX(calc(100% - 2px));
    position: absolute;
    top: 0;
    right: 0;
    z-index: 1;
    width: 30px;
    height: 30px;
    display: flex;
    align-items: center;
    justify-content: center;
    border:1px solid #303030;
    border-radius: 0px 5px 5px 0;
    background: #303030;
    cursor: pointer;
    margin-top:10px;
}