.SystemSetting {

    display: flex;
    flex-direction: column;
    gap: 20px;
    padding-bottom: 35px;
    margin-top: 20px;

    .HeaderSection {
        font-weight: 500;
    }
    .buttonSave {
        display: flex;
        justify-content: flex-end;
        width: 100%;
    }
    .buttonEdit{
        cursor: pointer;
    }
}