@import "Common.scss";
.CrossBorderCourierService {
    box-shadow: 0px 10px 30px 0px rgba(0, 0, 0, 0.30);
    padding: 10px 30px 30px 30px;
    display: flex;
    flex-direction: column;
    gap: 10px;
    background: rgba(112, 176, 187, 0.90);

    p {
        margin: 0;
    }
    .title-service-name {
        font-weight: 650;
        font-size: 16px;
        margin-bottom: 10px;
    }
    .CrossBorderCourierServiceContent {
        max-height: 70vh;
        padding-right: 15px;
        overflow-y: auto;

        .contentPopup {
            padding: 20px;
            width: 100%;
            background-color: #EBEBEB;
            border-radius: 10px 10px 10px 10px;

            .title-editor{
                font-weight: 650;
                font-size: 16px;
                margin-bottom: 10px;
            }

            .constant-table {
                .header-constant-table {
                    display: flex;
                    align-items: center;
                    justify-content: space-between;
                    margin-bottom: 10px;
                    .title-header-constant {
                        font-weight: 650;
                        font-size: 16px;
                    }
                }

                .icon-plus {
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    height: 30px;
                    width: 30px;
                    border-radius: 5px;
                    background-color: #FFC30D;
                    border: #FFC30D;
                    cursor: pointer;
                }
            }

            .btn-form {
                display: flex;
                justify-content: center;
                gap: 63px;
                padding: 30px;

                button {
                    border: none;
                    padding: 9px 12px;
                    color: #ffffff;
                    border-radius: 5px;
                    cursor: pointer;
                    font-size: 14px;
                }

                .btn-cancel {
                    background-color: #d7d7d7;
                }

                .btn-submit {
                    background-color: #00aaaa;

                    div {
                        display: flex;
                        align-items: center;
                        gap: 10px;
                    }
                }
            }

            .service-name{
                display: flex;
                align-items: center;
                p {
                    width: fit-content;
                    margin: 0px;
                    min-width: 150px;
                }
                .title-service-name{
                    font-weight: 650;
                    font-size: 16px;
                }
                .service-name-select{
                    min-width: 450px;
                }
                .radio-service{
                    p{
                        min-width: 0px;
                        font-weight: 300px !important;
                    }
                }
            }
        }
    }

    .Header {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;

        .HeaderLeft {
            display: flex;
            flex-direction: row;
            gap: 10px;

            .PopupTitle {
                p {
                    color: #263238;
                    font-size: 17px !important;
                    font-style: normal;
                    font-weight: 700;
                    line-height: 32px;
                    letter-spacing: 1.7px;
                }
            }
        }

        .HeaderRight {
            display: flex;
            flex-direction: row;
            gap: 10px;
        }
    }


    table {
        width: 100%;
        border-collapse: separate;
        border-spacing: 0;

        thead {
            background-color: $mainBg;
            border-radius: 5px;

            th {
                text-align: center;
            }

            tr:first-child th:first-child {
                border-top-left-radius: 10px;
            }

            tr:first-child th:last-child {
                border-top-right-radius: 10px;
            }

            .formula{
                min-width: 250px
            }

        }

        tbody {
            background-color: #fff;

            td {
                padding: 4px;
            }

            .formula{
                min-width: 250px
            }

            td {
                text-align: center;
                vertical-align: top;
            }

            tr:hover {
                background-color: #ddd;
            }

        }
    }
}

.line-estimate {
    height: 1px;
    background-color: #d7d7d7;
    margin: 30px 0px 20px 0px;
}