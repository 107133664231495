.container{
    position: fixed;
    top:0;
    bottom:0;
    left:0;
    right:0;
    z-index:99;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #333;

    .button-close{
        position: absolute;
        top: 10px;
        right: 10px;
        cursor: pointer !important;
        pointer-events: fill;
        z-index: 999;
    }
}