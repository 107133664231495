@import "Common.scss";
.ContentMessage {
    .Message {
        padding: 10px 20px 20px 30px;
        width: 100%;

        .Message_List {
            width: 100%;
            gap: 20px;
            display: flex;
            // flex-direction: column;
            // align-items: flex-end;
            // justify-content: flex-end;

            .Message_Item {
                width: 100%;
                position: relative;
                display: flex;
                flex-direction: column;
                position: relative;

                &.Message_Item_Me {
                    justify-content: flex-end;
                    align-items: end;
                    padding-right: 10px;

                    .Triangle_Me {
                        width: 10px;
                        height: 7px;
                        border-left: solid 12px #67BFCE;
                        border-bottom: solid 6px transparent;
                        border-top: solid 6px transparent;
                        position: absolute;
                        right: -10px;
                        top: 10px;
                    }

                    .Message_Item_Content {
                        justify-content: flex-end;

                        .User_Message {
                            background: #67BFCE;
                        }
                    }
                }

                .Message_Item_Content {
                    display: flex;
                    flex-direction: row;
                    align-items: stretch;
                    justify-content: flex-start;
                    gap: 10px;
                    width: 70%;
                    position: relative;

                    .Content {
                        display: flex;
                        align-items: flex-end;
                        justify-content: flex-end;
                        background-color: transparent;
                        flex-direction: column;
                    }

                    .Triangle {
                        width: 10px;
                        height: 7px;
                        border-right: solid 12px #EBEBEB;
                        border-bottom: solid 6px transparent;
                        border-top: solid 6px transparent;
                        position: absolute;
                        left: 34px;
                        top: 10px;
                    }

                    .User_Img {
                        height: 30px;
                        width: 30px;
                        min-width: 30px;
                        max-width: 30px;
                        border-radius: 50%;
                        overflow: hidden;
                        margin-right: 5px;

                        .Avatar {
                            border-radius: 50%;
                            background-color: white;
                            width: 100%;
                            height: 100%;
                            display: flex;
                            align-items: center;

                            img {
                                width: 100%;
                                height: 100%;
                                object-fit: cover;
                            }
                        }

                        .Avatar_None {
                            border: 1px solid #929292;
                            width: 100%;
                            height: 100%;
                            display: flex;
                            align-items: center;
                            border-radius: 50%;
                        }
                    }

                    .User_Message {
                        display: flex;
                        flex-direction: column;
                        position: relative;
                        overflow: hidden;
                        background: #EBEBEB;
                        border-radius: 10px;

                        .File_Link {
                            width: 100%;
                            min-height: 70px;
                            display: flex;
                            flex-direction: row;
                            align-items: center;
                            justify-content: flex-start;
                            gap: 15px;
                            padding: 11px 16px;
                            cursor: pointer;

                            .Icon_File {
                                width: 40px;
                                min-width: 40px;
                                height: 40px;
                                display: flex;
                                align-items: center;
                                justify-content: center;
                                border-radius: 50%;
                                border: 1px solid #929292;
                                cursor: pointer;
                            }

                            .File_Info {
                                display: flex;
                                flex-direction: column;
                                gap: 6px;

                                .Custom_Name_File {
                                    color: #000;
                                    font-size: 14px;
                                    font-style: normal;
                                    font-weight: 400;
                                    line-height: 24px;

                                    position: relative;
                                    display: inline;
                                    flex-grow: 1;
                                    flex-shrink: 1;
                                    overflow: hidden;
                                    white-space: pre;
                                    text-overflow: ellipsis;
                                }

                                .File_Size {
                                    color: #929292;
                                    font-size: 12px;
                                    font-style: normal;
                                    font-weight: 400;
                                    line-height: 12px;
                                    /* 100% */

                                }

                                .File_Size_Me {
                                    color: $btnDarkBgColor;
                                }
                            }
                        }

                        .File_Link_Reply {
                            padding: 0px;
                            height: 50px;
                            min-height: 50px;

                            .Custom_Name_File {
                                width: 300px;
                            }

                            .Custom_Name_File_Me {
                                width: 250px;
                            }

                            .File_Size {
                                color: #929292 !important;
                            }

                            .File_Size_Me {
                                color: $btnDarkBgColor !important;
                            }
                        }

                        .Image_Link {
                            position: relative;
                            overflow: visible;
                            background-color: transparent;
                            flex-grow: 0;
                            flex-shrink: 0;
                            background-position: center center;
                            background-repeat: no-repeat;
                            background-size: cover;
                            border-style: none;
                            display: flex;
                            cursor: pointer;
                        }

                        .User_Message_Reply {
                            border-bottom: 0.5px solid #929292;
                            padding: 11px 14px 11px 14px;
                            display: flex;
                            flex-direction: row;
                            align-items: flex-start;
                            gap: 10px;

                            &.User_Message_Reply_Me {
                                border-bottom: 1px solid $btnBgColor;
                            }

                            .User_Message_Reply_Avatar {
                                display: flex;
                                flex-direction: column;
                                align-items: flex-start;
                                padding-top: 3px;

                                .Avatar {
                                    width: 20px;
                                    height: 20px;
                                    min-width: 20px;
                                    display: flex;
                                    align-items: center;
                                    justify-content: center;
                                    border-radius: 50%;
                                    overflow: hidden;

                                    img {
                                        width: 100%;
                                        height: 100%;
                                        object-fit: cover;
                                    }
                                }

                                .Avatar_None {
                                    width: 24px;
                                    height: 24px;
                                    min-width: 24px;
                                    display: flex;
                                    align-items: center;
                                    justify-content: center;
                                    border-radius: 50%;
                                    overflow: hidden;

                                    img {
                                        width: 100%;
                                        height: 100%;
                                        object-fit: cover;
                                    }
                                }
                            }

                            .User_Message_Reply_Info {
                                display: flex;
                                flex-direction: column;
                                gap: 3px;
                                justify-content: flex-start;
                                // align-items: center;

                                .User_Message_Reply_Name {
                                    color: #000;
                                    font-size: 14px;
                                    font-style: normal;
                                    font-weight: 400;
                                    line-height: 24px;
                                    /* 171.429% */

                                    margin-bottom: 0px;
                                    text-transform: capitalize;
                                }

                                .User_Message_Reply_Message {
                                    color: #263238;
                                    font-size: 12px;
                                    font-style: normal;
                                    font-weight: 400;
                                    line-height: 24px;
                                    /* 200% */

                                    margin-bottom: 0px;

                                    white-space: pre-line;

                                    &.User_Message_Reply_Message_Me {
                                        color: $btnDarkBgColor !important;
                                    }
                                }
                            }
                        }

                        .content-message {
                            position: relative;
                            padding: 11px 14px 11px 14px;

                            p {
                                color: #000;
                                font-size: 14px !important;
                                font-style: normal;
                                font-weight: 400;
                                line-height: 24px;
                                /* 171.429% */

                                margin-bottom: 0px;
                                white-space: pre-line;
                                max-width: 40vw;
                                word-wrap: break-word;
                            }
                        }
                    }
                }
            }
        }
    }

    .Form_Input_Message {
        // height: 200px;
        position: fixed;
        bottom: 0px;
        right: 0px;
        left: calc(var(--contentMenuWidth) + var(--contentChannelWidth));
        background-color: #FFFFFF;

        // padding: 24px 30px;
        display: flex;
        align-items: flex-start;
        border: 1px solid #EBEBEB;
        border-left: none;
        flex-direction: column;

        .Form_Heading {
            padding: 20px 30px;
            display: flex;
            flex-direction: row;
            align-items: flex-start;
            justify-content: space-between;
            width: 100%;
            gap: 15px;
            border-bottom: 1px solid #EBEBEB;

            .Icon_Close {
                cursor: pointer;
                display: flex;
                align-items: flex-start;
                justify-content: flex-start;
            }

            .Info_Message_Reply {
                display: flex;
                flex-direction: row;
                align-items: flex-start;
                justify-content: space-between;
                gap: 14px;

                .Avatar {
                    width: 20px;
                    min-width: 20px;
                    height: 20px;
                    border-radius: 50%;
                    overflow: hidden;
                    display: flex;
                    align-items: center;
                    justify-content: center;

                    img {
                        width: 100%;
                        height: 100%;
                        object-fit: cover;
                    }
                }

                .Content {
                    display: flex;
                    flex-direction: column;
                    gap: 15px;

                    .Title {
                        margin-bottom: 0px;
                        color: #000;
                        font-size: 12px;
                        font-style: normal;
                        font-weight: 400;
                        line-height: 12px;
                        /* 100% */

                        margin-bottom: 0px;
                    }

                    .Msg {
                        color: #929292;
                        margin-bottom: 0px;
                        font-size: 12px;
                        font-style: normal;
                        font-weight: 400;
                        line-height: 15px;
                        /* 100% */

                        margin-bottom: 0px;
                        white-space: pre-line;

                        overflow: hidden;
                        display: -webkit-box;
                        -webkit-line-clamp: 4;
                        -webkit-box-orient: vertical;
                        text-overflow: ellipsis;
                    }
                }
            }
        }

        .Form_Input {
            width: 100%;
            position: relative;
            height: calc(200px - 100px);

            .Icon_Tag {
                position: absolute;
                top: 13px;
                right: 30px;
                display: flex;
                align-items: center;
                justify-content: center;
                cursor: pointer;
            }

            .Custom_Input {
                padding: 13px 30px;

            }
        }

        .Form_Row {
            display: flex;
            flex-direction: row;
            align-items: flex-start;
            justify-content: flex-start;
            width: 100%;

            textarea {
                width: 100%;
                height: auto;
                resize: none;
                border: none;
                outline: none;
                color: #000;
                font-size: 14px;
                font-style: normal;
                font-weight: 400;
                line-height: 24px;
                /* 171.429% */


                &::placeholder {
                    color: #929292;
                }
            }
        }
    }
}

.File_Reply {
    .Image_Link {
        min-width: 300px;
        height: 200px;

        img {
            width: 100%;
            height: 100%;
            object-fit: cover;
        }
    }

    .File_Link {
        width: 100%;
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: flex-start;
        gap: 10px;

        .Icon_File {
            width: 40px;
            min-width: 40px;
            height: 40px;
            display: flex;
            align-items: center;
            justify-content: center;
            border-radius: 50%;
            border: 1px solid #929292;
        }

        .File_Info {
            display: flex;
            flex-direction: column;
            gap: 6px;

            .File_Size {
                color: #929292;
                font-size: 10px;
                font-style: normal;
                font-weight: 400;
                line-height: 12px;
                /* 100% */

            }

            .Custom_Name_File {
                color: #5f5e5e;
                font-size: 12px;
                font-style: normal;
                font-weight: 400;
                line-height: 24px;

                position: relative;
                display: inline;
                flex-grow: 1;
                flex-shrink: 1;
                overflow: hidden;
                white-space: pre;
                text-overflow: ellipsis;
            }
        }
    }
}

.Message_Option {
    width: 100px;
    min-height: 30px;
    border-radius: 5px;
    background: #FFF;
    box-shadow: 0px 0px 15px 0px rgba(0, 0, 0, 0.20);
    display: flex;
    flex-direction: column;
    padding: 5px;

    .Option_Reply {
        padding: 10px;
        color: #000;
        font-size: 12px;
        font-style: normal;
        font-weight: 400;
        line-height: 12px;
        /* 100% */
        cursor: pointer;

        &:hover {
            background: #EBEBEB;
        }
    }
}

.Current_Time {
    margin-bottom: 0px;
    display: flex;
    align-items: flex-end;
    justify-content: flex-end;

    p {
        margin-bottom: 0px;
        color: #929292;
        font-size: 9px;
        font-style: normal;
        font-weight: 400;
        line-height: 12px;
    }
}

.Message_Date {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    width: 100%;

    div {
        padding: 9px 29px;
        border-radius: 100px;
        background: #EBEBEB;
        color: #929292;
        font-size: 12px;
        font-style: normal;
        font-weight: 400;
        line-height: 12px;

    }
}

.Image_Link_Reply {
    position: relative;
    display: flex;
    flex-direction: column;
    flex-grow: 0;
    flex-shrink: 0;
    overflow: hidden;
    align-items: stretch;
    justify-content: center;
    border-radius: 10px;
    cursor: pointer;
}

.User_Message_Forward {
    padding: 11px 14px;
    background-color: #EBEBEB;

    &.User_Message_Forward_Me {
        background-color: #67bfce;

        .Info_Message_Forward {
            p {
                color: $btnDarkBgColor !important;
            }
        }

        .Message_Forward_Text {
            color: $btnDarkBgColor !important;
        }
    }

    .Forward_Icon {
        position: relative;
        display: flex;
        flex-direction: row;
        flex-grow: 0;
        flex-shrink: 0;
        overflow: hidden;
        align-items: stretch;
    }

    .User_Message_Forward_Info {
        display: flex;
        flex-direction: column;
        gap: 10px;
        padding-left: 20px;

        .Message_Forward_Text {
            color: #263238;
            font-size: 12px;
            line-height: 24px;
            white-space: pre-line;
        }

        .Info_Message_Forward {
            display: flex;
            align-items: flex-start;
            flex-direction: row;
            justify-content: flex-start;
            gap: 5px;

            p {
                margin-bottom: 0px;
                color: #263238;
                font-size: 12px;
                font-style: normal;
                font-weight: 400;
                line-height: 24px;
                position: relative;
                display: inline;
                flex-shrink: 1;
                overflow: hidden;
                white-space: pre;
                text-overflow: ellipsis;
                white-space: pre-wrap;
                overflow-wrap: break-word;
            }
        }
    }
}

.Resize_Image {
    position: relative;
    overflow: visible;
    background-color: transparent;
    flex-grow: 0;
    flex-shrink: 0;
    background-position: center center;
    background-repeat: no-repeat;
    background-size: cover;
    border-style: none;
    display: flex;
    cursor: pointer;
    border-radius: 10px;
}

.content-attachment {
    border-radius: 10px;
    overflow: hidden;
    margin-bottom: 1px;
    background-color: #EBEBEB;

    &.content-attachment-me {
        background-color: #67BFCE;
    }

    .File_Link {
        width: 100%;
        min-height: 70px;
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: flex-start;
        gap: 15px;
        padding: 11px 16px;
        cursor: pointer;

        .Icon_File {
            width: 40px;
            min-width: 40px;
            height: 40px;
            display: flex;
            align-items: center;
            justify-content: center;
            border-radius: 50%;
            border: 1px solid #929292;
            cursor: pointer;
        }

        .File_Info {
            display: flex;
            flex-direction: column;
            gap: 6px;

            .Custom_Name_File {
                color: #000;
                font-size: 14px;
                font-style: normal;
                font-weight: 400;
                line-height: 24px;

                position: relative;
                display: inline;
                flex-grow: 1;
                flex-shrink: 1;
                overflow: hidden;
                white-space: pre;
                text-overflow: ellipsis;
            }

            .File_Size {
                color: #929292;
                font-size: 12px;
                font-style: normal;
                font-weight: 400;
                line-height: 12px;
                /* 100% */

            }

            .File_Size_Me {
                color: $btnDarkBgColor;
            }
        }
    }

    .File_Link_Reply {
        padding: 0px;
        height: 50px;
        min-height: 50px;

        .Custom_Name_File {
            width: 300px;
        }

        .Custom_Name_File_Me {
            width: 250px;
        }

        .File_Size {
            color: #929292 !important;
        }

        .File_Size_Me {
            color: $btnDarkBgColor !important;
        }
    }
}

.ContentLoading {
    display: flex;
    flex-direction: row;
    justify-content: center;
    padding-top: 30px;
}

.ContentLoading-InfiniteScroll {
    display: flex;
    flex-direction: row;
    justify-content: center;
    position: relative;
}