@import "Common.scss";
.RowItem {
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 10px;
    min-height: 30px;
    align-items: flex-start;

    .Label {
        width: 250px;
        min-width: 250px;
        color: $btnDarkBgColor;
        font-weight: 500;
    }

    .Value {
        overflow-wrap: break-word;
        width: 300px;
        font-weight: 400;
        p{
            white-space: break-spaces;
        }
    }
}
