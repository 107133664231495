.CustomCheckBox {
    cursor: pointer;
    height: 29px;
    width: 100%;
    position: relative;
    display: flex;
    align-items: center;
    gap: 7px;

    button {
        background-color: transparent;
        outline: none;
        border: none;
        cursor: pointer;
        display: flex;
        align-items: center;
        justify-content: center;
        padding: 0px;
    }

    &.disabled {
        cursor: default;

        button {
            cursor: default;
        }
    }

    p {
        margin-bottom: 0px;
    }
}

.CustomCheckBox_Error {
    margin-top: 7px;
}

.list-checkbox {
    flex-wrap: wrap;
    display: grid;
    grid-template-columns: repeat(2, 147px);
    gap: 10px;

    span {
        font-size: 12px;
    }

    .CustomCheckBox_Text {
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: flex-start;

        p {
            overflow: hidden;
            display: -webkit-box;
            -webkit-line-clamp: 1;
            -webkit-box-orient: vertical;
            text-overflow: ellipsis;
            overflow-wrap: break-word;
            margin-bottom: 0px;
        }
    }
}