.product-container {
    display: flex;
    flex-direction: column;
    gap: 21px;

    .custom-search {
        :global {
            .custom-select {
                select {
                    background-color: white;
                    border: none;
                    box-shadow: 4px 4px 6px 0px rgba(0, 0, 0, 0.05);
                    border-radius: 6px;
                }
            }

            .custom-button {
                box-shadow: 4px 4px 6px 0px rgba(0, 0, 0, 0.05);
            }

            .custom-input {
                input {
                    box-shadow: 4px 4px 6px 0px rgba(0, 0, 0, 0.05);
                }
            }

            .custom-form-search {
                display: flex;
                flex-direction: row;
                justify-content: space-between;
            }
        }
    }

    .custom-body {
        display: flex;
        flex-direction: column;
        gap: 40px;

        .total-quantity {
            display: flex;
            flex-direction: row;
            align-items: center;
            justify-content: flex-end;
            gap: 100px;
            margin-bottom: 10px;
            color: #000;
            font-size: 12px;
            font-style: normal;
            font-weight: 400;
            line-height: normal;
            letter-spacing: 0.24px;
        }
    }

    .custom-pagination {
        display: flex;
        align-items: center;
        justify-content: center;
    }
}

.custom-row-table {
    display: flex;
    align-items: center;
    justify-content: center;
}

.custom-form-search {
    flex-direction: row;
    flex-wrap: nowrap;
    justify-content: space-between;

    :global {
        .FormGroup {
            flex-wrap: wrap !important;
        }

        .FormItem {
            flex: unset !important;
        }
    }
}

.text-line {
    overflow: hidden;
    display: -webkit-box;
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical;
    margin-bottom: 0px;
    text-overflow: ellipsis;
    overflow-wrap: break-word;
}