.color-picker{
    display: flex;
    align-items: center;
    justify-content: flex-start;
    input{
        border: none;
        outline: none;
        overflow: hidden;
        padding: 0px;
        border-radius: 0px;
        cursor: pointer;
    }
}