@import "Common.scss";
.Custom_Input {
    position: relative;
    display: flex;
    flex-direction: row;
    gap: 10px;

    .Btn_Add {
        border-radius: 5px;
        height: 30px;
        width: 30px;
        background-color: $btnDarkBgColor;
        display: flex;
        align-items: center;
        justify-content: center;
        cursor: pointer;
    }

    .Content_File {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        justify-content: flex-start;
        max-width: 90%;
        gap: 10px;

        :global {
            button {
                background-color: #D9D9D9;
                border: 1px solid #929292;
                cursor: default;

                &:hover {
                    background-color: #D9D9D9;
                    svg {
                        color: #000000;
                    }
                }
            }

            .BtnFile{
                max-width: 100%;
            }
        }

        .ContentFile{
            white-space: pre-wrap;
            overflow: hidden;
            visibility: visible;
            overflow: hidden;
            text-overflow: ellipsis;
            display: -webkit-box;
            -webkit-line-clamp: 1;
            -webkit-box-orient: vertical;
            padding-right: 10px;
            text-align: left;
            max-width: 100%;
            word-break: break-all;
            text-align: left;
        }

        .Action_Delete {
            display: flex;
            align-items: center;
            justify-content: center;
            cursor: pointer;

            &:hover {
                svg {
                    color: #000000;
                }
            }
        }
    }

    .Placeholder_Value {
        margin-bottom: 0px;
        font-size: 14px;
        color: #000000;
    }

    .Custom_Input_Name {
        border-radius: 5px;
        background: var(--f-0-f-0-f-0, #F0F0F0);

        /* drop shadow input */
        box-shadow: 0px 1px 4px 0px rgba(0, 0, 0, 0.25);
        display: flex;
        min-height: 45px;
        padding: 10px 15px;
        justify-content: center;
        align-items: center;
        gap: 10px;
        align-self: stretch;
        color: #000000;
        text-align: left;
        justify-content: flex-start;
    }

    .Custom_Input_Import_File {
        border-radius: 5px;
        min-height: 40px;
        width: 100%;
        font-size: 14px;
        color: #000000;
        font-weight: 400;
        z-index: 1;
        background-color: #ffffff;
        padding: 10px;
        outline: none;
        text-indent: 8px;
        line-height: 19.1px;
        box-shadow: 0px 1px 6px 0px #00000040;
        border-radius: 5px;
        border: 1px dashed #848484;
        border-style: border !important;
        background: #FFF;

        /* drop shadow input */
        box-shadow: 0px 1px 4px 0px rgba(0, 0, 0, 0.25);
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;
        cursor: pointer;

        position: relative;

        svg {
            color: #5CBBFF;
        }

        .content {
            margin-top: 16px;
            display: flex;
            align-items: center;
            justify-content: center;
            flex-direction: column;
        }

        p {
            margin-bottom: 0px;
            color: var(--848484, #848484);
            text-align: center;

            /* Nunito Sans */
            font-family: Nunito Sans;
            font-size: 16px;
            font-style: normal;
            font-weight: 400;
            line-height: normal;
        }

        .Loading_Upload {
            :global {
                .lds-spinner {
                    div {
                        &::after {
                            background-color: $btnDarkBgColor;
                        }
                    }
                }
            }
        }

    }

    .File_Selection {
        position: absolute;
        top: 0px;
        right: 0px;
        transform: translate(-15%, 45%);
        border-radius: 3px;
        border: 1px solid var(--5-cbbff, #848484);
        z-index: 2;
        cursor: pointer;
        padding: 0px 10px;

        p {
            color: var(--5-cbbff, #848484);
            text-align: center;
            font-family: Nunito Sans;
            font-size: 16px;
            font-style: normal;
            font-weight: 600;
            line-height: normal;
            margin-bottom: 0px;
        }
    }
}

.Arrow {
    &:before {
        border: 1px solid #FF0000;
        color: #ffffff;
    }
}

.Tooltip {
    font-size: 14px !important;
    background-color: #ffffff !important;
    border-radius: 3px;
    border: 1px solid #FF0000;
    margin-top: 10px !important;

    &.p {
        margin-top: 0px !important;
    }
}

.CustomBorder_Error {
    border: 1px dashed #FF0000 !important;
}

.CustomBorder_Success {
    border: 1px dashed #848484 !important;
}

.Custom_Input_Error {
    .Custom_Input_Import_File {
        border-radius: 5px;
        border: 1px dashed #FC2424 !important;
        background: #FFF;

        /* drop shadow input */
        box-shadow: 0px 1px 4px 0px rgba(0, 0, 0, 0.25);

        svg {
            color: #FC2424;
        }
    }
}


.message_error {
    display: flex;
    max-width: 815px;
    width: 100%;
    height: 45px;
    flex-direction: row;
    align-items: center;
    gap: 10px;
    flex-shrink: 0;
    border-radius: 8px;
    border: 1px solid #FC2424;
    justify-content: flex-end;
    position: relative;
    background-color: #FC2424;
    margin-top: 20px;

    .message_error_border {
        display: flex;
        max-width: 815px;
        width: calc(100% - 10px);
        height: 45px;
        flex-direction: row;
        align-items: center;
        gap: 10px;
        flex-shrink: 0;
        border-radius: 8px;
        border: 1px solid #FC2424;
        background: #FFF8F8;
        justify-content: flex-start;
        padding: 0px 10px;
        position: relative;
    }

    p {
        margin-bottom: 0px;
        color: var(--ff-2237, #FF2237);

        /* Nunito sans */
        font-family: Nunito Sans;
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
    }

    .border {
        border-radius: 8px;
        border: 1px solid #FC2424;
        background: #FC2424;
        position: absolute;
        top: 0px;
        left: 0px;
        transform: translateY(-50%, 50%);
        width: 15px;
        height: 100%;
        border-top-right-radius: 0px;
        border-bottom-right-radius: 0px;
    }
}

.Custom_Input_Max {
    cursor: default;

    .Custom_Input_Import_File {
        border-radius: 5px;
        border: 1px dashed #848484 !important;
        background: #FFF;

        /* drop shadow input */
        box-shadow: 0px 1px 4px 0px rgba(0, 0, 0, 0.25);

        svg {
            color: #848484;
        }

        cursor: default;
        pointer-events: none;
    }
}

.drag-file-element {
    position: absolute;
    width: 100%;
    height: 100%;
    border-radius: 1rem;
    top: 0px;
    right: 0px;
    bottom: 0px;
    left: 0px;
}

.hover_drag_input {
    background-color: #5cbbff3b !important;
}

.hover_drag_input_error {
    background-color: #f3535351 !important;
    opacity: .7;
    border: 1px dashed var(--fc-2424, #fc24249d) !important;
}

.text-maximum-upload {
    font-size: 14px;
    color: #FF2237;
}

.Icon_Drag_Error {
    svg {
        color: #FC2424;
    }
}