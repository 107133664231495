@import "Common.scss";
.register-container {
    width: 60vw;
    padding: 50px;
    background: #FFF;
    max-width: 1100px;
}

.input-label {
    margin-bottom: 0;
    color: $mainBg;
    font-size: 16px;
    font-style: normal;
    font-weight: 700;
    line-height: 24px;
    letter-spacing: 1.6px;
    margin-bottom: 5px;
    align-items: center;
}

.input-wrap {
    margin-bottom: 10px;
    display: flex;
    align-items: center;
}

.notify {
    margin-bottom: 10px;
}

.notify-header {
    font-size: 16px;
    font-weight: 900;
    border-bottom: 1px solid;
    margin-bottom: 10px;
}

.notify-content .label {
    font-size: 16px;
    font-weight: 900;
}

.btn-submit {
    position: relative;
}

.disable-btn {
    opacity: 0;
}

.centered-content {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
}