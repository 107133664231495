.InquiryConditionSearchPopup {
    display: flex;
    flex-direction: column;
    gap: 20px;
    padding: 20px;
    background-color: #F2F2F2;

    .WrapContent {
        display: flex;
        flex-direction: row;
        gap: 10px;
        justify-content: space-between;
        align-items: center;

        .WrapContentLeft {
            width: 200px;
            min-width: 200px;
            max-width: 200px;

            .WrapContentLeftTitle {
                margin-bottom: 0px !important;
            }
        }

        .WrapContentRight {
            flex: 1;
            display: flex;
            flex-direction: row;
            gap: 10px;

            :global {
                .custom-date-picker {
                    border: 0.5px solid #929292;
                    box-shadow: none;
                    height: 40px;
                }
            }
        }
    }

    .SubmitButton {
        @extend .WrapContent;
        justify-content: end;
    }
}