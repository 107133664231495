@import 'Common.scss';

.action-button {
    display: flex;
    align-items: center;
    justify-content: center;

    :global{
        @include mobile(){
            .btn-action{
                width: 100%;
            }
        }
    }
}

.form-action{
    :global{
        @include mobile(){
            .btn-form{
                width: 100%;
            }
        }
    }
}