@import "Common.scss";
.FaqManagement-Content {
    display: flex;
    flex-direction: column;
    gap: 10px;

    .FaqManagement-Heading {
        p {
            color: #000;
            font-size: 16px;
            font-style: normal;
            font-weight: 600;
            line-height: 24px;
            letter-spacing: 1.6px;
            margin-bottom: 0px;
        }
    }

    .FaqManagement-FormSearch {
        display: flex;
        flex-direction: column;
        gap: 10px;
        margin-bottom: 20px;

        .Form-Group {
            display: flex;
            flex-direction: row;
            align-items: center;
            gap: 20px;
            width: 100%;

            .Form-Item_Left {
                width: 100px;

                p {
                    margin-bottom: 0px;
                    color: #000;
                    font-size: 12px;
                    font-style: normal;
                    font-weight: 500;
                    line-height: 24px;
                    letter-spacing: 1.6px;
                    margin-bottom: 0px;
                }
            }

            .Form-Item-Center {
                width: 300px;
            }
        }

    }

    .FaqManagement_body {
        display: flex;
        flex-direction: column;
        gap: 20px;
        border-radius: 10px 10px 0px 0px;
        overflow: hidden;

        .FaqManagement_table {
            max-height: 50vh;
            overflow-y: auto;
            position: relative;
        }

        .row-table {
            display: flex;
            flex-direction: row;
            align-items: center;
            width: 100%;
            padding: 5px 0px;
            border-bottom: 1px solid $btnDarkBgColor;
            background-color: #FFFFFF;

            .row-number {
                min-width: 50px;
                display: flex;
                align-items: center;
                justify-content: center;
            }

            .row-name {
                width: 100%;
            }

            .row-lang{
                width: 100px;
                display: flex;
                align-items: center;
                justify-content: center;
            }

            .row-release {
                min-width: 100px;
                display: flex;
                align-items: center;
                justify-content: center;

                :global {
                    .CustomCheckBox {
                        display: flex;
                        align-items: center;
                        justify-content: center;
                    }
                }
            }


            .row-action {
                display: flex;
                flex-direction: row;
                align-items: center;
                justify-content: center;
                gap: 10px;

                .btn-action{
                    cursor: pointer;
                }
            }

            p {
                margin-bottom: 0px;
                color: #000;
                text-align: left;
                font-size: 12px;
                font-style: normal;
                font-weight: 400;
                line-height: 24px;
                /* 184.615% */
                letter-spacing: 0.26px;
            }
        }

        .row-header {
            display: flex;
            flex-direction: row;
            align-items: center;
            width: 100%;
            padding: 5px 0px;
            background-color: $btnDarkBgColor;
            color: white;
            background: $mainBg;
            position: sticky;
            top: 0;

            .row-number {
                min-width: 50px;
                display: flex;
                align-items: center;
                justify-content: center;
            }

            .row-lang{
                width: 100px;
            }

            .row-name {
                width: 100%;
                p{
                    margin-bottom: 0px;
                    text-align: left;
                }
            }

            .row-release {
                min-width: 100px;
                display: flex;
                align-items: center;
                justify-content: center;
            }

            p {
                margin-bottom: 0px;
                color: #FFF;
                text-align: center;
                font-size: 13px;
                font-style: normal;
                font-weight: 400;
                line-height: 24px;
                /* 184.615% */
                letter-spacing: 0.26px;
            }
        }

        .row-add {
            display: flex;
            flex-direction: row;
            align-items: center;
            width: 100%;
            padding: 5px 0px;
            border-bottom: 1px solid $btnDarkBgColor;
            position: sticky;
            bottom: 0;
            background-color: #FFFFFF;

            .row-number {
                min-width: 50px;
                display: flex;
                align-items: center;
                justify-content: center;
            }

            .row-name {
                width: 100%;
                margin-left: -20px;
            }

            .row-release {
                min-width: 100px;
                display: flex;
                align-items: center;
                justify-content: center;

                :global {
                    .CustomCheckBox {
                        display: flex;
                        align-items: center;
                        justify-content: center;
                    }
                }
            }


            .row-action {
                display: flex;
                flex-direction: row;
                align-items: center;
                justify-content: center;
                gap: 10px;
            }

            p {
                margin-bottom: 0px;
                color: #000;
                text-align: left;
                font-family: Noto Sans JP;
                font-size: 13px;
                font-style: normal;
                font-weight: 400;
                line-height: 24px;
                /* 184.615% */
                letter-spacing: 0.26px;
            }
        }

    }
}

.action-button {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    width: 100%;
}