.wrap-text {
    max-width: 170px;

    p {
        text-align: left;
        margin-bottom: 0px;
        position: relative;
        display: inline;
        flex-grow: 1;
        flex-shrink: 1;
        overflow: hidden;
        white-space: pre;
        text-overflow: ellipsis;

        overflow: hidden;
        display: -webkit-box;
        -webkit-line-clamp: 1;
        -webkit-box-orient: vertical;
        margin-bottom: 0px;
        text-overflow: ellipsis;
        overflow-wrap: break-word;
    }
}