.alarm-container-content {
    padding: 34px 30px 105px 30px;
    display: flex;
    flex-direction: column;
    gap: 21px;

    .custom-search {

        :global {
            .custom-select {
                select {
                    background-color: white;
                    border: none;
                    box-shadow: 4px 4px 6px 0px rgba(0, 0, 0, 0.05);
                    border-radius: 6px;
                }
            }

            .custom-button {
                box-shadow: 4px 4px 6px 0px rgba(0, 0, 0, 0.05);
            }

            .custom-input {
                input {
                    box-shadow: 4px 4px 6px 0px rgba(0, 0, 0, 0.05);
                }
            }

            .custom-form-search {
                display: flex;
                flex-direction: row;
                justify-content: space-between;
            }
        }
    }

    .custom-body {
        display: flex;
        flex-direction: column;
        gap: 20px;
    }

    .custom-pagination {
        display: flex;
        align-items: center;
        justify-content: center;
    }
}


.Custom-Row-Table {
    display: flex;
    align-items: center;
    justify-content: center;
}

.custom-form-search {
    flex-direction: row;
    flex-wrap: nowrap;

    :global {
        .FormGroup {
            flex-wrap: wrap !important;
        }

        .FormItem {
            flex: unset !important;
        }
    }
}

.alarm-holon-template {
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 10px;

    .alarm-holon-template-item {
        border-radius: 4px;
        border: 0.5px solid #929292;
        color: #000;
        text-align: center;
        font-size: 12px;
        font-style: normal;
        font-weight: 400;
        line-height: 12px;
        padding: 3px 5px;
    }
}