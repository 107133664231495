.section-action{
    display: flex;
    justify-content: flex-end;
    align-items: center;
    margin-top: 20px;
}

.total-quantity {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-end;
    gap: 100px;
    margin-bottom: 10px;
    color: #000;
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    letter-spacing: 0.24px;
}