@import "Common.scss";

.modal-header {
  background-color: $darkBg;
  color: #fff;
  padding: 10px;
  text-align: center;
  justify-content: center;
  position: relative;
  height: 90px;
  padding: 40px;
}
.modal-header h1 {
  font-size: 24px;
}
.modal-header button {
  position: absolute;
  top: 50%;
  right: 34.27px;
  transform: translateY(-50%);
  background-color: transparent;
  outline: none;
  border: none;
  line-height: 1;
  padding: 0;
}

.modal-title {
  width: 100%;
  text-align: center;
}