.textarea {
    textarea {
        outline: none;

        &::placeholder {
            color: #929292;
        }
    }
    
    &.disabled {
        background-color: #D9D9D9;
    }
}
.error {
    border-color: red;
}