@import "Common.scss";

@mixin fitContent {
    width: fit-content;
}

.profile {
    margin-bottom: 38px;
}

.profile-name {
    color: #000;
    font-size: 10px;
    font-style: normal;
    font-weight: 400;
    line-height: 24px;
    /* 240% */
    letter-spacing: 1px;
}

.fit-content {
    @include fitContent;
}

.profile-img {
    @include fitContent;
}

.upload-new-img,
.delete-img {
    color: $btnDarkBgColor;
    font-family: Noto Sans JP;
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: 24px;
    /* 171.429% */
    letter-spacing: 1.4px;
}

.avatar-container {
    display: flex;
    flex-direction: row;
}

.edit-img {
    @include fitContent;
    margin-left: 20px;
    display: flex;
    flex-direction: column;
    justify-content: center;
}

.form-outline {
    width: 300px;
}

.form-outline-expanded {
    width: 640px;
}

.form-check-label {
    color: #000;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 14px;
    letter-spacing: 1.4px;
}

.right-label {
    @include fitContent;
    padding-left: 28px;
    display: flex;
    align-items: flex-end;
}

.row-form {
    margin-bottom: 30px;
}

.form-label {
    @extend .form-check-label;
    margin-bottom: 10px;
}

.form-check-input-name {
    padding-left: 10px;
}

.form-check-name {
    line-height: 15px;
    margin-bottom: 13px;
}

.form-check.form-check-inline {
    padding-bottom: unset;
    min-height: unset;
    height: 18px;
    line-height: 18px;
    margin-bottom: 0;
    margin-right: 40px;
}

.gender-checkbox {
    @extend .row-form;
    display: inline-block;
    position: relative;
    cursor: context-menu;
    // width: 30%;
}

.form-check-input {
    display: inline-block;
    position: relative;
    cursor: pointer;
}

.profile-btn {
    width: 52px;
    height: 24px;
    flex-shrink: 0;
    border-radius: 6px;
    background: #EBEBEB;
    color: #263238;
    font-family: Noto Sans JP;
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    letter-spacing: 1.4px;
    border: 0;
    background-color: lightblue;
    margin-left: 20px;
}

.phone-number {
    @extend .row-form;
    display: flex;
    display: flex;
    align-items: flex-end;
}

.form-outline-postcode {
    width: 300px;
    display: flex;
    justify-content: space-between;
}

.profile-submit-btn {
    @extend .row-form;
    padding-right: 40px;
    width: 640px;
    display: flex;
    justify-content: flex-end;
}

.choose-template-container {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.row-form-input {
    @extend .row-form;
    width: 640px;
    display: flex;
    justify-content: space-between;
}

.form-outline-with-btn {
    width: 300px;
    display: flex;
    justify-content: space-between;
}

.form-outline-checkbox {
    display: flex;
    align-items: center;
    .form-label-checkbox {
        @extend .form-check-label;
        display: flex;
        align-items: center;
    }
    .text-checkbox {
        display: flex;
        align-items: center;
    }
}

.profile-submit-btn-start {
    @extend .row-form;
    padding-right: 40px;
    width: 640px;
    display: flex;
    justify-content: flex-start;
}

.profile-submit-btn-end {
    @extend .row-form;
    padding-right: 40px;
    width: 640px;
    display: flex;
    justify-content: flex-end;
}

.ml-20 {
    margin-left: 20px;
}

.radio {
    grid-template-columns: repeat(3, 147px);
}