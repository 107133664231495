@import "Common.scss";

.MessageContainer {
    display: flex;
    flex-direction: row;
    width: 100%;
    justify-content: space-between;

    .list-user {
        width: var(--contentChannelWidth);
        min-width: var(--contentChannelWidth);
        max-width: var(--contentChannelWidth);
        position: fixed;
        top: 0;
        left: var(--contentMenuWidth);
        bottom: 0;
        background-color: white;
        border: 0.5px solid #EBEBEB;
        padding: 13px 0px;
        display: flex;
        flex-direction: column;

        .form-search-user{
            padding: 0px 20px;
        }

        .list-user-content{
            display: flex;
            flex-direction: column;
            position: relative;
            gap: 13px;
        }
    }

    .content-message {
        background-color: white;
        padding-left: var(--contentChannelWidth);
        width: calc(100%);
        margin-top: 10px;
    }
}

.button-add-channel {
    position: absolute;
    bottom: 10px;
    right: 20px;
    width: 50px;
    height: 50px;
    flex-shrink: 0;
    border-radius: 50%;
    background-color: $btnBgColor;
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
}