@import "Common.scss";

.react-datepicker__day--selected:hover,
.react-datepicker__day--in-selecting-range:hover,
.react-datepicker__day--in-range:hover,
.react-datepicker__month-text--selected:hover,
.react-datepicker__month-text--in-selecting-range:hover,
.react-datepicker__month-text--in-range:hover,
.react-datepicker__quarter-text--selected:hover,
.react-datepicker__quarter-text--in-selecting-range:hover,
.react-datepicker__quarter-text--in-range:hover,
.react-datepicker__year-text--selected:hover,
.react-datepicker__year-text--in-selecting-range:hover,
.react-datepicker__year-text--in-range:hover {
    border: none;
    background-color: var(--brand) !important;
    color: var(--dark) !important;
}
.react-datepicker-wrapper {
    width: 100%;
}
.react-datepicker__day--in-selecting-range {
    background-color: #67BFCE !important;
    border-radius: 0 !important;
    color: #fff;
}

.react-datepicker__day--in-range:hover::before {
    content: "";
    position: absolute;
    top: 0%;
    left: 0%;
    width: 30px;
    height: 30px;
    background-color: $mainBg;
    border-radius: 50% !important;
    z-index: -1;
}

.react-datepicker__day--in-range:not(.Sat, .Sun, .react-datepicker__day--range-start.react-datepicker__day--range-end)::after {
    content: "";
    position: absolute;
    top: 0%;
    left: -50%;
    width: 60px;
    height: 30px;
    background-color: #67BFCE;
    z-index: -2;
}

.react-datepicker__day--in-range.Sat:hover:not(.react-datepicker__day--range-start, .react-datepicker__day--range-end):after {
    content: "";
    position: absolute;
    top: 0%;
    left: -50%;
    width: 30px;
    height: 30px;
    background-color: #67BFCE;
    z-index: -2;
}

.react-datepicker__day--in-range.Sun:hover:not(.react-datepicker__day--range-start, .react-datepicker__day--range-end):after {
    content: "";
    position: absolute;
    top: 0%;
    left: 50%;
    width: 30px;
    height: 30px;
    background-color: #67BFCE;
    z-index: -2;
}

.react-datepicker__day--in-range.Sat:not(.react-datepicker__day--range-start, .react-datepicker__day--range-end):after {
    content: "";
    position: absolute;
    top: 0%;
    left: -50%;
    width: 30px;
    height: 30px;
    background-color: #67BFCE;
    z-index: -2;
}

.react-datepicker__day--in-range.Sun:not(.react-datepicker__day--range-start, .react-datepicker__day--range-end):after {
    content: "";
    position: absolute;
    top: 0%;
    left: 50%;
    width: 30px;
    height: 30px;
    background-color: #67BFCE;
    z-index: -2;
}

.react-datepicker__day--in-range {
    background-color: #67BFCE !important;
    color: #fff;
    border-radius: 0;
    width: 45px !important;
}

.react-datepicker__day--in-selecting-range.Sat:not(.react-datepicker__day--selecting-range-start, .react-datepicker__day--selecting-range-end),
.react-datepicker__day--in-range.Sat:not(.react-datepicker__day--range-start, .react-datepicker__day--range-end) {
    position: relative;
    background-color: #67BFCE !important;
    width: 30px !important;
    height: 30px !important;
    margin-left: 7.5px !important;
    margin-right: 7.5px !important;
    border-radius: 50% !important;
    z-index: 1;
}

.react-datepicker__day--in-selecting-range.Sat:not(.react-datepicker__day--selecting-range-start, .react-datepicker__day--selecting-range-end):after,
.react-datepicker__day--in-range.Sat:not(.react-datepicker__day--range-start, .react-datepicker__day--range-end):after {
    content: "";
    position: absolute;
    top: 0%;
    left: -50%;
    width: 30px;
    height: 30px;
    background-color: #67BFCE;
    z-index: -2;
}

.react-datepicker__day--in-selecting-range.Sun:not(.react-datepicker__day--selecting-range-start, .react-datepicker__day--selecting-range-end),
.react-datepicker__day--in-range.Sun:not(.react-datepicker__day--range-start, .react-datepicker__day--range-end) {
    position: relative;
    background-color: #67BFCE !important;
    width: 30px !important;
    height: 30px !important;
    margin-left: 7.5px !important;
    margin-right: 7.5px !important;
    border-radius: 50% !important;
    z-index: 1;
}

.react-datepicker__day--in-selecting-range.Sun:not(.react-datepicker__day--selecting-range-start, .react-datepicker__day--selecting-range-end):after,
.react-datepicker__day--in-range.Sun:not(.react-datepicker__day--range-start, .react-datepicker__day--range-end):after {
    content: "";
    position: absolute;
    top: 0%;
    left: 50%;
    width: 30px;
    height: 30px;
    background-color: #67BFCE;
    z-index: -2;
}

.react-datepicker__day-name {
    width: 45px !important;
    margin: 0;
}

.react-datepicker__day {
    margin: 0px 0px 0px 0px;
    padding: 0 2.5px 0 2.5px;
    width: 45px;
    line-height: 30px;
    color: $btnDarkBgColor;
    position: relative;
    text-align: center;
    font-family: 'Noto Sans JP';
    font-size: 18px;
    font-style: normal;
    font-weight: 500;
}
.react-datepicker__day.react-datepicker__day--disabled {
    color: #ccc;
}
.react-datepicker__day--range-end {
    background-color: red;
}

.react-datepicker__day--selected,
.react-datepicker__day--range-end,
.react-datepicker__day--range-start,
.react-datepicker__day--selecting-range-end,
.react-datepicker__day--selecting-range-start,
.react-datepicker__day:hover {
    position: relative;
    background-color: $mainBg !important;
    width: 30px !important;
    height: 30px !important;
    margin-left: 7.5px !important;
    margin-right: 7.5px !important;
    border-radius: 50% !important;
    color: #FFF !important;
    z-index: 1;
}

.react-datepicker__day--selecting-range-end:not(.Sat.Sun)::before,
.react-datepicker__day--range-end:not(.Sun)::before {
    content: "";
    position: absolute;
    top: 0%;
    left: 0%;
    width: 30px;
    height: 30px;
    background-color: $mainBg;
    border-radius: 50% !important;
    z-index: -1;
}

.react-datepicker__day--selecting-range-end:not(.Sun, .react-datepicker__day--selecting-range-start.react-datepicker__day--selecting-range-end)::after,
.react-datepicker__day--range-end:not(.Sun, .react-datepicker__day--range-start.react-datepicker__day--range-end)::after {
    content: "";
    position: absolute;
    top: 0%;
    left: -50%;
    width: 30px;
    height: 30px;
    background-color: #67BFCE;
    z-index: -2;
}

.react-datepicker__day--selecting-range-start:not(.Sat)::before,
.react-datepicker__day--range-start:not(.Sat)::before {
    content: "";
    position: absolute;
    top: 0%;
    left: 0%;
    width: 30px;
    height: 30px;
    background-color: $mainBg;
    border-radius: 50% !important;
    z-index: -1;
}

.react-datepicker__day--selecting-range-start:not(.Sat, .react-datepicker__day--selecting-range-start.react-datepicker__day--selecting-range-end)::after,
.react-datepicker__day--range-start:not(.Sat, .react-datepicker__day--range-start.react-datepicker__day--range-end)::after {
    content: "";
    position: absolute;
    top: 0%;
    left: 50%;
    width: 30px;
    height: 30px;
    background-color: #67BFCE;
    z-index: -2;
}

.react-datepicker__day--outside-month {
    color: transparent !important;
    pointer-events: none;
}

.react-datepicker {
    width: 330px;
    height: 325px;
    box-shadow: 0 0 20px 0 lightgrey;
    border-radius: 10px;
    border: none;
}

.react-datepicker__header {
    border-bottom: none !important;
    height: 60px;
    background-color: #FFF;
    margin: 18px 0 0 0;
    padding: 0 0 0 0;
}

.react-datepicker__month-container {
    width: 100%;
    height: 100%;
}

.react-datepicker__month {
    width: 100%;
    height: 274px;
    margin: 20px 0 0 0;
}

.custom-header {
    margin: 0px 0 12px 0;
    color: $btnDarkBgColor;
    text-align: center;
    font-family: 'Noto Sans JP';
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: 24px;
    /* 150% */
    letter-spacing: 1.6px;
    height: 24px;
    display: flex;
    justify-content: space-between;
    margin-left: 10px;
    margin-right: 10px;
}
.custom-header button {
    color: $btnDarkBgColor;
    font-size: 16px;
    font-style: normal;
    text-align: center;
    background: transparent;
    border: none;
    font-weight: 500;
    outline: none;
}
.react-datepicker__day-name {
    color: #929292 !important;
    text-align: center;
    font-family: 'Noto Sans JP';
    font-size: 13px;
    font-style: normal;
    font-weight: 500;
    line-height: 24px !important;
    /* 184.615% */
    letter-spacing: 1.3px;
    height: 24px;
}

.btn-arrow-rounded-icon {
    padding: 0 0 0 0 !important;
    color: $btnDarkBgColor !important;
}

.react-datepicker__week {
    padding: 0 0 0 0 !important;
    width: 100%;
    height: 30px;
    flex-shrink: 0;
    margin: 7px 0 7px 0 !important;
}

.react-datepicker__day--keyboard-selected {
    background-color: unset;
}

.react-datepicker__triangle {
    left: 200px !important;
    transform: none !important;
} 
.react-datepicker__input-container input {
    padding-right: 20px;
    padding-left: 20px;
    border: unset;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    height: 100%;
    letter-spacing: 1.6px;
    border-radius: 5px;
    background: #EBEBEB;
    min-height: 43px;
}

.react-datepicker__header.react-datepicker__header--time {
    margin-top: 0;
    background-color: #f0f0f0;
    height: fit-content;
    padding: 5px;
}
.react-datepicker__time-container {
    float: none;
}
.datetime-picker .react-datepicker {
    display: flex;
    align-items: stretch;
}