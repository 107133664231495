@import "Common.scss";

.CustomSelect {
    cursor: pointer;
    position: relative;
    width: 100%;
    height: $inputHeight;

    .CustomIcon {
        position: absolute;
        right: 9px;
        top: 50%;
        transform: translateY(-50%);
        height: 34px;
        display: flex;
        align-items: center;
        justify-content: center;
        z-index: 2;

        svg {
            color: #000000;
        }
    }

    &.disabled {
        cursor: default;
        pointer-events: none;


        select {
            background-color: #D9D9D9;
        }
    }

    &.CustomSelect_Error {
        select {
            border: 1px solid #FF2C00;
        }
    }

    &.small {
        height: 34px;

        .CustomIcon {
            height: 34px;
        }

        select {
            height: 34px;
        }
    }

    &.large {
        height: 40px;

        .CustomIcon {
            height: 40px;
        }

        select {
            height: 40px;
        }
    }

    .CustomNameSelect {
        width: 120px;
        background-color: #929292;
        height: 100%;
        text-align: left;
        border-top-left-radius: 3px;
        border-bottom-left-radius: 3px;
        display: flex;
        align-items: center;
        justify-content: flex-start;
        padding-left: 10px;

        p {
            overflow: hidden;
            display: -webkit-box;
            -webkit-line-clamp: 1;
            -webkit-box-orient: vertical;
            margin-bottom: 0px;
            font-size: 12px;
            color: #000000;
        }
    }

    .Custom_Select {
        border-top-left-radius: 0px;
        border-bottom-left-radius: 0px;
        left: 120px;
    }

    select {
        height: 100%;
        font-size: 12px;
        z-index: 1;
        outline: none;
        border-radius: 4px;
        border: 0.5px solid #929292;
        background: #FFF;
        background: white;
        cursor: pointer;
        z-index: 1;
        position: absolute;
        top: 0;
        left: 0px;
        right: 0;
        bottom: 0;

        color: #0E0E28;
        font-size: 12px;
        font-style: normal;
        font-weight: 400;
        line-height: 30px;
        text-indent: 0px;
        padding: 0px 9px;

        ::-ms-expand {
            display: none;
        }

        -webkit-appearance: none;
        -moz-appearance: none;

        &::placeholder {
            color: #C7C7C7;
            opacity: 1;
        }

        &:Disabled {
            background-color: #E3E3E3;
        }

        &.Padding_Icon_Left {
            padding-left: 25px;
        }

        option {
            text-align: center;
            color: #000000;
            text-align: center;
            font-size: 14px;
            font-style: normal;
            font-weight: 400;
            line-height: normal;
        }

        &:focus {
            border: 0.5px solid #929292;
        }

        &:active {
            border: 0.5px solid #929292;
        }
        background-image: url("data:image/svg+xml;utf8,<svg width='12' height='12' viewBox='0 0 10 7' fill='none' xmlns='http://www.w3.org/2000/svg'><path d='M9 1L5 5L0.999999 1' stroke='gray' strokeWidth='1.5' strokeLinecap='round' /></svg>");
        background-repeat: no-repeat;
        background-position-x: 98%;
        background-position-y: 9px;
        padding-right: 2rem;
    }

    select:invalid {
        color: #929292;
    }

    option[value=""][disabled] {
        display: none;
    }

    option {
        color: black;
    }

    &.CustomSelect_Error {
        select {
            border: 0.5px solid #FF2C00 !important;

            &:focus {
                border: 0.5px solid #FF2C00 !important;
            }

            &:active {
                border: 0.5px solid #FF2C00 !important;
            }
        }

    }

    .option-left {
        option {
            text-align: left;

            &:disabled {
                color: rgb(170, 170, 170);
            }
        }
    }

    .Icon_Left {
        width: 35px;
        height: 50px;
        display: flex;
        align-items: center;
        justify-content: center;
        position: absolute;
        top: 0px;
        margin: auto;
        z-index: 1;
    }

    &.Custom_Form_Search {
        height: 40px !important;
        border: none;
        box-shadow: none;
        border-radius: 6px;
    }
}

.Custom_Select_Error_Message {
    margin-top: 7px;
}