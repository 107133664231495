@import "Common.scss";
.dropdown-container {
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    z-index: 100;
    position: relative;
    .dropdown-option-list {
        background-color: rgb(235, 235, 235);
        // border: 1px solid #eae9e9;
        position: absolute;
        top: 105%;
        border-radius: 4px;
        box-shadow: 4px 4px 6px 0px rgba(0, 0, 0, 0.05);
        .dropdown-option-container {
            padding: 8px 8px;
            cursor: pointer;
            border-top: 1px solid #989393;
        }
        .dropdown-option-container:first-child {
            border-top: none;
          }
        .option {
            font-size: 14px;
            width: 200px;
            display: flex;
            flex-direction: row;
            align-items: center;
        }
    }
    p {
        cursor: pointer;
    }
}