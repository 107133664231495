@import "Common.scss";
.wrap-container {
    background: rgba(112, 176, 187, 0.90);
    width: 700px;
}

.wrap-body {
    display: flex;
    flex-direction: column;
    gap: 35px;
    overflow-y: auto;
    max-height: 80vh;

    .wrap-form {
        display: flex;
        flex-direction: column;
        gap: 15px;
    }
}

.wrap-footer {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
}

.data-attributes {
    display: flex;
    flex-direction: column;
    border-radius: 8px;

    .data-attributes-header {
        height: 30px;
        background-color: $mainBg;
        display: flex;
        align-items: center;
        justify-content: flex-start;
        padding: 0px 10px;
        border-radius: 10px 10px 0px 0px;

        p {
            margin-bottom: 0px;
            font-size: 14px;
            color: white;
        }
    }

    .data-attributes-body {
        display: flex;
        flex-direction: column;
        gap: 10px;
        background: #F2F2F2;
        padding: 10px;
        border-radius: 0px 0px 10px 10px;

        .item-attributes {
            display: flex;
            flex-direction: row;
            align-items: stretch;
            justify-content: flex-start;
            gap: 20px;

            :global {
                .form-input {
                    border: 1px solid #929292;
                    background-color: white;
                }
            }

            .attributes-password {
                width: 100%;
                display: flex;
                flex-direction: column;
                gap: 5px;
            }

            .item-attributes-title {
                margin-bottom: 0px;
                color: $mainBg;
                font-size: 13px;
                font-style: normal;
                font-weight: 500;
                line-height: 24px;
                /* 184.615% */
                letter-spacing: 1.3px;
                width: 200px;
                min-width: 200px;
            }
        }
    }
}

.data-relation {
    display: flex;
    flex-direction: column;
    border-radius: 8px;
    // overflow: hidden;

    .data-relation-header {
        height: 30px;
        background-color: $mainBg;
        display: flex;
        align-items: center;
        justify-content: flex-start;
        padding: 0px 10px;
        border-radius: 10px 10px 0px 0px;

        p {
            margin-bottom: 0px;
            font-size: 14px;
            color: white;
        }
    }

    .data-relation-body {
        display: flex;
        flex-direction: column;
        gap: 10px;
        background: #F2F2F2;
        padding: 10px;
        border-radius: 0px 0px 10px 10px;

        .item-relation {
            display: flex;
            flex-direction: row;
            align-items: center;
            justify-content: flex-start;
            gap: 20px;

            .item-connection-title {
                width: 200px;
                margin-bottom: 0px;
                color: $mainBg;
                font-size: 13px;
                font-style: normal;
                font-weight: 500;
                line-height: 24px;
                letter-spacing: 1.3px;
            }
        }
    }
}

.wrap-success-container {
    width: 900px;
    background-color: white;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    .notify-header { 
        width: 100%;
        font-size: 16px;
        font-weight: 900;
        border-bottom: 1px solid;
        margin-bottom: 10px;
        padding: 8px 16px;
    }
    .notify {
        margin-bottom: 10px;
        width: 100%;
        padding: 8px 16px;
    }
    .notify-content .label {
        font-size: 16px;
        font-weight: 900;
        width: 80%;
    }
    .form-group-row {
        margin-top: 16px;
        display: flex;
        justify-content: center;
    }
}