@import "Common.scss";
$remark-text: 700;

.reset-password-container {
    margin: 0;
    height: 100vh;
    position: relative;

    .model-body {
        width: 600px;
        padding: 0;
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        border-radius: 10px;
        box-shadow: 0px 11px 15px -7px rgba(0, 0, 0, 0.2), 0px 24px 38px 3px rgba(0, 0, 0, 0.14), 0px 9px 46px 8px rgba(0, 0, 0, 0.12);

        .model-header {
            border-radius: 10px 10px 0 0;
        }

        .reset-password-form {
            padding: 12px 26px 26px 26px;

            .btn-reset {
                min-width: 300px;
                height: 60px;
                box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.10);
                border-radius: 50px;
                font-family: Noto Sans JP;
                font-size: 14px;
                font-weight: 700;
                line-height: 24px; 
                letter-spacing: 1.4px;
                border: 0;
                color: #FFF;
                background: $btnBgColor;
            }

            .form-label {
                color: $mainBg;
                font-size: 16px;
                font-style: normal;
                font-weight: $remark-text;
                line-height: 24px;
                letter-spacing: 1.6px;
                margin-bottom: 5px;
            }

            .checkbox-container {
                margin-top: 18px;
                margin-bottom: 38px;
            }
        }
    }
}