@import "Common.scss";
.container {
    background: #F2F2F2;
    border-radius: 10px;

    :global {
        .wrap-content-body {
            padding: 20px 30px 20px 30px;
        }
    }


    .icon {
        cursor: pointer;

        svg {
            color: #929292;
        }
    }

    .list-selected {
        table {
            thead {
                th {
                    background-color: #FFC30D !important;

                    .title-header {
                        color: #000 !important;
                    }
                }
            }
        }
    }

    .selected-holon-template {
        width: 1160px;
        display: flex;
        flex-direction: column;
        gap: 20px;

        .selected-container {
            display: flex;
            flex-direction: row;
            gap: 20px;
            flex-wrap: nowrap;


            .content-list {
                // height: 400px;
                min-height: 406px;
                overflow-y: auto;
                gap: 22px;
                display: flex;
                flex-direction: column;
            }

            .list {
                flex: 1;
                width: 50%;
                position: relative;
                display: flex;
                flex-direction: column;

                .custom-pagination-popup {
                    display: flex;
                    justify-content: center;
                    padding-top: 25px;
                }

                .custom-form-search {
                    position: sticky;
                    top: 0;
                    display: flex;
                    flex-direction: row;
                }

                table {
                    width: 100%;
                    border-collapse: collapse;
                    overflow: hidden;

                    thead {
                        position: sticky;
                        top: 0;

                        th {
                            background: $mainBg;
                            min-width: 100px;
                            padding: 5px;
                            color: #FFFFFF;
                            font-size: 14px;
                            font-style: normal;
                            font-weight: 400;
                            line-height: normal;
                            letter-spacing: 0.24px;
                            padding: 3px 10px;
                            text-align: left;

                            &:first-child {
                                padding-left: 20px;
                                border-top-left-radius: 10px;
                            }

                            &:last-child {
                                padding-right: 20px;
                                border-top-right-radius: 10px;
                            }

                            &.content-with {
                                width: 100%;
                                max-width: 100%;
                                min-width: 100px;
                            }

                            &.content-with-action {
                                width: 30px;
                                max-width: 30px;
                                min-width: 30px;
                            }

                            .title-header {
                                overflow: hidden;
                                display: -webkit-box;
                                -webkit-line-clamp: 1;
                                -webkit-box-orient: vertical;
                                color: #FFF;
                                text-align: left;
                                font-size: 13px;
                                font-style: normal;
                                font-weight: 400;
                                line-height: 24px;
                            }
                        }
                    }

                    tbody {

                        tr {
                            &:hover {
                                td {
                                    background: #EBEBEB;
                                }
                            }

                            &:last-child {
                                td {
                                    &:last-child {
                                        border-bottom-right-radius: 10px;
                                        overflow: hidden;
                                    }

                                    &:first-child {
                                        border-bottom-left-radius: 10px;
                                        overflow: hidden;
                                    }
                                }
                            }
                        }

                        td {
                            color: #000;
                            font-size: 12px;
                            font-style: normal;
                            font-weight: 400;
                            line-height: normal;
                            letter-spacing: 0.24px;
                            padding: 3px 10px;
                            background: #FFF;

                            &:first-child {
                                padding-left: 20px;
                            }

                            &:last-child {
                                padding-right: 20px;
                            }

                            &.content-with {

                                p {
                                    color: #000;
                                    font-family: Noto Sans JP;
                                    font-size: 12px;
                                    font-style: normal;
                                    font-weight: 400;
                                    line-height: normal;
                                    letter-spacing: 0.24px;
                                    margin-bottom: 0px;
                                    overflow: hidden;
                                    display: -webkit-box;
                                    -webkit-line-clamp: 1;
                                    -webkit-box-orient: vertical;
                                }
                            }

                            &.content-with-action {
                                cursor: pointer;
                            }

                            &.action-remove {
                                svg {
                                    color: #929292;
                                }

                                &:hover {
                                    svg {
                                        color: #D9D9D9;
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }
    }

    .custom-pagination {
        display: flex;
        align-items: center;
        justify-content: center;
        padding-bottom: 20px;
    }

    .custom-content-table {
        overflow: hidden;
        display: -webkit-box;
        -webkit-line-clamp: 1;
        -webkit-box-orient: vertical;
        max-width: 300px;
    }

    .custom-tab-holon-template {
        display: flex;
        flex-direction: row;
        align-items: center;
        border-bottom: 1px solid $mainBg;
        border-radius: 10px 10px 0px 0px;
        gap: 30px;
        max-width: 1160px;
        overflow-x: auto;
        // padding-bottom: 5px;

        &::-webkit-scrollbar {
            height: 8px;
            visibility: hidden;
        }
    
        /* Track */
        &::-webkit-scrollbar-track {
            background: #D9D9D9;
            border-radius: 5px;
            transform: translateY(-100px)
        }
    
        /* Handle */
        &::-webkit-scrollbar-thumb {
            background: #929292;
            border-radius: 5px;
            border: 2px solid transparent;
            background-clip: content-box;
            transform: translateY(-100px)
        }
    
        /* Handle on hover */
        &::-webkit-scrollbar-thumb:hover {
            background: #555555c4;
            border: 2px solid transparent;
            background-clip: content-box;
        }

        .custom-tab {
            padding: 7px;
            font-size: 13px;
            color: black;
            cursor: pointer;
            position: relative;
            display: flex;
            flex-direction: row;
            align-items: center;
            width: max-content;
            min-width: max-content;
            gap: 5px;

            span {
                overflow: hidden;
                display: -webkit-box;
                -webkit-line-clamp: 1;
                -webkit-box-orient: vertical;
                text-overflow: ellipsis;
            }

            &::before {
                width: 100%;
                height: 4px;
                bottom: 0px;
                position: absolute;
                left: 0px;
                content: "";
                border-radius: 5px 5px 0px 0px;
                background: #FFFFFF;
            }

            &.custom-tab-active {
                &::before {
                    width: 100%;
                    height: 4px;
                    bottom: 0px;
                    position: absolute;
                    left: 0px;
                    content: "";
                    border-radius: 5px 5px 0px 0px;
                    background: $btnBgColor !important;
                }

                color: $btnBgColor;
            }

            .custom-tab-number {
                border-radius: 50%;
                background-color: $btnBgColor;
                min-width: 15px;
                height: 15px;
                display: flex;
                align-items: center;
                justify-content: center;

                p {
                    color: #FFFFFF;
                    font-size: 12px !important;
                    margin-bottom: 0px;
                }
            }
        }
    }

    .custom-loading {
        position: absolute;
        transform: translateX(-50%);
        z-index: 10;
        top: 45px;
        left: 50%;

        :global {
            .lds-spinner {
                div {
                    &::after {
                        background-color: $mainBg;
                    }
                }
            }
        }
    }
}