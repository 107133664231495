@import "Common.scss";
.CreateFAQPopup {
    flex-shrink: 0;
    display: flex;
    flex-direction: column;
    gap: 15px;
    position: relative;

    .CreateFAQPopup_Heading{
        p{
            color: #000;
            font-size: 16px;
            font-style: normal;
            font-weight: 600;
            line-height: 24px;
            letter-spacing: 1.6px;
            margin-bottom: 0px;
        }
    }
}

.UpdateFAQPopup {
    flex-shrink: 0;
    display: flex;
    flex-direction: column;
    gap: 15px;
    // padding: 20px;
    position: relative;

    .UpdateFAQPopup_Heading{
        p{
            color: #000;
            font-size: 16px;
            font-style: normal;
            font-weight: 600;
            line-height: 24px;
            letter-spacing: 1.6px;
            margin-bottom: 0px;
        }
    }
}

.Custom-Header {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    width: 100%;
    align-items: center;
    background-color: $mainBg;
    padding: 10px 20px;
    position: sticky;
    top: 0;
    z-index: 10;

    p {
        margin-bottom: 0px;
        color: #FFFFFF;
        font-size: 17px;
        font-style: normal;
        font-weight: 700;
        line-height: 32px;
        /* 188.235% */
        letter-spacing: 1.7px;
    }
}

.Option-Button{
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    width: 100%;
    position: sticky;
    bottom: 0;
    background-color: #F2F2F2;
    padding: 10px 20px;
}

.Wrap_ContentPopup{
    background-color: #F2F2F2;
    border-radius: 10px;

    :global{
        .FormGroup{
            align-items: flex-start;
        }
    }
}

.FaqDetail{
    p{
        margin-bottom: 0px;
    }
}