.render-list {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    align-items: center;
    justify-content: flex-start;
    gap: 10px;

    .item {
        padding: 3px 5px;
        display: flex;
        align-items: center;
        justify-content: center;

        font-size: 14px;
    }
}

.list-checkbox {
    display: grid;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
    gap: 10px;
    grid-template-columns: repeat(4, 25%);
    width: 100%;

    span {
        font-size: 12px;
    }

    .content-checkbox{
        display: flex;
        flex-direction: row;
        justify-content: flex-start;
        align-items: center;
        gap: 10px;
    }
}